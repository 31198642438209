import log from "loglevel";
import React, { useEffect, useState } from "react";
// Handle console logs
import "./utils/dropConsole";

import { Session } from "bc-react-session";
import i18n from "i18next";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { ReactComponent as LogoIcon } from "./assets/images/splash_logo.svg";
import { ReactComponent as LogoTextVG } from './assets/icons/Type=LogoText.svg';
import { ReactComponent as LogoLoaderDE } from './assets/icons/LoaderGermanLogo.svg';
import { ReactComponent as LogoLoaderEN } from './assets/icons/LoaderEnglishLogo.svg';
import "./App.scss";

import "./plugins/flag-icon-css/css/flag-icon.min.css";
import "./plugins/font-awesome/css/font-awesome.min.css";
import "./styles/App.css";

import "./styles/Style.css";
import "./styles/Theme.css";
import "./styles/Navigation.scss";
import "./styles/Ltheme.css";

import Body from "./components/inc/Body";
import Sidebar from "./components/inc/Sidebar";

import { BASE_URL, IS_LOCALHOST, arrSigninAsAdminAuthId } from "./config/Constants";
import TagManager from "react-gtm-module";
import smartlookClient from "smartlook-client";
import { SERVER_URL } from "./services/ServiceConstants";
import Intercom from "@intercom/messenger-js-sdk";
import {ReactComponent as LogoEn} from "./assets/images/NewJSLogo-en.svg";
import {ReactComponent as LogoDe} from "./assets/images/NewJSLogo-de.svg";
// import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
// import $ from 'jquery';

const tagManagerArgs = {
  gtmId: "GTM-5MLBD6W",
};

function App(params) {
  // Session.start('login');
  const [globalData, setGlobalData] = useState(false);

  console.log(i18n.language)

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    const { payload } = Session.get("login");
    const UserSession = payload;
    if (UserSession.status === "loggedin") {
      // window.smartlook||(function(d) {
      //   var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      //   var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      //   c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      //   })(document);
      //   smartlook('init', '112ac909729bc3c2d08114cc8d9cdd143629b76d', { region: 'eu' });

      setGlobalData(true);
    }

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    // Start App when session is set
    const LoginSessionSet = Session.onChange((session) => {
      LoginSessionSet();
      log.debug("Session Change Detect App.js", session);
      if (Object.keys(session).length && !globalData) {
        setGlobalData(true); //JSON.stringify(session.payload) Not using
        if (typeof session.payload.DashboardLanguage !== "undefined") {
          log.debug(
            "setlanguage->>>>>>>>>>>>>>>>>>>>>>>>>",
            session.payload.DashboardLanguage
          );

          i18n.changeLanguage(session.payload.DashboardLanguage.toLowerCase());
        }
      }
    });

    // Css Fixes
    document.body.classList.add("sidebar-light");
    document.body.classList.add("sidebar-fixed");

    // if (window.matchMedia("(max-width:1500px)").matches) {
      document.body.classList.add("sidebar-icon-only");
      document.body.classList.remove("sidebar-fixed");
    // }

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    // Set Zoom Level and don't go below 1280px width
    // if(document.body.style.zoom != ''){
    if (window.innerWidth < 1280) {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute("content", "width=1280, shrink-to-fit=yes");
      document.body.style.zoom = (window.innerWidth * 100) / 1280 - 1 + "%";
    } else {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute(
          "content",
          "width=device-width, initial-scale=1, shrink-to-fit=no"
        );
      document.body.style.zoom = "0%";
    }
    if (window.innerWidth > 3840) {
      document.body.style.zoom = (window.innerWidth * 100) / 3840 - 1 + "%";
    }
    // }
  };

  // Css Fixes End
  // log.debug('App',globalData)

  const searchparams = new URLSearchParams(window.location.search);

  //  Don't delete the code below it is used on LIVE

  const { payload } = Session.get("login");
  const UserSession = payload;
  const { intlLocale = "en-US" } = UserSession;
  // eslint-disable-next-line
  useEffect(() => {
    if (!IS_LOCALHOST) {
      const signInAsAdmin =
        UserSession.UserName === "Demo" ||
        UserSession.LoginByMasterPass === true ||
        (UserSession.intAuthID !== undefined &&
          arrSigninAsAdminAuthId.includes(parseInt(UserSession.intAuthID)));
    
        var SubUserIDSm = "";
        if (UserSession.SubUserID !== "") {
          SubUserIDSm = "_" + UserSession.SubUserID;
        }
        var UserIDSm = "";
        if (UserSession.UserID !== "") {
          UserIDSm = "_" + UserSession.UserID;
        }
        var FUseridSm = "JSPF_" + UserIDSm + SubUserIDSm;
    
        if (!signInAsAdmin) {
          if (
            UserSession !== undefined &&
            UserSession.UIPreferences !== undefined &&
            UserSession.UIPreferences.cookie_consent !== undefined
          ) {
            if (UserSession.UIPreferences.cookie_consent[0].ga === "true") {
              TagManager.initialize(tagManagerArgs);
            }
            if (UserSession.UIPreferences.cookie_consent[0].smartlook === "true") {
              smartlookClient.init("112ac909729bc3c2d08114cc8d9cdd143629b76d", {
                cookies: false,
                region: "eu",
                advancedNetwork: {
                  allowedUrls: [new RegExp("^"+SERVER_URL)],
                  // allowedHeaders: ['x-custom-header'],
                  websockets: true,
                },
              });
              smartlookClient.identify(FUseridSm);
            }
        }

          Intercom({
            api_base: "https://api-iam.eu.intercom.io",
            app_id: "ppumrcse",
            name: UserSession.Name, // Full name
            user_id: UserSession.SubUserID,
            email: UserSession.SubUserEmail? UserSession.SubUserEmail : UserSession.UserEmail, // the email for your user
            created_at: "1312182000"
          });
          
        }
      }
  }, []);
  log.debug("--- App Js return() Render --- basname = ", BASE_URL);


  return (
    <>
      { globalData !== undefined && globalData !== false ? (
        <Router basename={IS_LOCALHOST ? "" : "/" + BASE_URL}>
          <div className="App">
            {handleResize()}
            <div className="container-scroller">
              <div className="container-fluid page-body-wrapper">
                <div className="row row-offcanvas row-offcanvas-right active-left">
                    <Sidebar
                    theUserID={searchparams.theuserid}
                    key={121212}
                  />
                  <Body
                    data={globalData}
                  />
                </div>
              </div>
            </div>
          </div>
        </Router>
      ) : (
        <div className="App MainApp">
          {handleResize()}
          <div className="container-scroller white-bg">
            <div className="container-fluid page-body-wrapper">
              <div className="row">
                <div className="content-wrapper full-page-wrapper d-flex align-items-center index-login auth">
                  <div className="row w-100">
                    <div className="col-lg-8 mx-auto">
                      <div className="row">
                        <h1 id="logo" className="mx-auto text-center">
                            {/* <LogoIcon /> */}
                            {i18n.language === 'en' ? <LogoLoaderEN  loading="lazy" className={'full_logo'} /> : <LogoLoaderDE  loading="lazy" className={'full_logo'} />}
                          
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default App;

import log from "loglevel";

import { Session } from 'bc-react-session';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoTextVG } from '../../assets/icons/Type=LogoText.svg';
import { ReactComponent as LogoIconSVG } from '../../assets/icons/Type=LogoIcon.svg';
import { ReactComponent as JobsIcon } from '../../assets/icons/Type=Portfolio.svg';
import { ReactComponent as MYjobsCloneIcon } from '../../assets/icons/Type=Synonyms.svg';
import { ReactComponent as MYjobsCloneTicketIcon } from '../../assets/icons/Type=SynonymsTicket.svg';
import { ReactComponent as JobGroupsIcon } from '../../assets/icons/Type=Jobgroups.svg';
import { ReactComponent as MyjobsIcon } from '../../assets/icons/Type=Job.svg';
import { ReactComponent as JQXIcon } from '../../assets/icons/Type=JQX.svg';
import { ReactComponent as JQXCompareIcon } from '../../assets/icons/Type=JQXComparison.svg';
import { ReactComponent as JTCIcon } from '../../assets/icons/Type=JTCIcon.svg';
import { ReactComponent as MarketDataIcon } from '../../assets/icons/Type=MD.svg';
import { ReactComponent as MDCIcon } from '../../assets/icons/Type=MDC.svg';
import { ReactComponent as BackIconSVG } from '../../assets/icons/iconBack.svg';
// import { ReactComponent as BackIcon } from '../../assets/icons/Type=Back.svg';
import { ReactComponent as InnovationLabIcon } from '../../assets/icons/Type=InnovationLab.svg';

// import { NavLink } from 'react-router-dom';
import $ from 'jquery';
// import i18n from "i18next";
import { useLocation } from "react-router-dom";
import { getPortfolioData } from "../../services";
import { SITE_URL } from '../../services/ServiceConstants';
import { recordInnovationLabEvent } from "../blocks/innovationLab/innov_services";
import { useWMSContext } from "../../wmsContext";
import { SettingRoutes } from "../../config/RouteMap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { recordUserEvent } from "../blocks/common/record-user-event";
import { UserEvents } from "../../config/UserEvents";
import { handleClickEventRecord } from "../blocks/common-blocks";

export default function Sidebar({ theUserID }) {

  const location = useLocation();

  const { pathname } = location;
  const search = window.location.search;
  const params = new URLSearchParams(search);

  // log.debug('-----------------------',pathname);
  let theuserid = '';
  // let theuseridback = '';
  if (theUserID !== '') {
    theuserid = '?' + theUserID;
    // theuseridback = 'myjobs?' + theUserID;
  }
  theuserid = (params.get('theuserid') !== undefined && params.get('theuserid') != null ? '?theuserid=' + params.get('theuserid') : '');
  // theuseridback = 'myjobs?' + (params.get('theuserid') !== undefined && params.get('theuserid') != null ? 'theuserid=' + params.get('theuserid') : '')
  const { t } = useTranslation();
  const { payload } = Session.get('login');
  const UserSession = payload;
  const landingUrlPage = UserSession.UIPreferences.DefaultLandingPage;

  const { navcollapse, setNavCollapse, navSynonymsCollapse, setNavSynonymsCollapse,
    navJQXCollapse, setNavJQXCollapse,
    navMDCollapse, setNavMDCollapse,isModifiedGlobal,setSaveSettingsModifiedModal,setFailRedirectUrl } = useWMSContext();
  const defLandPageUrl = useMemo(() => {
    if (landingUrlPage === "JobGroupOverview" || landingUrlPage === 'JobGroupDashboard') {
      if (UserSession.UIPreferences.hasOwnProperty('DefaultLandingPageID') && UserSession.UIPreferences.DefaultLandingPageID && landingUrlPage === 'JobGroupDashboard') {
        return `${SITE_URL}job-groups-dashboard${theuserid === "" ? "?" : theuserid + "&"}jg=${UserSession.UIPreferences.DefaultLandingPageID}`;
      } else {
        return `${SITE_URL}job-groups${theuserid === "" ? "" : theuserid}`;
      }
    }

    return `${SITE_URL}dashboard${theuserid === "" ? "" : theuserid}`;
  }, [landingUrlPage, UserSession]);

  const [arrMenuItems, setArrMenuItems] = useState([]);
  const [additionalMenuItems, setAdditionalMenuItems] = useState([]);

  const [initData, setinitData] = useState(0);



  function showNavigation(event) {
    if(event !== 'smallScreen'){
      if(isModifiedGlobal) return false;
    }
    var body = $('body');
    var sidebarIconOnly = body.hasClass("sidebar-icon-only");
    if (sidebarIconOnly) {
      document.querySelector('#sidebar').style.display = 'none'
      document.querySelector('#sidebar2').style.display = 'block'
      document.body.classList.add("sidebar-fixed");
      document.getElementById("sidebar2").classList.add("in");
      document.body.classList.remove("sidebar-icon-only");
    }
    if(event !== 'smallScreen'){
      setNavCollapse(false)
      if(event !== 'pageStart' && event !== 'smallScreen' && UserSession !== undefined){
        recordUserEvent({
            session_data: UserSession,
            eventName: UserEvents.sidebar_nav_show_navigation
        });
      }
    }
    
  }

  function hideNavigation(event) {
    if(event !== 'smallScreen'){
      if(isModifiedGlobal) return false;
    }
    document.querySelector('#sidebar2').style.display = 'none'
    document.querySelector('#sidebar').style.display = 'block'
    document.body.classList.remove("sidebar-fixed");
    document.body.classList.add("sidebar-icon-only");
    // alert(event)
    if(event !== 'smallScreen'){
      
      setNavCollapse(true)
      if(event !== 'pageStart' && event !== 'smallScreen' && UserSession !== undefined){
        recordUserEvent({
            session_data: UserSession,
            eventName: UserEvents.sidebar_nav_hide_navigation
        });
      }
    }
    

  }

  function showSynonymsNavigation() {
    if (Boolean(navSynonymsCollapse)) {
      $('.dropdownitem_synonyms').show()
      setNavSynonymsCollapse(false)
      if(UserSession !== undefined){
        recordUserEvent({
            session_data: UserSession,
            eventName: UserEvents.sidbar_nav_synonyms_dropdown_open
        });
      }
      
    } else {
      $('.dropdownitem_synonyms').hide()
      setNavSynonymsCollapse(true)
      if(UserSession !== undefined){
        recordUserEvent({
            session_data: UserSession,
            eventName: UserEvents.sidbar_nav_synonyms_dropdown_close
        });
      }
      
    }
  }
  function showJQXNavigation() {
    if (Boolean(navJQXCollapse)) {
      $('.dropdownitem_jqx').show()
      setNavJQXCollapse(false)
      if(UserSession !== undefined){
        recordUserEvent({
            session_data: UserSession,
            eventName: UserEvents.sidbar_nav_jqx_dropdown_open
        });
      }
    } else {
      $('.dropdownitem_jqx').hide()
      setNavJQXCollapse(true)
      if(UserSession !== undefined){
        recordUserEvent({
            session_data: UserSession,
            eventName: UserEvents.sidbar_nav_jqx_dropdown_close
        });
      }
    }
  }
  function showMDNavigation() {
    if (Boolean(navMDCollapse)) {
      $('.dropdownitem_md').show()
      setNavMDCollapse(false)
      if(UserSession !== undefined){
        recordUserEvent({
            session_data: UserSession,
            eventName: UserEvents.sidbar_nav_md_dropdown_open
        });
      }
    } else {
      $('.dropdownitem_md').hide()
      setNavMDCollapse(true)
      if(UserSession !== undefined){
        recordUserEvent({
            session_data: UserSession,
            eventName: UserEvents.sidbar_nav_md_dropdown_close
        });
      }
    }
  }

  // 
  const fetchData = () => {
    const getList = async () => {
      const params = new URLSearchParams(window.location.search);
      const paramsUserID = params.get("theuserid");
      let theuserid = UserSession.UserID;

      if (paramsUserID != null) {
        theuserid = paramsUserID;
      }
      const res = await getPortfolioData({ getPageInitDetails: 1, userID: theuserid, sessionData: UserSession });
      setinitData(res);
    };
    getList();
  }

  const handleDirtySettings = (e) => {
    // alert(isModifiedGlobal)
    if (isModifiedGlobal) {
      e.preventDefault();
      e.stopPropagation()
      // console.log('test',$(e.target).closest('a'))
      setFailRedirectUrl($(e.target).closest('a').attr('data-href'));
      setSaveSettingsModifiedModal(true)
      return false;
    }
  }

  

  useEffect(() => {
    let tempArrMenuItems = [
      {
        id: 2,
        label: 'sidebar_nav_portfolio',
        isEnabled: true,
        liClass: 'nav-item portfolio_link',
        class: 'jscdga_menu_jobs_link portfolio_link ' + (window.location.pathname.includes('dashboard') && !(window.location.pathname.includes('job-groups')) ? 'active' : ''),
        link: 'dashboard' + theuserid,
        img: <JobsIcon fill={window.location.pathname.includes('dashboard') ? 'var(--primary100)' : '#1C2D3B'} />,
        eventName: UserEvents.sidebar_nav_portfolio
      },
      {
        id: 3,
        label: 'sidebar_nav_jobgroups',
        isEnabled: true,
        liClass: 'nav-item link_jobgroups dropdown ',
        class: 'jscdga_menu_jobgroups_link ' + (window.location.pathname.includes('job-groups') ? 'active' : ''),
        link: 'job-groups' + theuserid,
        isPhp: false,
        img: <JobGroupsIcon fill={window.location.pathname.includes('job-groups') ? 'var(--primary100)' : '#1C2D3B'} />,
        eventName: UserEvents.sidebar_nav_jobgroups
      },
      {
        id: 4,
        label: 'sidebar_nav_myjobs',
        isEnabled: true,
        liClass: 'nav-item link_myjobs dropdown',
        class: 'jscdga_menu_myjobs_link',
        link: '/kunden_beta/myjobs' + theuserid,
        isPhp: 1,
        img: <MyjobsIcon fill={window.location.pathname.includes('myjobs') ? 'var(--primary100)' : '#1C2D3B'} />,
        eventName: UserEvents.sidebar_nav_myjobs
      },
      {
        id: 5,
        label: 'sidbar_nav_synonyms_dropdown',
        isDropdown: true,
        isEnabled: true,
        liClass: 'nav-item dropdown dropdown_new dropdown_synonyms',
        class: '',
        link: '#sidbar_nav_synonyms_dropdown',
        has_notifications: 0,
        isPhp: 0,
        img: '',

      },
      {
        id: 6,
        label: 'sidbar_nav_synonyms_overview',
        isEnabled: true,
        liClass: 'nav-item link_myjobs_clone dropdownitem_synonyms ' + (!navSynonymsCollapse ? '' : 'd-none'),
        class: 'jscdga_menu_jobtitle_generator_link',
        link: '/kunden_beta/myjobs.clone' + theuserid,
        has_notifications: 0,
        isPhp: 1,
        img: <MYjobsCloneIcon fill={window.location.pathname.includes('jobtitle-generator') ? 'var(--primary100)' : '#1C2D3B'} />,
        eventName: UserEvents.sidbar_nav_synonyms_overview

      },
      {
        id: 7,
        label: 'sidbar_nav_synonyms_tickets',
        isEnabled: true,
        liClass: 'nav-item link_myjobs_clone dropdownitem_synonyms ' + (!navSynonymsCollapse ? '' : 'd-none'),
        class: 'jscdga_menu_jobtitle_generator_link',
        link: '/kunden_beta/synonyms-tickets' + theuserid,
        has_notifications: 1,
        isPhp: 1,
        img: <MYjobsCloneTicketIcon fill={window.location.pathname.includes('jobtitle-generator') ? 'var(--primary100)' : '#1C2D3B'} />,
        eventName: UserEvents.sidbar_nav_synonyms_tickets
      },
      {
        id: 8,
        label: 'sidbar_nav_jqx_dropdown',
        isDropdown: true,
        isEnabled: true,
        liClass: 'nav-item link_myjobs_clone dropdown dropdown_new dropdown_jqx',
        class: 'jscdga_menu_jobtitle_generator_link',
        link: '#sidbar_nav_jqx_dropdown',
        has_notifications: 0,
        isPhp: 0,
        img: ''
      },
      {
        id: 9,
        label: 'sidebar_nav_jqx_new_menu',
        isEnabled: true,
        liClass: 'nav-item dropdownitem_jqx ' + (!navJQXCollapse ? '' : 'd-none'),
        link: ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true)?'/kunden_beta/demo-jobanalytics':'/kunden_beta/jobanalytics') + theuserid,
        isPhp: 1,
        class: 'jscdga_menu_jobanalytics_link ' + ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? ' disabledNav ' : ''),
        tag: ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? "tag_demo" : ''),
        img: <JQXIcon fill={((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? 'var(--neutral100)' : (window.location.pathname.includes('jobtitle-quality-index') ? 'var(--primary100)' : '#1C2D3B'))} />,
        eventName: UserEvents.sidebar_nav_jqx_new_menu
      },
      {
        id: 10,
        label: 'sidebar_nav_jqx_compare',
        isEnabled: true,
        liClass: 'nav-item dropdownitem_jqx ' + (!navJQXCollapse ? '' : 'd-none'),
        link: ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true)?'/kunden_beta/demo-jqx-compare':'/kunden_beta/jqx-compare')  + theuserid,
        isPhp: 1,
        class: 'jscdga_menu_jobanalytics_link ' + ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? ' disabledNav ' : ''),
        tag: ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? "tag_demo" : ''),
        img: <JQXCompareIcon fill={((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? 'var(--neutral100)' : (window.location.pathname.includes('jobtitle-quality-index') ? 'var(--primary100)' : '#1C2D3B'))} />,
        eventName: UserEvents.sidebar_nav_jqx_compare
      },
      {
        id: 11,
        label: 'sidebar_nav_jtc',
        isEnabled: true,
        liClass: 'nav-item dropdownitem_jqx ' + (!navJQXCollapse ? '' : 'd-none'),
        link: '/kunden_beta/jobanalytics-pg' + theuserid,
        isPhp: 1,
        class: 'jscdga_menu_jobanalytics_link ' + ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? ' disabled ' : ''),
        img: <JTCIcon fill={((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? 'var(--neutral100)' : (window.location.pathname.includes('jobtitle-quality-index') ? 'var(--primary100)' : '#1C2D3B'))} />,
        eventName: UserEvents.sidebar_nav_jtc
      },
      {
        id: 12,
        label: 'sidbar_nav_md_dropdown',
        isDropdown: true,
        isEnabled: true,
        liClass: 'nav-item link_myjobs_clone dropdown dropdown_new dropdown_md',
        class: 'jscdga_menu_jobtitle_generator_link',
        link: '#sidbar_nav_md_dropdown',
        has_notifications: 0,
        isPhp: 0,
        img: ''
      },
      {
        id: 13,
        label: 'sidebar_nav_mkt_data_main',
        isEnabled: true,
        liClass: 'nav-item dropdownitem_md ' + (!navMDCollapse ? '' : 'd-none'),
        link: ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true)?'/kunden_beta/demo-marketdata':'/kunden_beta/marketdata') + theuserid,
        class: 'jscdga_menu_marketdata_link ' + ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? ' disabledNav ' : ''),
        isPhp: 1,
        tag: ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? "tag_demo" : ''),
        img: <MarketDataIcon fill={((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? 'var(--neutral100)' : (window.location.pathname.includes('jobtitle-quality-index') ? 'var(--primary100)' : '#1C2D3B'))} />,
        eventName: UserEvents.sidebar_nav_mkt_data_main

      },
      {
        id: 14,
        label: 'sidebar_nav_mkt_data_checker',
        isEnabled: true,
        liClass: 'nav-item dropdownitem_md  ' + (!navMDCollapse ? '' : 'd-none'),
        link: '/kunden_beta/md-checker' + theuserid,
        class: 'jscdga_menu_marketdata_link ' + ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? ' disabled ' : ''),
        isPhp: 1,
        img: <MDCIcon fill={((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? 'var(--neutral100)' : (window.location.pathname.includes('jobtitle-quality-index') ? 'var(--primary100)' : '#1C2D3B'))} />,
        eventName: UserEvents.sidebar_nav_mkt_data_checker
      },
    ];
    let tempAdditionalMenuItems = [
      {
        id: 1,
        label: "sidebar_nav_innovationlab",
        tag: "sidebar_nav_innovationlab_tag",
        isEnabled: UserSession.JCDVisiblePages.includes('InnovationLab'),
        liClass: "nav-item link_innovationlab",
        class: "jscdga_menu_innovationlab_link " + (window.location.pathname.includes("innovation-lab") ? "active" : "") + ((UserSession.no_active_campaigns_since_x_days !== undefined && UserSession.no_active_campaigns_since_x_days === true) ? ' disabled ' : ''),
        link: "innovation-lab" + theuserid,
        // isPhp: false,
        img: <InnovationLabIcon fill={window.location.pathname.includes("innovation-lab") ? "var(--primary100)" : "#1C2D3B"} />,
        eventName: UserEvents.InnovationLab_SideNavigation
      },
    ];
    if(window.location.pathname.includes("settings")){
      const params = new URLSearchParams(window.location.search);
  
      
      let paramstab = params.get("tab");
      
      tempArrMenuItems = [];
      tempArrMenuItems.push({
        id: 5,
        label: 'sidbar_settings_dropdown',
        isDropdown: true,
        isEnabled: true,
        liClass: 'nav-item dropdown dropdown_new dropdown_settings',
        class: '',
        link: '#sidbar_settings_dropdown',
        has_notifications: 0,
        isPhp: 0,
        img: '',
        isSettings: true,
        eventName: UserEvents.sidbar_settings_dropdown
      })
      tempArrMenuItems.push({
        id: 6,
        label: 'sidbar_settings_personal_tab',
        isEnabled: true,
        liClass: 'nav-item dropdownitem_settings ' +(window.location.pathname.includes(SettingRoutes.settings_personal)?'active':''),
        class: '',
        link: 'personal-settings' + theuserid,
        has_notifications: 0,
        isPhp: 0,
        img: '',
        isSettings: true,
        eventName: UserEvents.sidbar_settings_personal_tab
      })
      
      if(UserSession.JSCDUserType !== undefined && UserSession.JSCDUserType === 'Admin'){

        
        tempArrMenuItems.push({
          id: 7,
          label: 'sidbar_settings_users_permission_tab',
          isEnabled: true,
          liClass: 'nav-item dropdownitem_settings ' +(window.location.pathname.includes(SettingRoutes.settings_users_rights)?'active':''),
          class: '',
          link: 'users-rights-settings' + theuserid,
          has_notifications: 0,
          isPhp: 0,
          img: '',
          isSettings: true,
          eventName: UserEvents.sidbar_settings_users_permission_tab
        })
        tempArrMenuItems.push({
          id: 8,
          label: 'sidbar_settings_notifications_tab',
          isEnabled: true,
          liClass: 'nav-item dropdownitem_settings ' +(window.location.pathname.includes(SettingRoutes.settings_notifications)?'active':''),
          class: '',
          link: 'notifications-settings' + theuserid,
          has_notifications: 0,
          isPhp: 0,
          img: '',
          isSettings: true,
          eventName: UserEvents.sidbar_settings_notifications_tab
        })
        tempArrMenuItems.push({
          id: 9,
          label: 'sidbar_settings_job_budget_tab',
          isEnabled: true,
          liClass: 'nav-item dropdownitem_settings ' +(window.location.pathname.includes(SettingRoutes.settings_job_budget)?'active':''),
          class: '',
          link: 'job-budget-settings' + theuserid,
          has_notifications: 0,
          isPhp: 0,
          img: '',
          isSettings: true,
          eventName: UserEvents.sidbar_settings_job_budget_tab
        })
        tempArrMenuItems.push({
          id: 10,
          label: 'sidbar_settings_auto_tickets_tab',
          isEnabled: true,
          liClass: 'nav-item dropdownitem_settings ' +(window.location.pathname.includes(SettingRoutes.settings_auto_synonyms_tickets)?'active':''),
          class: '',
          link: 'auto-synonyms-tickets-settings' + theuserid,
          has_notifications: 0,
          isPhp: 0,
          img: '',
          isSettings: true,
          eventName: UserEvents.sidbar_settings_auto_tickets_tab
        })
        
        
      }
      if(UserSession.JCDVisiblePages.includes('JobGroupCRUD') === true){
        tempArrMenuItems.push({
          id: 11,
          label: 'sidbar_settings_job_group_tab',
          isEnabled: true,
          liClass: 'nav-item dropdownitem_settings ' +(window.location.pathname.includes(SettingRoutes.settings_job_group)?'active':''),
          class: '',
          link: 'job-group-settings' + theuserid,
          has_notifications: 0,
          isPhp: 0,
          img: '',
          isSettings: true,
          eventName: UserEvents.sidbar_settings_job_group_tab
        })
      }
      // if(UserSession.JSCDUserType !== undefined && UserSession.JSCDUserType === 'Admin'){
        tempArrMenuItems.push({
          id: 12,
          label: 'sidbar_settings_sea_tab',
          isEnabled: true,
          liClass: 'nav-item dropdownitem_settings ' +(window.location.pathname.includes(SettingRoutes.settings_sea)?'active':''),
          class: '',
          link: 'sea-settings' + theuserid,
          has_notifications: 0,
          isPhp: 0,
          img: '',
          isSettings: true,
          eventName: UserEvents.sidbar_settings_sea_tab
        })
      // }
      log.debug('thisHref',tempArrMenuItems)
      tempAdditionalMenuItems = [];
    }
    
    setArrMenuItems(tempArrMenuItems)

    setAdditionalMenuItems(tempAdditionalMenuItems);


    $('.sidebar li a.active:not(.brand-logo):not(.logoSidebar2)').css('color', 'var(--primary100)');
    $('#sidebar li:not(.dropdown_new) a.active:not(.brand-logo):not(.logoSidebar2)').find('path').attr('fill', 'var(--primary100)');

    // document.body.addEventListener('click', closeSidemenu);
    // $(document).on('mouseenter', 'nav.sidebar', addIconsNav)
    // $(document).on('mouseleave', 'nav.sidebar', removeIcons)

      
    log.debug('navcollapse1', navcollapse)
    if (!window.matchMedia("(max-width: 1500px)").matches) {
      if (!navcollapse) {
        showNavigation('pageStart')
      } else {
        hideNavigation('pageStart')
      }
    }
    window.addEventListener('mousemove', e => {
      $('.nav li:not(.dropdown_new):not(.back-button-block_parent)').removeClass('tempHover');
      $('.sidebar li:not(.dropdown_new):not(.back-button-block_parent) a.disabled').find('path').attr('fill', 'var(--neutral100)');
      $('.sidebar li:not(.dropdown_new):not(.back-button-block_parent) a:not(.active):not(.brand-logo):not(.logoSidebar2):not(.disabled)').find('path').attr('fill', '#1C2D3B');
      $('.nav li a:not(.active):not(.brand-logo):not(.logoSidebar2):not(.disabled)').css('color', '#1C2D3B');
      $('.sidebar li:not(.active):not(.dropdown_new):not(.back-button-block_parent) a.disabled').find('path').attr('fill', 'var(--neutral100)');
      if($(e.target).closest('li').hasClass('nav-logo-small')) return false;

      if (e.target.tagName === 'a') {

        $('.sidebar li:not(.dropdown_new):not(.back-button-block_parent) a:not(.active):not(.brand-logo):not(.logoSidebar2):not(.disabled)').find('path').attr('fill', '#1C2D3B');
        $('.sidebar li:not(.active):not(.dropdown_new):not(.back-button-block_parent) a.disabled').find('path').attr('fill', 'var(--neutral100)');
        $(e.target).find('path').attr('fill', 'var(--primary100)');
        $('.sidebar li.dropdown_new a:not(.disabled)').find('path').attr('fill', '#8D969D');
        $('.nav li:not(.dropdown_new):not(.back-button-block_parent) a:not(.disabled):not(.active):not(.brand-logo):not(.logoSidebar2)[data-href="' + $(e.target).attr('data-href') + '"]').css('color', 'var(--primary100)');
      }

      if (e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.tagName === 'li' || e.target.tagName === 'ul' || e.target.classList.contains('open-nav') || e.target.classList.contains('nav-link') || e.target.classList.contains('badge')) {
        $('.nav li:not(.dropdown_new):not(.back-button-block_parent)').removeClass('tempHover');
        $('.sidebar li:not(.dropdown_new):not(.back-button-block_parent) a:not(.active):not(.brand-logo):not(.logoSidebar2):not(.disabled)').find('path').attr('fill', '#1C2D3B');
        $('.sidebar li:not(.active):not(.dropdown_new):not(.back-button-block_parent) a.disabled').find('path').attr('fill', 'var(--neutral100)');
        $('.sidebar li:not(.dropdown_new):not(.back-button-block_parent) a:not(.active):not(.disabled):not(.brand-logo):not(.logoSidebar2)[data-href="' + $(e.target).closest('a').attr('data-href') + '"]').css('color', 'var(--primary100)');
        $('.sidebar li:not(.dropdown_new):not(.back-button-block_parent) a:not(.active):not(.disabled):not(.brand-logo):not(.logoSidebar2)[data-href="' + $(e.target).closest('a').attr('data-href') + '"]').find('path').attr('fill', 'var(--primary100)');
        $('#sidebar li:not(.dropdown_new):not(.back-button-block_parent) a:not(.active):not(.disabled):not(.brand-logo):not(.logoSidebar2)[data-href="' + $(e.target).closest('a').attr('data-href') + '"]').parent('li').addClass('tempHover');
      }
      $('.sidebar li.disabled a').find('path').attr('fill', 'var(--neutral100)');
    });

   



    return function cleanup() {
      // window.removeEventListener('click', closeSidemenu);
    }
    // eslint-disable-next-line 
  }, [theuserid, isModifiedGlobal, navSynonymsCollapse, navJQXCollapse, navMDCollapse]);

  // Get Notification Count in synonyms navigation
  useEffect(() => {
    if(!window.location.pathname.includes("settings")){
      fetchData();
    }
    $('.chevron-left-nav,.chevron-right-nav').css('top',(window.innerHeight/2 -24)+'px')
    
  }, [])

  function addIconsNav(){
    // alert($('body').hasClass('sidebar-icon-only'))
    
    if (window.matchMedia("(max-width: 1500px)").matches) {
      showNavigation('smallScreen');
    }else{
      if($('body').hasClass('sidebar-icon-only')){
        $('.chevron-right-nav').css('display','none')
        $('.chevron-left-nav').css('display','flex')
        
      }else{
        $('.chevron-left-nav').css('display','none')  
        $('.chevron-right-nav').css('display','flex')     
      }
    }
}
function removeIcons(e){
    // alert(11)
    $('.chevron-right-nav').css('display','none')
    $('.chevron-left-nav').css('display','none')
    if (window.matchMedia("(max-width: 1500px)").matches && $('#sidebar2').is(':visible')) {
      hideNavigation('smallScreen')
  }
}

  let keycounter = 0;
  return (<span onClick={handleDirtySettings} >
    <nav className="sidebar sidebar-offcanvas" id="sidebar" onMouseEnter={addIconsNav} onMouseLeave={removeIcons}>
      <ul className="nav">
        <li className="nav-item nav-logo-small">
          <a className="brand-logo jscdga_menu_logo_mob_link" href={defLandPageUrl} data-href={defLandPageUrl} onClick={e=>handleClickEventRecord(e,isModifiedGlobal,UserEvents.sidebar_nav_logo)}>
            <LogoIconSVG className="logoIconTopLeft" loading="lazy" />
          </a>
        </li>

        {window.location.pathname.includes("settings")?<><li className="nav-item dropdown d-lg-flex back-button-block_parent">
          <a className="nav-link back-button-block" href={defLandPageUrl} data-href={defLandPageUrl} onClick={e=>handleClickEventRecord(e,isModifiedGlobal,UserEvents.sidbar_settings_back_to_js)}>
            <div className="frame-2">
              <div className="frame-139">
                <BackIconSVG />
                {/* <div className="back-to-campaign-portfolio button">
                  {backButtonData.backButtonText}</div> */}
              </div>
            </div>
          </a>
        </li>
        </>:<></>}
        
        
        {arrMenuItems.map((item, index) => {
          if (item.isEnabled === true) {
            // if (item.isDropdown !== undefined) {
            //   return (<li className={'nav-item disable-button navigation_line'} style={{
            //     // marginLeft: "14px",
            //     opacity: "1",
            //   }}>
            //     <div style={{
            //       height: "2px",
            //       width: "36px",
            //       backgroundColor: "#BBC0C4",
            //     }} />
            //   </li>);
            // }
            if (item.isDropdown !== undefined) {
              log.debug('navSynonymsCollapse', navSynonymsCollapse)
              if (item.link === '#sidbar_nav_synonyms_dropdown') {
                keycounter++
                return (<li className={item.liClass} key={'text_1_' + keycounter} onClick={(e) => {
                  e.preventDefault();
                  showSynonymsNavigation()
                }}> <OverlayTrigger
                overlay={(props) => (
                  <Tooltip {...props} className="tooltip-action">
                    <div className="text-left">{t(item.label)} </div>
                  </Tooltip>
                )}
                placement="right"
                html="true"
              >
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={item.link} data-href={item.link} >
                    {/* <span className="menu-title  open-nav">{t(item.label)} </span>  */}
                    <span className="up-arrow" style={{ display: navSynonymsCollapse ? 'none' : '', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#8D969D" />
                      </svg>
                    </span>
                    <span className="down-arrow" style={{ display: navSynonymsCollapse ? '' : 'none', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#8D969D" />
                      </svg>
                    </span>
                  </a></OverlayTrigger>
                </li>);
              }
              if (item.link === '#sidbar_nav_jqx_dropdown') {
                keycounter++
                return (<li className={item.liClass} key={'text_2-' + keycounter} onClick={(e) => {
                  e.preventDefault();
                  showJQXNavigation()
                }}> <OverlayTrigger
                overlay={(props) => (
                  <Tooltip {...props} className="tooltip-action">
                    <div className="text-left">{t(item.label)} </div>
                  </Tooltip>
                )}
                placement="right"
                html="true"
              >
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={ item.link} data-href={item.link} >
                    {/* <span className="menu-title  open-nav">{t(item.label)} </span>  */}
                    <span className="up-arrow" style={{ display: navJQXCollapse ? 'none' : '', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#8D969D" />
                      </svg>
                    </span>
                    <span className="down-arrow" style={{ display: navJQXCollapse ? '' : 'none', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#8D969D" />
                      </svg>
                    </span>
                  </a></OverlayTrigger>
                </li>);
              }
              if (item.link === '#sidbar_nav_md_dropdown') {
                keycounter++
                return (<li className={item.liClass} key={'text_3_' + keycounter} onClick={(e) => {
                  e.preventDefault();
                  showMDNavigation()
                }}> <OverlayTrigger
                overlay={(props) => (
                  <Tooltip {...props} className="tooltip-action">
                    <div className="text-left">{t(item.label)} </div>
                  </Tooltip>
                )}
                placement="right"
                html="true"
              >
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={ item.link} data-href={item.link} >
                    {/* <span className="menu-title  open-nav">{t(item.label)} </span>  */}
                    <span className="up-arrow" style={{ display: navMDCollapse ? 'none' : '', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#8D969D" />
                      </svg>
                    </span>
                    <span className="down-arrow" style={{ display: navMDCollapse ? '' : 'none', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#8D969D" />
                      </svg>
                    </span>
                  </a></OverlayTrigger>
                </li>);
              }
              if (item.link === '#sidbar_settings_dropdown') {
                return <><li className='nav-item nav-item-innovation-line disable-button' style={{ opacity: "1" }}>
                <div style={{
                  width: "36px",
                  height: "2px",
                  backgroundColor: "#BBC0C4",
                }} />
              </li></>
              }

            }
            if (item.isPhp !== undefined) {
              let label = item.img
              if(item.isSettings){
                let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
                let initials = 'JS';
                // label = item.label
                label = [...(t(item.label)).matchAll(rgx)] || [];
                console.log('item1',label)
                label = (
                  (label.shift()?.[1] || '') + (label.pop()?.[1] || '')
                ).toUpperCase();
                if(item.label === 'sidbar_settings_job_group_tab'){
                  label = 'JG';
                }
                // if (label.length === 1) {
                //   label = UserSession.UserName.slice(0, 2);
                //   label = label.toUpperCase();
                // }
              }
              keycounter++
              return (
                <li className={item.liClass} key={'icon_' + index+keycounter}>
                   <OverlayTrigger
                    overlay={(props) => (
                      <Tooltip {...props} className="tooltip-action">
                        <div className="text-left">{t(item.label)} </div>
                      </Tooltip>
                    )}
                    placement="right"
                    html="true"
                  >
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={item.link} data-href={item.link}   onClick={e=>handleClickEventRecord(e,isModifiedGlobal,item.eventName)}>
                    {label}
                  </a>
                  </OverlayTrigger>
                </li>
              )
            } else {
              var innerPageActive = '';
              if (pathname.includes('/cpc-history') && item.link === 'dashboard') {
                innerPageActive = 'active';
              }
              else {
                log.debug('no->>>>>>>>>>>>>>>>>>>>>>>>>>>', pathname + ' ***' + item.link);
              }
              keycounter++
              return (
                <li className={item.liClass} key={'icon_' + index+keycounter}>
                   <OverlayTrigger
                    overlay={(props) => (
                      <Tooltip {...props} className="tooltip-action">
                        <div className="text-left">{t(item.label)} </div>
                      </Tooltip>
                    )}
                    placement="right"
                    html="true"
                  >
                  <a key={item.id+keycounter} className={'nav-link ' + item.class + ' ' + innerPageActive} href={item.link} data-href={item.link}  onClick={e=>handleClickEventRecord(e,isModifiedGlobal,item.eventName)}>
                    {item.img}
                  </a></OverlayTrigger>
                </li>
              )
            }

          }
          return <></>
        })}
        {additionalMenuItems.filter((item) => item.isEnabled === true).length > 0 &&
          (
            <li className={'nav-item disable-button nav-item-innovation-line'} style={{
              // marginLeft: "14px",
              opacity: "1",
            }}>
              <div style={{
                height: "2px",
                width: "36px",
                backgroundColor: "var(--secondary100)",
              }} />
            </li>
          )
        }
        {additionalMenuItems.map((item, index) => {
          if (item.isEnabled === true) {
            if (item.isPhp !== undefined) {
              keycounter++
              return (
                <li className={item.liClass} key={'icon_' + index+keycounter}> <OverlayTrigger
                overlay={(props) => (
                  <Tooltip {...props} className="tooltip-action">
                    <div className="text-left">{t(item.label)} </div>
                  </Tooltip>
                )}
                placement="right"
                html="true"
              >
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={item.link} data-href={item.link}  onClick={e=>handleClickEventRecord(e,isModifiedGlobal,item.eventName)}>
                    {item.img}
                  </a></OverlayTrigger>
                </li>
              )
            } else {
              var innerPageActive = '';
              if (pathname.includes('/cpc-history') && item.link === 'dashboard') {
                innerPageActive = 'active';
              }
              else {
                log.debug('no->>>>>>>>>>>>>>>>>>>>>>>>>>>', pathname + ' ***' + item.link);
              }
              keycounter++
              return (
                <li className={item.liClass} key={'icon_' + index+keycounter}> <OverlayTrigger
                overlay={(props) => (
                  <Tooltip {...props} className="tooltip-action">
                    <div className="text-left">{t(item.label)} </div>
                  </Tooltip>
                )}
                placement="right"
                html="true"
              >
                  <a key={item.id+keycounter} className={'nav-link ' + item.class + ' ' + innerPageActive} href={item.link} data-href={item.link}  onClick={e=>handleClickEventRecord(e,isModifiedGlobal,item.eventName)}>
                    {item.img}
                  </a></OverlayTrigger>
                </li>
              )
            }
          }
          return <></>
        })}

      </ul>
    </nav>
    <div className="chevron-left chevron-left-nav" onMouseEnter={addIconsNav} onMouseLeave={removeIcons} onClick={(e) => showNavigation(e)}><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
      <path d="M1.69922 -5.24537e-07L7.69922 6L1.69922 12L0.299219 10.6L4.89922 6L0.29922 1.4L1.69922 -5.24537e-07Z" fill="#3C83BC" />
    </svg></div>
    <div className="chevron-right chevron-right-nav" onMouseEnter={addIconsNav} onMouseLeave={removeIcons} onClick={(e) => hideNavigation(e)}><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
      <path d="M6.30078 12L0.300781 6L6.30078 0L7.70078 1.4L3.10078 6L7.70078 10.6L6.30078 12Z" fill="#3C83BC" />
    </svg></div>

    
    <nav className="sidebar sidebar-offcanvas" id="sidebar2" onMouseEnter={addIconsNav} onMouseLeave={removeIcons}>
      <ul className="nav">
        <li className="nav-item text-left logoSidebarLI">
          <a key={1+keycounter} className=" jscdga_menu_logo_mob_link logoSidebar2" href={defLandPageUrl} data-href={defLandPageUrl} onClick={e=>handleClickEventRecord(e,isModifiedGlobal,UserEvents.sidebar_nav_logo)}>
            {/* <LogoIconSVG className={"logoIconTopLeft small_logo"} loading="lazy" /> */}
            <LogoTextVG  loading="lazy" className={'full_logo'} />
          </a>
        </li>
        {window.location.pathname.includes("settings")?<><li className="nav-item dropdown d-lg-flex back-button-block_parent">
          <a className="nav-link back-button-block" href={defLandPageUrl} data-href={defLandPageUrl} onClick={e=>{handleClickEventRecord(e,isModifiedGlobal,UserEvents.sidbar_settings_back_to_js)}}>
            <div className="frame-2">
              <div className="frame-139">
                <BackIconSVG />
                <div className="back-to-campaign-btn button">
                  {t('sidebar_settings_back_to_js')}</div>
              </div>
            </div>
          </a>
        </li>
        </>:<></>}
        {arrMenuItems.map((item, index) => {
          if (item.isEnabled === true) {
            if (item.isDropdown !== undefined) {
              log.debug('navSynonymsCollapse', navSynonymsCollapse)
              if (item.link === '#sidbar_nav_synonyms_dropdown') {
                keycounter++
                return (<li className={item.liClass} key={'text_' + index+keycounter} onClick={(e) => {
                  e.preventDefault();
                  showSynonymsNavigation()
                }}>
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={ item.link} data-href={item.link} >
                    <span className="menu-title  open-nav">{t(item.label)} </span>
                    <span className="up-arrow" style={{ display: navSynonymsCollapse ? 'none' : '', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#8D969D" />
                      </svg>
                    </span>
                    <span className="down-arrow" style={{ display: navSynonymsCollapse ? '' : 'none', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#8D969D" />
                      </svg>
                    </span>
                  </a>
                </li>);
              }
              if (item.link === '#sidbar_nav_jqx_dropdown') {
                keycounter++
                return (<li className={item.liClass} key={'text_' + index+keycounter} onClick={(e) => {
                  e.preventDefault();
                  showJQXNavigation()
                }}>
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={ item.link} data-href={item.link} >
                    <span className="menu-title  open-nav">{t(item.label)} </span>
                    <span className="up-arrow" style={{ display: navJQXCollapse ? 'none' : '', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#8D969D" />
                      </svg>
                    </span>
                    <span className="down-arrow" style={{ display: navJQXCollapse ? '' : 'none', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#8D969D" />
                      </svg>
                    </span>
                  </a>
                </li>);
              }
              if (item.link === '#sidbar_nav_md_dropdown') {
                keycounter++
                return (<li className={item.liClass} key={'text_' + index+keycounter} onClick={(e) => {
                  e.preventDefault();
                  showMDNavigation()
                }}>
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={ item.link} data-href={item.link} >
                    <span className="menu-title  open-nav">{t(item.label)} </span>
                    <span className="up-arrow" style={{ display: navMDCollapse ? 'none' : '', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#8D969D" />
                      </svg>
                    </span>
                    <span className="down-arrow" style={{ display: navMDCollapse ? '' : 'none', cursor: 'pointer' }}>
                      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#8D969D" />
                      </svg>
                    </span>
                  </a>
                </li>);
              }

            }
            if (item.isPhp !== undefined) {
              keycounter++
              return (
                <li className={item.liClass} key={'text_' + index+keycounter}>
                  <a key={item.id+keycounter} className={'nav-link ' + item.class} href={ item.link} data-href={item.link} onClick={e=>handleClickEventRecord(e,isModifiedGlobal,item.eventName)}>
                    {item.img} {
                      (item.label === 'sidbar_nav_synonyms_tickets') ?
                        <span className="menu-title  open-nav">{t(item.label)}<span className='badge badge-pill badge-primary' style={{ minWidth: '25px', fontWeight: 700, fontSize: '14px', height: '18px', padding: '1px 4px', marginLeft: '8px' }}>{initData.intTicketsToReview}</span></span>
                        :
                        <span className="menu-title  open-nav">{t(item.label)}</span>
                    }
                    {item.tag !== undefined && item.tag !== ''?<div className="inno_try_now" style={{  padding: "0px 8px", borderRadius: "12px", background: "#B0B4FF", fontSize: "0.8rem", color: "white", lineHeight: "27px" }}>{t(item.tag)}</div>:<></>}
                  </a>
                </li>
              )
            } else {
              var innerPageActive = '';
              if (pathname.includes('/cpc-history') && item.link === 'dashboard') {
                innerPageActive = 'active';
              }
              keycounter++
              return (

                <li className={item.liClass} key={'text_' + index+keycounter}>
                  <a key={item.id+keycounter} className={'nav-link ' + item.class + ' ' + innerPageActive} href={ item.link} data-href={item.link} onClick={e=>handleClickEventRecord(e,isModifiedGlobal,item.eventName)}>
                    {item.img}  <span className="menu-title  open-nav">{t(item.label)}</span>
                  </a>
                </li>
              )
            }

          }
          return <></>
        })}
        {additionalMenuItems.filter((item) => item.isEnabled === true).length > 0 &&
          (
            <li className='nav-item nav-item-innovation-line disable-button' style={{ opacity: "1" }}>
              <div style={{
                width: "252px",
                height: "2px",
                backgroundColor: "var(--secondary100)",
              }} />
            </li>
          )
        }
        {additionalMenuItems.map((item, index) => {
          if (item.isEnabled === true) {
            
              var innerPageActive = '';
              if (pathname.includes('/cpc-history') && item.link === 'dashboard') {
                innerPageActive = 'active';
              }
              keycounter++
              return (

                <li className={item.liClass} key={'text_' + index+keycounter}>
                  <a key={item.id+keycounter} className={'nav-link ' + item.class + ' ' + innerPageActive} href={ item.link} data-href={item.link}
                    onClick={e=>handleClickEventRecord(e,isModifiedGlobal,item.eventName)}>
                    {item.img}   <span className="menu-title  open-nav">{t(item.label)}</span>
                    <div className="inno_try_now" style={{ marginLeft: "8px", padding: "0px 8px", borderRadius: "12px", background: "#B0B4FF", fontSize: "0.8rem", color: "white", lineHeight: "27px" }}>{t(item.tag)}</div>
                  </a>
                </li>
              )

          }
          return <></>
        })}
       



        {/* <li className='nav-item' id="feedback_beta" onClick={() => { setNewModal(true); setModalTitle(t('feedback_beta_box_title_new')); }}>
          <img src={feedbackIconNav} className="ml-4 cursor-pointer" alt='Feedback Icon' />
        </li> */}

      </ul>
    </nav>
  </span>
  )
}
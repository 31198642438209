import log from "loglevel";
import React from "react";
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { Session } from 'bc-react-session';
import { ReactComponent as SwitchAccountIcon } from '../../assets/icons/switchAccountIcon.svg';
import { Buffer } from 'buffer';
import { recordUserEvent } from "../blocks/common/record-user-event";
import { UserEvents } from "../../config/UserEvents";
import { handleClickEventRecordForDropdown } from "../blocks/common-blocks";
import { useWMSContext } from "../../wmsContext";

export default function SubUserDropdown() {

    const { payload } = Session.get('login');
    const UserSession = payload;
    const { t } = useTranslation();
    const totalSubAccounts = Object.keys(UserSession.SubAccounts).length;
    let dropdownSubUser = (totalSubAccounts > 1 ? true : false);
    const [subUserShow, setSubUserShow] = React.useState(false);

    const stopClose = (e) => {
        if(isModifiedGlobal) return false;
        setSubUserShow(!subUserShow);
        if (UserSession !== undefined) {


        }
        e.stopPropagation();
    }
    let currentUserId = UserSession.UserID;
    let params = new URLSearchParams(window.location.search);
    let subUserID = params.get("theuserid");
    if (subUserID != null) {
        let bufferObj = Buffer.from(subUserID, "base64")
        currentUserId = bufferObj.toString("utf8");
    }
    var arrSortedSubUsers = [];
    if (UserSession.SubAccounts !== undefined) {
        Object.keys(UserSession.SubAccounts).map(function (element) {
            if (UserSession.SubAccounts[element].DefaultAdmin === 'No') {
                arrSortedSubUsers.push(UserSession.SubAccounts[element])
            }
            return ''
        })
    }
    const sortByName = arr => {
        const sorter = (a, b) => {
            return String(a.Name).toLowerCase() > String(b.Name).toLowerCase() ? 0 : -1;
        };
        arr.sort(sorter);
        return arr;
    };

    sortByName(arrSortedSubUsers)
    log.debug(arrSortedSubUsers)
    log.debug((UserSession.SubAccounts))
    const {isModifiedGlobal} = useWMSContext()
    // Set/Update Nav Dropdown Language Btn
    if (dropdownSubUser) {
        return (
            <>
                <Dropdown.Item className=" font-weight-medium noHoverSubUser" onClick={stopClose} style={{ fontSize: '16px', color: '#1c2d38', lineHeight: '27px', fontWeight: 400 }} >
                    <Dropdown show={subUserShow} className="nav-item subUsersDropdown" style={{ marginLeft: '0px', marginRight: '0px', width: '100%' }}>
                        <Dropdown.Toggle className="subUserToggle" aria-expanded="false" style={{ color: '#1C2D3B', background: 'none', lineHeight: '27px', border: 'none', marginLeft: '0px', boxShadow: 'none', fontWeight: 400, fontSize: '16px' }}>
                            <SwitchAccountIcon style={{ marginRight: '14px' }} />{t('pf_beta_switch_account')} <span className="subAccountCount ml-1">{totalSubAccounts}</span>
                        </Dropdown.Toggle>
                        <div className="dropdown-menu subUser navbar-dropdown" aria-labelledby="userDropdown" href="#" style={{ top: '0px', marginTop: '-12px', marginRight: '29px', overflow: 'auto', maxHeight: '490px', left: "auto", right: "100%" }}>
                            {
                                Object.keys(UserSession.SubAccounts).map(function (element) {
                                    if (UserSession.SubAccounts[element].DefaultAdmin === 'Yes') {
                                        return <Dropdown.Item data-sl="mask" className={`${currentUserId === UserSession.SubAccounts[element].ID ? 'activeSubAccount' : ''}`} onClick={() => {
                                            if(isModifiedGlobal) return false;
                                            recordUserEvent({
                                                session_data: UserSession,
                                                eventName: UserEvents.topbar_user_switch_account
                                            });
                                            window.location.href = "dashboard?theuserid=" + Buffer.from((UserSession.SubAccounts[element].ID)).toString('base64')
                                        }}>{UserSession.SubAccounts[element].Name}</Dropdown.Item>
                                    } else {
                                        return <></>
                                    }
                                })}
                            {
                                arrSortedSubUsers.map(function (element) {
                                    log.debug(element)
                                    if (element.DefaultAdmin === 'No') {
                                        return <Dropdown.Item data-sl="mask" className={`${currentUserId === element.ID ? 'activeSubAccount' : ''}`} onClick={() => {
                                            if(isModifiedGlobal) return false;
                                            recordUserEvent({
                                                session_data: UserSession,
                                                eventName: UserEvents.topbar_user_switch_account
                                            });
                                            window.location.href = "dashboard?theuserid=" + Buffer.from((element.ID)).toString('base64')
                                        }}>{element.Name}</Dropdown.Item>
                                    } else {
                                        return <></>
                                    }
                                })}
                        </div>
                    </Dropdown>
                </Dropdown.Item>
            </>
        )

    }
}
export const RouteMap = {
  "": "",
  
  dashboard: "dashboard",
  job_groups_dashboard: "job-groups-dashboard",
  cpc_history: "cpc-history",
  myjobs: "myjobs",
  job_groups: "job-groups",
  settings: "settings",
  title_gpt: "title-gpt",
  sourcing_search: "sourcing-search",
  innovation_lab: "innovation-lab",
  test: "test",
  logout: "logout",
  theuserid: "theuserid",
  forgot_password: 'forgot_password',

};

export const SettingRoutes = {
  settings_personal: "personal-settings",
  settings_users_rights: "users-rights-settings",
  settings_notifications: "notifications-settings",
  settings_job_budget: "job-budget-settings",
  settings_auto_synonyms_tickets: "auto-synonyms-tickets-settings",
  settings_job_group: "job-group-settings",
  settings_sea: "sea-settings",
};

export const InnovationLabRoutes = {
  sourcing_search_builder: "sourcing-search-builder",
  job_briefing: "job-briefing",
  click_performance_indicator: "click-performance-indicator",
};

export const URLAccessibleWithoutSession = {
  login:"login",
};

import log from "loglevel";
import React, { useEffect, useState } from "react";
// Handle console logs
import "./utils/dropConsole";

import { Session } from "bc-react-session";
import i18n from "i18next";
import { ReactComponent as LogoLoaderDE } from './assets/icons/LoaderGermanLogo.svg';
import { ReactComponent as LogoLoaderEN } from './assets/icons/LoaderEnglishLogo.svg';
// import "./App.scss";



import LoginPage from "./components/LoginPage";

import { BASE_URL, GOOGLE_SIGN_IN_CLIENT_ID, IS_LOCALHOST, arrSigninAsAdminAuthId } from "./config/Constants";
import TagManager from "react-gtm-module";
import smartlookClient from "smartlook-client";
import { SERVER_URL } from "./services/ServiceConstants";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
const tagManagerArgs = {
  gtmId: "GTM-5MLBD6W",
};

const config = {
  auth: {
      clientId: '8ec728cb-a57b-4947-9e8f-985f27fb0d70',
      authority: "https://login.microsoftonline.com/common"
  }
};


function AppLogin(params) {
  // Session.start('login');
  const [globalData, setGlobalData] = useState(false);
  // create PublicClientApplication instance
  const publicClientApplication = new PublicClientApplication(config);
  // alert(222)
  console.log(i18n.language)

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    const { payload } = Session.get("login");
    const UserSession = payload;
    if (UserSession.status === "loggedin") {
      setGlobalData(true);
    }

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    // Set Zoom Level and don't go below 1280px width
    // if(document.body.style.zoom != ''){
    if (window.innerWidth < 1280) {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute("content", "width=1280, shrink-to-fit=yes");
      document.body.style.zoom = (window.innerWidth * 100) / 1280 - 1 + "%";
    } else {
      document
        .querySelector('meta[name="viewport"]')
        .setAttribute(
          "content",
          "width=device-width, initial-scale=1, shrink-to-fit=no"
        );
      document.body.style.zoom = "0%";
    }
    if (window.innerWidth > 3840) {
      document.body.style.zoom = (window.innerWidth * 100) / 3840 - 1 + "%";
    }
    // }
  };

  
  //  Don't delete the code below it is used on LIVE

  const { payload } = Session.get("login");
  const UserSession = payload;
  const { intlLocale = "en-US" } = UserSession;
  // eslint-disable-next-line

  log.debug("--- App Js return() Render --- basname = ", BASE_URL);
  
  return (
    <>
      { globalData !== undefined && globalData !== false ?  (
        
        <div className="App AppLogin">
          {handleResize()}
          <div className="container-scroller white-bg">
            <div className="container-fluid page-body-wrapper">
              <div className="row">
                <div className="content-wrapper full-page-wrapper d-flex align-items-center index-login auth">
                  <div className="row w-100">
                    <div className="col-lg-8 mx-auto">
                      <div className="row">
                        <h1 id="logo" className="mx-auto text-center">
                            {/* <LogoIcon /> */}
                            {i18n.language === 'en' ? <LogoLoaderEN  loading="lazy" className={'full_logo'} /> : <LogoLoaderDE  loading="lazy" className={'full_logo'} />}
                          
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ):<>
      <GoogleOAuthProvider clientId={GOOGLE_SIGN_IN_CLIENT_ID}>
        <LoginPage /></GoogleOAuthProvider></>}
    </>
  );
}
export default AppLogin;

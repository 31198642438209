import log from "loglevel";
import { Session } from "bc-react-session";
import React from "react";
import { Button, Modal } from "react-bootstrap";
// import { i18next } from "react-i18next";
import { getPortfolioData } from "../../../../services";
import "../../../../styles/_jobgrouphistory.scss";
import "../../../../styles/jscd_changelog.scss";
import "../../../../styles/_pagination.scss";
import Loader from "../../common/loader-block";
import ModalBodyScrollIcon from "../../common/scroll-down";
import { recordUserEvent } from "../../common/record-user-event";

import i18next from "i18next";
import { UserEvents } from "../../../../config/UserEvents";
// const { t } = useTranslation();
const { payload } = Session.get("login");
const UserSession = payload;
const search = window.location.search;
const params = new URLSearchParams(search);

const paramsUserID = params.get("theuserid");
let theuserid = UserSession.UserID;

if (paramsUserID != null) {
  theuserid = paramsUserID;
}

/**
 * Renders an JSCDChangeLog component.
 * @since 26.02.2024
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The rendered JSCDChangeLog component.
 */
class JSCDChangeLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaderProgress: 0,
      itemsPerPage: 25,
      itemsPerPageChangeLog: 25,
      changeLogData: {},
      activeChangeLogData: { intLastChangeLogId: 0 },
      show: false,
      modalTitle: i18next.t("changelog_heading"),
      footerBtnTitle: i18next.t("changelog_back_btn_text"),
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }
  handleClose() {
   
    recordUserEvent({
        session_data: UserSession,
        eventName: UserEvents.Topbar_Change_Log_Close
    });
    
    this.setState({ loading: false, show: false });
  }
  loadMore() {
    let postDataProtFolioTable = {
      getChangeLog: 1,
      userID: theuserid,
      itemsPerPage: parseInt(this.state.itemsPerPageChangeLog) + 25,
      sessionData: UserSession,
    };

    this.getChangeLogData(postDataProtFolioTable);
  }

  handleShow() {
  
    recordUserEvent({
      session_data: UserSession,
      eventName: UserEvents.Topbar_Change_Log_Open
    });

    this.setState({
      loading: true,
      loaderProgress: 0,
      itemsPerPageChangeLog: 25,
      changeLogData: {},
      activeChangeLogData: { intLastChangeLogId: 0 },
      show: true,
      modalTitle: i18next.t("changelog_heading"),
      footerBtnTitle: i18next.t("changelog_back_btn_text"),
    });
    // const { id } = this.props.match.params
    let postDataProtFolioTable = {};
    this.getChangeLogData(postDataProtFolioTable);
  }

  getChangeLogData = (postDataProtFolioTable) => {
    postDataProtFolioTable["page"] = 1;
    postDataProtFolioTable["getChangeLog"] = 1;
    postDataProtFolioTable["userID"] = theuserid;
    postDataProtFolioTable["sessionData"] = UserSession;

    getPortfolioData(postDataProtFolioTable).then((response) => {
      this.setState({ changeLogData: response, loading: false, itemsPerPageChangeLog: response.history.length });
    });
  };
  getActiveChangeLogData = (postDataProtFolioTable) => {
    postDataProtFolioTable["itemsPerPage"] = 1;
    postDataProtFolioTable["page"] = 1;
    postDataProtFolioTable["getPageInitChangeLogDetails"] = 1;
    postDataProtFolioTable["userID"] = theuserid;
    postDataProtFolioTable["sessionData"] = UserSession;

    getPortfolioData(postDataProtFolioTable).then((response) => {
      this.setState({ activeChangeLogData: response});
    });
  };

  componentDidMount() {
    let postDataProtFolioTable = {};
    this.getActiveChangeLogData(postDataProtFolioTable);
    
  } 

  

  render() {
    
    return (
      <>
      <div className="jg-history change-log ml-0 mr-0 nav-item" onClick={this.handleShow}>
      <div >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            style={{ cursor: "pointer" }}
          >
            <path
              d="M6 17H14V15H6V17ZM6 14H14V12H6V14ZM10 10.3C11.1 9.3 12.0417 8.4125 12.825 7.6375C13.6083 6.8625 14 6.05 14 5.2C14 4.6 13.7833 4.08333 13.35 3.65C12.9167 3.21667 12.4 3 11.8 3C11.45 3 11.1125 3.07083 10.7875 3.2125C10.4625 3.35417 10.2 3.55 10 3.8C9.8 3.55 9.5375 3.35417 9.2125 3.2125C8.8875 3.07083 8.55 3 8.2 3C7.6 3 7.08333 3.21667 6.65 3.65C6.21667 4.08333 6 4.6 6 5.2C6 6.05 6.37917 6.85 7.1375 7.6C7.89583 8.35 8.85 9.25 10 10.3ZM16 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V2C2 1.45 2.19583 0.979167 2.5875 0.5875C2.97917 0.195833 3.45 0 4 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V18C18 18.55 17.8042 19.0208 17.4125 19.4125C17.0208 19.8042 16.55 20 16 20ZM4 18H16V2H4V18Z"
              fill="#1C1B1F"
            />
          </svg>
          {this.state.activeChangeLogData.intLastChangeLogId !== 0 ? (
            <svg height="10" width="10" className="blinking">
              <circle cx="5" cy="5" r="5" fill="#dd5050"></circle>Sorry, your browser does not support inline SVG.
            </svg>
          ) : (
            <></>
          )}
        </div>
<span className="changelogtext">{i18next.t('pf_changeLog_text')}</span>
</div>
        

        {this.state.show ? (
          <Modal
            className={"msgModal changelogModal"}
            dialogClassName={""}
            id={"msgModal"}
            show={this.state.show}
            onHide={this.handleClose}
            onClose={this.handleClose}
            backdropClassName={"msgModalBackDrop"}
            contentClassName={"whitebg"}
          >
            <Modal.Header closeButton style={{ paddingBottom: "16px !important" }}>
              <Modal.Title>
                <span>{this.state.modalTitle}</span>
              </Modal.Title>
            </Modal.Header>

            <ModalBodyScrollIcon className="">
              {/* Loader Starts */}
              {this.state.loading ? <Loader loadingTimer={2} style={{ width: "120px", height: "120px" }} /> : <></>}
              {/* <Tabs tabsState={tabsState} changeLogData={changeLogData.data} pager={pager} /> */}
              {/* Loader Ends */}
              {this.state.changeLogData !== false && !this.state.loading ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="settings-block " id="pf-campaign-budget-summary-block">
                        <div className="tab-content tab-content-solid pt-0">
                          <div
                            className={"tab-pane fade" + (true ? " active show " : "")}
                            id="settings_tab_change_log"
                            role="tabpanel"
                          >
                            <>
                              <div className="history_intro_text">{i18next.t("changelog_introduction_text")}</div>
                              {this.state.changeLogData !== false &&
                                this.state.changeLogData.history !== undefined &&
                                Object.keys(this.state.changeLogData.history).length === 0 && (
                                  <div className="changelogFrame">{i18next.t("jg_hist_no_records_found")}</div>
                                )}
                              {this.state.changeLogData !== false &&
                                this.state.changeLogData.history !== undefined &&
                                this.state.changeLogData.history.map(function (item, index) {
                                  log.debug("ChangeLogTab", index);
                                  return (
                                    <div key={"ChangeLogTab" + index} className="changelogFrame">
                                      <ChangeLogRow keyIndex={"change_log" + index} data={item} show={index === 0} />
                                    </div>
                                  );
                                })}
                            </>
                          </div>

                          {/* <!-- Tabs Content  --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {this.state.changeLogData !== undefined && this.state.changeLogData !== undefined ? (
                <>
                  {this.state.changeLogData.history !== undefined &&
                  parseInt(Object.keys(this.state.changeLogData.history).length) > 0 ? (
                    <>
                      <div className="row">
                        <div className="col-12 text-center mt-3">
                          {this.state.itemsPerPageChangeLog < parseInt(this.state.changeLogData.paginationTotal) ? (
                            <Button
                              variant="primary"
                              className="loadMoreAction "
                              onClick={(e) => {
                                this.loadMore(e);
                              }}
                            >
                              {i18next.t("jg_hist_load_more_text")}
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </ModalBodyScrollIcon>
            <Modal.Footer>
              <Button variant="primary" className={"primary_action_button p-2"} onClick={this.handleClose}>
                {this.state.footerBtnTitle}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

/**
 * Renders an ChangeLogRow component.
 * @since 26.02.2024
 * @param {Object} props - The component props.
 * @param {string} props.show - The property uses the default value for expansion of detailed description.
 * @param {Object} props.data - The content of the change log row including the title, description and date.
 * @returns {JSX.Element} The rendered ChangeLogRow component.
 */
class ChangeLogRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: !props.show,
      data: props.data,
    };
    this.handleshowDetails = this.handleshowDetails.bind(this);
  }

  handleshowDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  };
  render() {
    log.debug("ChangeLogFrame was rendered at", new Date().toLocaleTimeString());
    log.debug("ChangeLogFrame was rendered at", this.state);
    return (
      <div className="changelogFrameDetail">
        <div className={"rowHist " + (!this.state.showDetails ? "expanded" : "")}>
          <div className={"historyDetails "}>
            <p className="user-details">
              {/* <span className="text-wrapper-2">&nbsp;</span> */}
              <span className="date">{this.state.data.ActionTimeFormatted}</span>
              <div className="actionButtonHistory">
                {String(this.state.data.LongDescription).length > 0 ? (
                  <Button variant="primary" className="p-2" onClick={this.handleshowDetails}>
                    {this.state.showDetails ? i18next.t("jg_hist_show_details") : i18next.t("jg_hist_hide_details")}
                    <span className="up-arrow ml-2" style={{ display: this.state.showDetails ? "none" : "" }}>
                      <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#3c83bc" />
                      </svg>
                    </span>
                    <span className="down-arrow ml-2" style={{ display: this.state.showDetails ? "" : "none" }}>
                      <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#3c83bc" />
                      </svg>
                    </span>
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </p>
            <div
              className="changeDetails"
              // style={{ display: !showDetails ? "none" : "" }}

              // {data.ShortDescription}
            >
              <>
                <span className="name" data-sl="mask">
                  {this.state.data.Title}
                </span>
              </>
            </div>

            <div
              className="expandedHistTop"
              style={{ display: this.state.showDetails ? "none" : "" }}
              dangerouslySetInnerHTML={{ __html: this.state.data.LongDescription }}
            ></div>
            <div className="card-content-tags">
              {this.state.data.Tags !== undefined ? (
                this.state.data.Tags.map((tag, index) => {
                  return <Tag key={index} bgcolor="#B0B4FF" text={tag} skeleton={false} />;
                })
              ) : (
                <>
                  <Tag bgcolor="#B0B4FF" skeleton={true} />
                  <Tag bgcolor="#B0B4FF" skeleton={true} />
                  <Tag bgcolor="#B0B4FF" skeleton={true} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Renders an Tag component.
 * @since 26.02.2024
 * @param {Object} props - The component props.
 * @param {string} props.bgcolor - The background color of the tag.
 * @param {string} props.text - The text content of the tag.
 * @param {boolean} [props.skeleton=false] - Indicates if the tag should be rendered as a skeleton animation.
 * @returns {JSX.Element} The rendered Tag component.
 */
class Tag extends React.Component {
  render() {
    let { bgcolor, text, skeleton = false } = this.props;
    if (text !== undefined && text.trim() === "" && !skeleton) return <></>;
    return (
      <div
        className={`innovation-lab-tag ${skeleton && "skeleton-anim-tags"}`}
        style={skeleton ? {} : { background: bgcolor }}
      >
        <p>{skeleton ? "." : text}</p>
      </div>
    );
  }
}



export default JSCDChangeLog;
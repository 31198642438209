export const UserEvents = {
  Topbar_Change_Log_Open: 'Topbar_Change_Log_Open',
  Topbar_Change_Log_Close: 'Topbar_Change_Log_Close',
  Topbar_HelpCenter_Link: 'Topbar_HelpCenter_Link',
  sidebar_nav_logo : 'sidebar_nav_logo',
  
  // sidbar_settings_sea_tab : 'sidbar_settings_sea_tab',
  sidebar_nav_portfolio: 'sidebar_nav_portfolio',
  sidebar_nav_jobgroups: 'sidebar_nav_jobgroups',
  sidebar_nav_myjobs: 'sidebar_nav_myjobs',
  sidbar_nav_synonyms_dropdown_open: 'sidbar_nav_synonyms_dropdown_open',
  sidbar_nav_synonyms_dropdown_close: 'sidbar_nav_synonyms_dropdown_close',
  sidbar_nav_synonyms_overview: 'sidbar_nav_synonyms_overview',
  sidbar_nav_synonyms_tickets: 'sidbar_nav_synonyms_tickets',
  sidbar_nav_jqx_dropdown_open: 'sidbar_nav_jqx_dropdown_open',
  sidbar_nav_jqx_dropdown_close: 'sidbar_nav_jqx_dropdown_close',
  sidebar_nav_jqx_new_menu: 'sidebar_nav_jqx_new_menu',
  sidebar_nav_jqx_compare: 'sidebar_nav_jqx_compare',
  sidebar_nav_jtc: 'sidebar_nav_jtc',
  sidbar_nav_md_dropdown_open : 'sidbar_nav_md_dropdown_open',
  sidbar_nav_md_dropdown_close : 'sidbar_nav_md_dropdown_close',
  sidebar_nav_mkt_data_main : 'sidebar_nav_mkt_data_main',
  sidebar_nav_mkt_data_checker : 'sidebar_nav_mkt_data_checker',
  sidebar_nav_innovationlab : 'sidebar_nav_innovationlab',
  sidbar_settings_dropdown : 'sidbar_settings_dropdown',
  sidbar_settings_personal_tab : 'sidbar_settings_personal_tab',
  sidbar_settings_users_permission_tab : 'sidbar_settings_users_permission_tab',
  sidbar_settings_notifications_tab : 'sidbar_settings_notifications_tab',
  sidbar_settings_job_budget_tab : 'sidbar_settings_job_budget_tab',
  sidbar_settings_auto_tickets_tab : 'sidbar_settings_auto_tickets_tab',
  sidbar_settings_job_group_tab : 'sidbar_settings_job_group_tab',
  sidbar_settings_sea_tab : 'sidbar_settings_sea_tab',
  sidbar_settings_back_to_js : 'sidbar_settings_back_to_js',
  InnovationLab_SideNavigation: 'InnovationLab_SideNavigation',
  sidebar_nav_show_navigation : 'sidebar_nav_show_navigation',
  sidebar_nav_hide_navigation : 'sidebar_nav_hide_navigation',
  topbar_link_settings:'topbar_link_settings',
  topbar_language_update:'topbar_language_update',
  topbar_link_logout:'topbar_link_logout',
  topbar_user_dropdown:"topbar_user_dropdown",
  topbar_user_switch_account:"topbar_user_switch_account"
};


import log from "loglevel";
import React from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import CookieConsentMain from './common/cookie-consent-main';
import ModalComponent from './modal-component';
import { Session } from 'bc-react-session';
import '../../styles/Footer.css';
import '../../styles/Footer.css';
import { useEffect } from 'react';
import CookieConsent2 from './common/cookie-consent-2';
import { setPortfolioData } from '../../services';
import { arrSigninAsAdminAuthId } from '../../config/Constants';
import BackToTop from "./common/back-to-top";

export default function Footer({isCampaignLevel,isJobGroupLevel}) {
    const { payload } = Session.get('login');
    const UserSession = payload;
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const { t } = useTranslation();
    const [modalTitle, setModalTitle] = useState(t('feedback_beta_box_title'));
    const [checkedGA, setCheckedGA] = useState(true);
    const [checkedSL, setCheckedSL] = useState(true);
    const handleChangeGA = () => {
        setCheckedGA(!checkedGA);
    };
    const handleChangeSL = () => {
        setCheckedSL(!checkedSL);
    };
    const [showGA, setshowGA] = useState(true);
    const [showSL, setshowSL] = useState(true);
    const [cookieConsentExists, setcookieConsentExists] = useState(false);


    const handleShowGA = () => {
        setshowGA(!showGA);
    };
    const handleShowSL = () => {
        setshowSL(!showSL);
    };
    const hideModal2 = () =>{
        setModal2(false);
        setModal(false);
        setshowGA(true);
        setshowSL(true);
        if(UserSession.UIPreferences.cookie_consent !== undefined){
            setCheckedGA(UserSession.UIPreferences.cookie_consent[0].ga === 'true'?true:false)
            setCheckedSL(UserSession.UIPreferences.cookie_consent[0].smartlook === 'true'?true:false)
        }
    }


    const handleSubmitConsent = () => {
        const params = new URLSearchParams(window.location.search);
        const paramsUserID = params.get("theuserid");
        let theuserid = UserSession.UserID;
        
        if(paramsUserID != null){
            theuserid = paramsUserID;
        }
        
        let postDataConsent = { setCookieConsent: 1, userID: theuserid, sessionData: UserSession, method: 'setCookieConsent', ga: (checkedGA ? 'true' : 'false'), smartlook: (checkedSL ? 'true' : 'false') };
        // let arrUIPreferences = UserSession.UIPreferences;
        setPortfolioData(postDataConsent).then((consentResponse) => {
            if(consentResponse.status === 'true'){
                // alert(consentResponse.message)    
            }
            setModal2(false);
            setModal(false);
            // arrUIPreferences['cookie_consent'] = [];
            // arrUIPreferences['cookie_consent'][0] = {ga: (checkedGA ? 'true' : 'false'),smartlook: (checkedSL ? 'true' : 'false')};
            // // log.debug('arrUIPreferences',arrUIPreferences)
            // Session.setPayload({
            //     UIPreferences:arrUIPreferences
            // });
            window.location.reload();
            // setArrSessionCols(arrColsToShow);
        })
    }
   
    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        const paramsAdmin = params.get("theuserid") === null?false:true;
        const signInAsAdmin = (UserSession.UserName === 'Demo' || (UserSession.intAuthID !== undefined && arrSigninAsAdminAuthId.includes(parseInt(UserSession.intAuthID))) || UserSession.LoginByMasterPass || paramsAdmin);
        if (!signInAsAdmin){
            if(UserSession.UIPreferences.cookie_consent !== undefined){
                setCheckedGA(UserSession.UIPreferences.cookie_consent[0].ga === 'true'?true:false)
                setCheckedSL(UserSession.UIPreferences.cookie_consent[0].smartlook === 'true'?true:false)
                setcookieConsentExists(true)
            }
            if(UserSession.UIPreferences.cookie_consent === undefined){
                setCheckedGA(true)
                setCheckedSL(true)
                setModal(true)
                setcookieConsentExists(false)
            }
        }
        
    }, [])
    
    return <footer className={"footer "+(isCampaignLevel || isJobGroupLevel?'isSecondLevel':'')}>
        <BackToTop />
        <div className="row footer_bottom">
            <div className="col-md-12">
                <div className="footer_left">
                    <a href="https://wollmilchsau.de/karriere/#uberuns" rel="noreferrer" target="_blank" className="jscdga_wms_carrer_link">{t("footer_aboutus_text")}</a> <span className="jcd-xs-hidden">|</span> <a rel="noreferrer" href="https://wollmilchsau.de/kontakt/impressum/" target="_blank">{t("footer_impressum_text")}</a> <span className="jcd-xs-hidden">|</span> <a rel="noreferrer" href="https://wollmilchsau.de/kontakt/datenschutzerklaerung/" target="_blank">{t("footer_datenschutz_text")}</a> <span className={"jcd-xs-hidden"}>|</span> <span style={{cursor:'pointer'}} onClick={() => { setModal(true); setModalTitle(''); }} className={((UserSession.UserName === 'Demo' || UserSession.LoginByMasterPass === true || (UserSession.intAuthID !== undefined && arrSigninAsAdminAuthId.includes(parseInt(UserSession.intAuthID)))) ? " disabledButton " : "")}>{t("footer_cookie_settings")}</span> <span className="jcd-xs-hidden">|</span> <a href="mailto:support@wollmilchsau.de">{t("footer_support_text")}</a>
                </div>
                <div className="footer_right">
                    <a href="https://wollmilchsau.de/" rel="noreferrer" target="_blank" className="jscdga_wms_site_link"><span>© {(new Date().getFullYear())}</span><span className="copyright-text">{t("footer_copyright_text")}</span></a>
                </div>
            </div>
        </div>
        <ModalComponent
            show={modal}
            modalClassName="cookie-modal"
            size='lg'
            modalId=""
            handleClose={() => { setModal(false); }}
            handleShow={() => { setModal(true); }}
            modalTitle={modalTitle}
            backdrop={UserSession.UIPreferences !== undefined && UserSession.UIPreferences.cookie_consent === undefined ?"static":''}
            keyboard={UserSession.UIPreferences !== undefined && UserSession.UIPreferences.cookie_consent === undefined ?false:true}
            >
            {UserSession.UIPreferences.cookie_consent === undefined ? <CookieConsentMain checkedGA={checkedGA} checkedSL={checkedSL} handleChangeGA={handleChangeGA} handleChangeSL={handleChangeSL} showGA={showGA} showSL={showSL} handleShowGA={handleShowGA} handleShowSL={handleShowSL} handleSubmitConsent={handleSubmitConsent} modal2={modal2} setModal2={setModal2} cookieConsentExists={cookieConsentExists} hideModal2={hideModal2}/> : <CookieConsent2 checkedGA={checkedGA} checkedSL={checkedSL} handleChangeGA={handleChangeGA} handleChangeSL={handleChangeSL} showGA={showGA} showSL={showSL} handleShowGA={handleShowGA} handleShowSL={handleShowSL} handleSubmitConsent={handleSubmitConsent} modal2={modal2} setModal2={setModal2} cookieConsentExists={cookieConsentExists} hideModal2={hideModal2}/>}
        </ModalComponent>
       
    </footer>

}
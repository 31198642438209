import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { BASE_URL, IS_LOCALHOST } from "./config/Constants";
import packageJson from '../package.json'
var loc = '';
const updateLangAttribute = () => {
  document.body.setAttribute('lang', i18n.language);
};

// Listen to language change events and update the lang attribute
i18n.on('languageChanged', updateLangAttribute);
if(IS_LOCALHOST){
  loc = '';
}
else{
  loc = window.location.origin+'/'+BASE_URL;
}

i18n
.use(Backend)
.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // lng: "de",
    fallbackLng: "de", // use en if detected lng is not available
    supportedLngs: ['de','en'],
    cleanCode:false,
    debug: false,
    load: 'currentOnly',
    backend: {
        loadPath: loc+'locales/{{lng}}/translation.json?v='+packageJson.version,
      },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
import log from "loglevel";
import { Session } from 'bc-react-session';
import 'bootstrap-daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'moment/locale/de';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { ReactComponent as IconCalendar } from "../../../assets/icons/IconCalendar.svg";
import { useTranslation } from 'react-i18next';
import "../../../styles/DateRangePicker.css";

export default function JSDateRangePicker({data, clickBlock, clicksBlockChangeHandler, conversionBlock, conversionBlockChangeHandler,portfolioTableHandler,renderedFrom,portfolioTableData,isJobGroupLevel,jgData }) {

    const { payload } = Session.get('login');
    const UserSession = payload;
    const locale_short = UserSession.intlLocale.split('-')[0];
    moment.locale(locale_short);
    // const { data } = props;
    const { t } = useTranslation();
    const dateRangePickerData = data;
    const [showTooltip,setShowTooltip] = useState(false);
    const [ranges, setRanges] = useState({});
    const keyRef = useRef(Date.now());
    const [state, setState] = useState({
        start: moment().subtract(60, 'days'),
        end: moment(),
    });
    const [randoNum] = useState("drp" + Math.floor(Math.random() * 1000000000));
    const { start, end } = state;
    const label = useRef(`${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`);
    let showPicker=0;
    let pastCampaignsClicked=useRef(0);
    let tempArr = useMemo(() => { return [] }, []);
    let tempValues = []
    let minDate=null;
    let drpmaxDate=dateRangePickerData.maxDate;

    // Arrow icon for past campaigns
    // let initialRender=true;

    // use states here to preverse value on re-render
    const [initialRender] = useState(true);
    var downIcon=document.createElement('span');
    downIcon.setAttribute('id','downArrow');
    downIcon.innerHTML='<i class="fa fa-angle-down pull-right mt-1"></i>';
    
    var upIcon=document.createElement('span');
    upIcon.setAttribute('id','upArrow');
    upIcon.innerHTML='<i class="fa fa-angle-up pull-right mt-1"></i>';
    const params = new URLSearchParams(window.location.search);
    const paramsUserID = params.get("theuserid");
    const paramsJGID = params.get("jg");
    // log.debug('start jg: '+firstRender.current);
    if(paramsJGID !== null && jgData !== undefined)
    {
        minDate=(jgData);
    }
    
    // end arrow icons for past campaigns
    
    if (dateRangePickerData.ranges !== undefined) {
        tempValues = Object.entries(dateRangePickerData.ranges)
        tempValues.map((key, index) => {
            if (index === 1) {
                tempArr[`${t('myjobs_datepicker_past_campaigns_text')}`] = ['', ''];
            }

            tempArr[`${key[0]}`] = [moment(String(key[1]).split("-")[0].trim(), 'YYYY/MM/DD').toDate(), moment(String(key[1]).split("-")[1].trim(), 'YYYY/MM/DD').toDate()];
            return '';

        });

    }
    
    // const pastCampaignToggleValue = useRef(2); 
    const isExpandedRef = useRef(false);
    const isApplyOnClickAttched = useRef(false);

    useEffect(() => {
        
        const timer = setTimeout(() => {
            keyRef.current = Date.now();
            setRanges(tempArr)
            setState({ start: moment(dateRangePickerData.initialStartDate), end: moment(dateRangePickerData.initialEndDate) });
            label.current = `${moment(dateRangePickerData.initialStartDate).format('DD.MM.YYYY')} - ${moment(dateRangePickerData.initialEndDate).format('DD.MM.YYYY')}`;
        }, 500);

        function customDateRangeSelected(e) {
            $(this).closest('.ranges ul').find('li').removeClass('active');
            $(this).addClass('active');
        }

        function onClickHandler(e) {

            if (!isExpandedRef.current) {
                $(this).prev('li').removeClass('active');
                $(this).addClass('modified');
                $(this).addClass('active');
                $(this).closest('.ranges ul').find('[data-range-key]:not([data-range-key="Current Campaign"]):not([data-range-key="Past campaigns"]):not([data-range-key="All Campaigns"]):not([data-range-key="Custom Date"]):not([data-range-key="Vergangene Kampagnen"]):not([data-range-key="Letzte Kampagne"]):not([data-range-key="Aktuelle Kampagne"]):not([data-range-key="Alle Kampagnen"]):not([data-range-key="Eigenes Datum"])').addClass('subrange_past').show();
                //setState({ start, end });
                // pastCampaignToggleValue.current = pastCampaignToggleValue.current +1;
                $(this).find('#upArrow').show();
                $(this).find('#downArrow').hide();
                log.debug("if condition");

                setTimeout(() => {
                    isExpandedRef.current = true;
                }, 200);
            }
            else
            {
                $(this).closest('.ranges ul').find('[data-range-key]:not([data-range-key="Current Campaign"]):not([data-range-key="Past campaigns"]):not([data-range-key="All Campaigns"]):not([data-range-key="Custom Date"]):not([data-range-key="Vergangene Kampagnen"]):not([data-range-key="Letzte Kampagne"]):not([data-range-key="Aktuelle Kampagne"]):not([data-range-key="Alle Kampagnen"]):not([data-range-key="Eigenes Datum"])').hide();
                $('li').removeClass('subrange_past')
                // pastCampaignToggleValue.current = pastCampaignToggleValue.current +1;
                $(this).find('#upArrow').hide();
                $(this).find('#downArrow').show();
                $(this).prev('li').removeClass('active');
                $(this).addClass('active');
                log.debug("else condition")
                setTimeout(() => {
                    isExpandedRef.current = false;
                }, 200);

            }
            e.preventDefault()
            e.stopPropagation()
            return false;
        }
        const elem = `#${randoNum} [data-range-key="${t('myjobs_datepicker_past_campaigns_text')}"]`;
        const customDateRangeElem = `#${randoNum} .ranges ul li[data-range-key="Custom Date"], #${randoNum} .ranges ul li[data-range-key="Eigenes Datum"]`;
        $(document).on("click", elem, onClickHandler)
        $(document).on("click", customDateRangeElem, customDateRangeSelected)
        return () => { clearTimeout(timer); $(document).off("click") };
        // eslint-disable-next-line
    }, [tempArr, dateRangePickerData]);

    
       
    const onApplyDateRangePicker = (event, picker) => {
       
        let theuserid = UserSession.UserID;
        let jobGroupID="";
        if(paramsUserID != null){
        theuserid = paramsUserID;
        }
        if(paramsJGID != null){
            jobGroupID = paramsJGID;
        }
        if((picker.chosenLabel === "Custom Date" || picker.chosenLabel === "Eigenes Datum") && pastCampaignsClicked.current === 0)
        {
            log.debug("Current Date")
            // showPicker=1;
            if (clickBlock !== undefined) {
                let postDataClicks;
                if(isJobGroupLevel !== undefined && isJobGroupLevel)
                {
                    postDataClicks = { clicksAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), old_from_date: dateRangePickerData.startDate, old_to_date: dateRangePickerData.endDate, isChartZoomed: false, sessionData: UserSession, jobGroupID : jobGroupID };
                }
                else
                {
                    postDataClicks = { clicksAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), old_from_date: dateRangePickerData.startDate, old_to_date: dateRangePickerData.endDate, isChartZoomed: false, sessionData: UserSession };
                }
                
                log.debug('Date Picker Change',postDataClicks)
                let result = {
                    startDate: postDataClicks.from_date,
                    endDate: postDataClicks.to_date,
                    ranges: dateRangePickerData.ranges,
                    maxDate: dateRangePickerData.maxDate,
                 }

                //Get Clicks Block Data            
                clicksBlockChangeHandler(result, postDataClicks)

            }
             
            if (conversionBlock !== undefined) {
                let postDataConversionBlock;
                if(isJobGroupLevel)
                {
                    postDataConversionBlock = { conversionBlockAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), sessionData: UserSession, jobGroupID : jobGroupID }
                }
                else
                {
                    postDataConversionBlock = { conversionBlockAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), sessionData: UserSession }
                }
                // log.debug('Date Picker Change',postDataConversionBlock)
                let result = {
                    startDate: postDataConversionBlock.from_date,
                    endDate: postDataConversionBlock.to_date,
                    ranges: dateRangePickerData.ranges,
                    maxDate: dateRangePickerData.maxDate,
                }
                // Get Clicks Block Data            
                conversionBlockChangeHandler(result, postDataConversionBlock)
            }
            // pastCampaignsClicked.current=0;
            setTimeout(() => {
                isApplyOnClickAttched.current = false;
            }, 200);
        }
    
        showPicker=0;
        if (!isApplyOnClickAttched.current){
        $(`#${randoNum} .applyBtn`).on('click', function () {
            label.current = `${picker.startDate.format('DD.MM.YYYY')} - ${picker.endDate.format('DD.MM.YYYY')}`;
            showPicker=1;
            if (clickBlock !== undefined) {
                let postDataClicks;
                if(isJobGroupLevel)
                {
                    postDataClicks = { clicksAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), old_from_date: dateRangePickerData.startDate, old_to_date: dateRangePickerData.endDate, isChartZoomed: false, sessionData: UserSession, jobGroupID : jobGroupID };
                }
                else
                {
                    postDataClicks = { clicksAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), old_from_date: dateRangePickerData.startDate, old_to_date: dateRangePickerData.endDate, isChartZoomed: false, sessionData: UserSession };
                }
                    // log.debug('Date Picker Change',postDataClicks)
                    let result = {
                        startDate: postDataClicks.from_date,
                        endDate: postDataClicks.to_date,
                        ranges: dateRangePickerData.ranges,
                        maxDate: dateRangePickerData.maxDate,
                    }

                //Get Clicks Block Data            
                clicksBlockChangeHandler(result, postDataClicks)

            }
            if (renderedFrom === "portfolio-detailed") {
                //    alert(1);
                let postDataClicks;
                if(isJobGroupLevel)
                {
                    postDataClicks = { clicksAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), old_from_date: dateRangePickerData.startDate, old_to_date: dateRangePickerData.endDate, isChartZoomed: false, sessionData: UserSession, jobGroupID : jobGroupID };
                }
                else
                {
                    postDataClicks = { clicksAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), old_from_date: dateRangePickerData.startDate, old_to_date: dateRangePickerData.endDate, isChartZoomed: false, sessionData: UserSession };
                }
                // log.debug('Date Picker Change',postDataClicks)
                let result = {
                    startDate: postDataClicks.from_date,
                    endDate: postDataClicks.to_date,
                    ranges: dateRangePickerData.ranges,
                    maxDate: dateRangePickerData.maxDate,
                }

                // Get Clicks Block Data            
                clicksBlockChangeHandler(result, postDataClicks)


                let postDataProtFolioTable = { portfolioTableAPI: 1, userID: theuserid, from_date: start.format('YYYY-MM-DD'), to_date: end.format('YYYY-MM-DD'), old_from_date: start.format('YYYY-MM-DD'), old_to_date: end.format('YYYY-MM-DD'), isChartZoomed: false, sessionData: UserSession};
                
                portfolioTableHandler(portfolioTableData, postDataProtFolioTable)
            }
            if (conversionBlock !== undefined) {
                let postDataConversionBlock;
                if(isJobGroupLevel)
                {
                    postDataConversionBlock = { conversionBlockAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), sessionData: UserSession, jobGroupID : jobGroupID }
                }
                else
                {
                    postDataConversionBlock = { conversionBlockAPI: 1, userID: theuserid, from_date: picker.startDate.format('DD.MM.YYYY'), to_date: picker.endDate.format('DD.MM.YYYY'), sessionData: UserSession }
                }
                // log.debug('Date Picker Change',postDataConversionBlock)
                let result = {
                    startDate: postDataConversionBlock.from_date,
                    endDate: postDataConversionBlock.to_date,
                    ranges: dateRangePickerData.ranges,
                    maxDate: dateRangePickerData.maxDate,
                }
                // Get Clicks Block Data            
                conversionBlockChangeHandler(result, postDataConversionBlock)
            }
            setTimeout(() => {
                isApplyOnClickAttched.current = false;
            }, 200);
        });
        isApplyOnClickAttched.current = true;
        }

        setState({ start: picker.startDate, end: picker.endDate });
    };

    const onShowDateRangePicker = (e, picker) => {
        // setTimeout(() => {
        //     isExpandedRef.current = false;
        // }, 100);
        setShowTooltip(false)
        if(paramsJGID !== null)
        {
            let datePart=minDate.split(".");
            let dateMin=new Date(+datePart[2], datePart[1] - 1, +datePart[0]);
            let datePart2= UserSession.DashboardLanguage === 'EN' ? 
                                                                    (dateRangePickerData.ranges['Current Campaign'] === undefined ? (dateRangePickerData.ranges['Last Campaign'].slice(0,10)).split("/"): (dateRangePickerData.ranges['Current Campaign'].slice(0,10)).split("/")) : 
                                                                    (dateRangePickerData.ranges['Aktuelle Kampagne'] === undefined ? (dateRangePickerData.ranges['Letzte Kampagne'].slice(0,10)).split("/"): (dateRangePickerData.ranges['Aktuelle Kampagne'].slice(0,10)).split("/"));
            let dateCurrCampaign=new Date(+datePart2[0], datePart2[1] - 1, +datePart2[2]);
            if((dateMin > dateCurrCampaign) || (dateMin.getTime() === dateCurrCampaign.getTime()))
            {
                $(`#${randoNum} li[data-range-key='Past campaigns']`).css({'pointer-events':'none','background-color':'#F3F3F3'});
                $(`#${randoNum} li[data-range-key='Vergangene Kampagnen']`).css({'pointer-events':'none','background-color':'#F3F3F3'});
                $(`#${randoNum} li[data-range-key='Alle Kampagnen']`).css({'pointer-events':'none','background-color':'#F3F3F3'});
                $(`#${randoNum} li[data-range-key='All Campaigns']`).css({'pointer-events':'none','background-color':'#F3F3F3'});
            }

        }
        if (picker.chosenLabel !== "Current Campaign" && picker.chosenLabel !== "All Campaigns" && picker.chosenLabel !== "Past campaigns" && picker.chosenLabel !== "Custom Date" && picker.chosenLabel !== "Vergangene Kampagnen" && picker.chosenLabel !== "Aktuelle Kampagne" && picker.chosenLabel !== "Letzte Kampagne" && picker.chosenLabel !== "Eigenes Datum" && picker.chosenLabel !== "Alle Kampagnen") {
            $(`#${randoNum} .ranges li`).show();
            $(`#${randoNum}  #upArrow`).show();
            $(`#${randoNum}  #downArrow`).hide();    

        }
        else
        {
            // $(`#${randoNum} .subrange_past`).hide(); 
            $(`#${randoNum} .ranges ul`).find('[data-range-key]:not([data-range-key="Current Campaign"]):not([data-range-key="Past campaigns"]):not([data-range-key="All Campaigns"]):not([data-range-key="Custom Date"]):not([data-range-key="Vergangene Kampagnen"]):not([data-range-key="Letzte Kampagne"]):not([data-range-key="Aktuelle Kampagne"]):not([data-range-key="Alle Kampagnen"]):not([data-range-key="Eigenes Datum"])').hide();
            $(`#${randoNum} #downArrow`).show();
            $(`#${randoNum} #upArrow`).hide();
        } 
        
    };

    const onHideDateRangePicker = (e,picker) => {
        setTimeout(() => {
            isExpandedRef.current = false;
        }, 100);

        if(picker.chosenLabel === t('myjobs_datepicker_past_campaigns_text'))
        {
            pastCampaignsClicked.current=pastCampaignsClicked.current+1;
        }
        else{
            pastCampaignsClicked.current=0;
        }
    }

    const handleCallback = (start, end) => {
        setState({ start, end });
    };

    const handleEvent = (event,picker) =>{
        if(event.type === "hide" && $(`#${randoNum} li`).hasClass("subrange_past"))
        {
            $(`#${randoNum} .subrange_past`).hide();
            $(`#${randoNum} li`).removeClass("subrange_past");
            //pastCampaignToggleValue.current=pastCampaignToggleValue.current+1;
        }
    
        if(initialRender)
        {
            if($(`#${randoNum} #downArrow`).length === 0)
            {
                $("li[data-range-key='Past campaigns']").append(downIcon);
                $("li[data-range-key='Vergangene Kampagnen']").append(downIcon);
            }
            if($(`#${randoNum} #upArrow`).length === 0)
            {
                $("li[data-range-key='Past campaigns']").append(upIcon);
                $("li[data-range-key='Vergangene Kampagnen']").append(upIcon);
            }
            // initialRender=false;
            // setInitialRender(false);
        }

        
        if (picker.chosenLabel === t('myjobs_datepicker_past_campaigns_text')) {
            picker.setStartDate(start);
            picker.setEndDate(end);
       
        }
        if(event.type === "apply" && showPicker === 0 && picker.chosenLabel !== "Custom Date")
        {
            picker.show();
        }
        else if(event.type === "apply" && showPicker === 1)
        {
            picker.hide();
        }
    }
    if(paramsJGID !== null){
        log.debug('end date  : '+drpmaxDate);
        if(dateRangePickerData.ranges['Last Campaign'] !== undefined || dateRangePickerData.ranges['Letzte Kampagne'] !== undefined)
        {
            drpmaxDate=dateRangePickerData.initialEndDate;
        }
    }
    const template = `<div class="daterangepicker" style="color: #1C2D3B;" id="${randoNum}">
    <div class="ranges"></div>
    <div class="drp-calendar left">
        <div class="calendar-table"></div>
        <div class="calendar-time"></div>
    </div>
    <div class="drp-calendar right">
        <div class="calendar-table"></div>
        <div class="calendar-time"></div>
    </div>
    <div class="drp-buttons">
        <span class="drp-selected"></span>
        <button class="cancelBtn" type="button"></button>
        <button class="applyBtn" disabled="disabled" type="button"></button>
    </div>
    </div>`;
    return (
        <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            show={showTooltip}
            overlay={(props) => (
                <Tooltip {...props} className="tooltip-action">
                    <div className='text-justify'>{t('tooltip_action_datepicker')} </div>
                </Tooltip>
            )}
            placement="top"
            html="true"
        >
            <div>
                <DateRangePicker
                        key={keyRef.current}
                        onApply={onApplyDateRangePicker}
                        onShow={onShowDateRangePicker}
                        onHide={onHideDateRangePicker}
                        onCallback={handleCallback}
                        onEvent={handleEvent}
                        initialSettings={{
                            ranges,
                            template,
                            autoApply : false,
                            class: 'test',
                            format: 'YYYY-MM-DD',
                            linkedCalendars: true,
                            alwaysShowCalendars: true,
                            opens: 'left',
                            startDate: start,
                            endDate: end,
                            locale: {
                                format: 'DD.MM.YYYY',
                                applyLabel: 'OK',
                                cancelLabel: t('mj_daterange_cancel_label'),
                                fromLabel: t('mj_daterange_from_label'),
                                toLabel: t('mj_daterange_to_label'),
                                customRangeLabel: t('myjobs_custom_date_text'),
                                firstDay: 1,
                                monthNames: locale_short === "de" ? moment.months() : moment.monthsShort(),
                            },
                            applyClass: 'btn-primary',
                            minDate: minDate ?? new Date(0),
                            maxDate: drpmaxDate
                        }}
                    >
                        {/* <input type="button" className="form-control daterange" /> */}
                        <div 
                            className="daterange"
                            style={{ cursor: 'pointer', height: 'auto' }}
                            onMouseOver={()=>setShowTooltip(true)} 
                            onMouseLeave={()=>setShowTooltip(false)}
                        >
                            <IconCalendar style={{marginRight : '6px',paddingTop: '2px',overflow:'visible'}}/>&nbsp;
                            <span>{label.current}</span>
                        </div>
                    </DateRangePicker>

            </div>
        </OverlayTrigger>
    );
};


/**
 * @module WMSSearchField
 * @module WMSProgressBar
 * @module WMSJobspreaderAI
 * @module WMSTextBox
 * @description
 * This set of component was re-written for use directly as component
 * with all the styles - active, hover, etc
 *
 * See confluence page for documentation and usage examples.
 * Documentation: https://wollmilchsau.atlassian.net/wiki/x/E4CD8
 *
 * @author Jayantajit Gogoi
 * @contributor Chitransh Bhalla
 *
 * Changelog details:
 * - 2024-05-06: Added component
 * - 2024-05-09: Added progress bar component
 * - 2024-06-05: Added JobspreaderAI tag component
 * - 2024-06-05: Added TextBox component
 * - 2024-07-03: Added SectionTabs component
 * - 2024-07-03: Added Slider component
 * - 2024-07-03: Added Accordion component
 * - 2024-08-03: Added Instant Search Box component
 * - 2024-08-23: Added CD Adv. Search Box component
 *
 */
import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/wms_figma-library.scss";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as JobGroupIcon } from "../../../assets/icons/JobgroupSearchIcon.svg";
import { ReactComponent as JobGroupIconGreen } from "../../../assets/icons/JobgroupSearchIconGreen.svg";
import { Session } from "bc-react-session";


/**
 * A search field component.
 *
 * Usage:
 * ```jsx
 * import { WMSSearchField } from "../common/figma-library";
 *
 * <WMSSearchField
 *    variant="small"
 *    handleSearch={(text) => {
 *      log.warn("search", text);
 *    }}
 * />
 * ```
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.variant="large"] - The variant of the search field. Can be "large" or "small".
 * @param {string} [props.placeholder="md_search_text"] - The placeholder text for the search field.
 * @param {function} props.onChange - The event handler for the onChange event of the search field.
 * @param {string} props.name - The name attribute of the search field.
 * @param {string} props.value - The value of the search field.
 * @param {function} props.handleSearch - The event handler for the search action.
 * @param {Object} props.style - The style object for the search field.
 * @returns {JSX.Element} The search field component.
 */
export const WMSSearchField = memo(
  forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { variant = "large", placeholder = t("md_search_text"), onChange, name, value, handleSearch, style } = props;
    const inputRef = useRef(null);
    const _prevInputTextRef = useRef(value || "");
    const [inputText, setInputText] = useState(value || "");

    if (variant !== "large" && variant !== "small") {
      throw new Error("Variant must be either 'large' or 'small'");
    }

    const selectedVariant = variant === "small" ? "wms-search-field-small" : "";

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        _prevInputTextRef.current = e.target.value;
        if (handleSearch.length > 0) {
          handleSearch(e.target.value);
        } else {
          handleSearch();
        }
      }
    };

    const handleOnBlur = () => {
      if (_prevInputTextRef.current !== inputText) {
        setInputText(_prevInputTextRef.current);
      }
    };

    const handleClearInputBox = () => {
      setInputText("");
      handleSearch?.("");
      _prevInputTextRef.current = "";
    };

    useImperativeHandle(ref, () => ({
      getInputText: () => {
        return inputText;
      },
      clearInputBox: () => {
        handleClearInputBox();
      },
    }));

    return (
      <div className={`wms-search-field ${selectedVariant}`} onClick={() => inputRef.current.focus()} style={style}>
        <span>
          {variant === "small" ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M10.0103 8.80592H9.37863L9.15475 8.59016C10.0315 7.56924 10.4784 6.24852 10.4016 4.9053C10.3248 3.56208 9.7303 2.30084 8.74288 1.38637C7.75546 0.471907 6.45195 -0.0246544 5.10602 0.000942646C3.76008 0.0265397 2.47641 0.572305 1.52452 1.52365C0.572631 2.475 0.0265548 3.75794 0.000943184 5.1031C-0.0246685 6.44827 0.472177 7.75104 1.38717 8.73789C2.30215 9.72474 3.56412 10.3189 4.9081 10.3957C6.25209 10.4724 7.57356 10.0258 8.59507 9.14953L8.81095 9.37327V10.0046L12.8087 14L14 12.8014L10.0103 8.80592ZM5.21299 8.80592C4.50138 8.80592 3.80576 8.59502 3.21407 8.1999C2.62239 7.80478 2.16123 7.24317 1.88891 6.58611C1.61659 5.92904 1.54534 5.20603 1.68417 4.50849C1.823 3.81095 2.16567 3.17023 2.66885 2.66733C3.17204 2.16443 3.81313 1.82196 4.51107 1.68321C5.209 1.54446 5.93243 1.61567 6.58987 1.88784C7.24731 2.16 7.80923 2.6209 8.20458 3.21224C8.59993 3.80358 8.81095 4.49881 8.81095 5.21002C8.812 5.68253 8.71966 6.1506 8.53922 6.58735C8.35878 7.0241 8.09379 7.42093 7.75948 7.75505C7.42517 8.08917 7.02812 8.354 6.59112 8.53434C6.15412 8.71468 5.68578 8.80697 5.21299 8.80592Z"
                fill="#898989"
              />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M11.4403 10.0639H10.7184L10.4626 9.81733C11.4646 8.65056 11.9753 7.14117 11.8876 5.60606C11.7998 4.07095 11.1203 2.62953 9.99186 1.58443C8.86339 0.539323 7.37366 -0.0281764 5.83545 0.00107731C4.29724 0.0303311 2.83019 0.654062 1.74231 1.74132C0.654436 2.82857 0.0303484 4.29478 0.00107792 5.83212C-0.0281925 7.36945 0.539631 8.85833 1.58533 9.98616C2.63103 11.114 4.07328 11.7931 5.60926 11.8808C7.14524 11.9685 8.6555 11.458 9.82294 10.4566L10.0697 10.7123V11.4338L14.6385 16L16 14.6301L11.4403 10.0639ZM5.9577 10.0639C5.14444 10.0639 4.34943 9.82288 3.67323 9.37131C2.99702 8.91975 2.46998 8.27791 2.15876 7.52698C1.84753 6.77605 1.7661 5.94975 1.92476 5.15256C2.08343 4.35538 2.47505 3.62311 3.05012 3.04838C3.62518 2.47364 4.35786 2.08224 5.1555 1.92367C5.95314 1.7651 6.77992 1.84648 7.53128 2.15753C8.28264 2.46857 8.92484 2.99531 9.37667 3.67113C9.82849 4.34695 10.0697 5.1415 10.0697 5.9543C10.0709 6.49432 9.96532 7.02926 9.7591 7.5284C9.55289 8.02755 9.25005 8.48107 8.86798 8.86292C8.48591 9.24477 8.03213 9.54743 7.5327 9.75353C7.03328 9.95963 6.49803 10.0651 5.9577 10.0639Z"
                fill="#898989"
              />
            </svg>
          )}
        </span>
        <input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          onChange={(e) => {
            setInputText(e.target.value);
            onChange?.(e);
          }}
          name={name}
          value={inputText}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
        />
        {inputText.length > 0 && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClearInputBox();
            }}
          >
            <i className="fa fa-times-circle" />
          </span>
        )}
      </div>
    );
  })
);

/**
 * Renders a progress bar component.
 *
 * Heavily modified for JQX only
 *
 * @param {Object} props - The component props.
 * @param {Object} props.style - The inline style for the progress bar track.
 * @param {number} [props.progress=0] - The progress value (between 0 and 100).
 * @param {string} [props.module="jqx"] - The module name for the progress bar.
 * @param {boolean} [props.showText=false] - Indicates if the progress text (number and %) should be shown.
 * @param {boolean} [props.showJQX=true] - Indicates if the JQX text should be shown. > dont show JQX
 * @param {boolean} [props.loading=false] - Indicates if the progress bar is in loading state. > moving stripes
 * @param {boolean} [props.noData=false] - Indicates if the progress bar is in no data state. > full gray
 * @param {string} [props.className=""] - The class name for the progress bar.
 * @param {boolean} [props.isPredicted=false] - JQX only, indicates if the JQX Score is predicted.
 *
 * @throws {Error} If the progress value is not a number. << removed as highly vulnerable
 * @returns {JSX.Element} The rendered progress bar component.
 */
export const WMSProgressBar = memo(
  forwardRef((props, ref) => {
    const {
      style,
      progress = 0,
      module = "jqx",
      showText = false,
      showJQX = true,
      loading = false,
      noData = false,
      className = "",
      isPredicted = false,
    } = props;
    const { t } = useTranslation();

    // if (isNaN(progress)) {
    //   throw new Error("Progress must be a number");
    // }

    const global_colours = [
      {
        module: "jqx",
        colours: {
          0: "#dd5050",
          31: "#fec570",
          61: "#3c83bc",
          91: "#3ac693",
        },
        predictedColours: {
          0: "#eea8a8",
          31: "#fee2b8",
          61: "#9dc1dd",
          91: "#9ce2c9",
        },
      },
    ];

    if (!global_colours.find((item) => item.module === module)) {
      throw new Error("Module not found");
    }

    const localProgress = isNaN(progress) ? 100 : progress;
    const showPrediction = isPredicted && !noData && !loading;

    const colours = global_colours.find((item) => item.module === module)?.colours;
    const predictedColours = showPrediction
      ? global_colours.find((item) => item.module === module)?.predictedColours
      : {};

    const barColor = Object.keys(colours).reduce((acc, curr) => (localProgress >= curr ? colours[curr] : acc), "");
    const predictedBarColor = showPrediction
      ? Object.keys(predictedColours).reduce((acc, curr) => (localProgress >= curr ? predictedColours[curr] : acc), "")
      : "";

    const backgroundStyles = showPrediction
      ? {
        backgroundImage: `repeating-linear-gradient(-45deg, ${barColor} 0px, ${barColor} 3px, ${predictedBarColor} 3px, ${predictedBarColor} 6px)`,
      }
      : { backgroundColor: barColor };

    return (
      <div className={`wms-progress-bar ${className}`}>
        <div className="wms-progress-bar-track" style={style} ref={ref}>
          <div
            className={`wms-progress-bar-thumb ${loading ? "loading-bar" : ""} ${noData ? "no-data-bar" : ""}`}
            style={{
              width: `${loading ? 100 : localProgress}%`,
              ...backgroundStyles,
            }}
          />
        </div>
        {showText && (
          <p className="wms-progress-bar-text">
            {`${showPrediction ? "~" : ""} ${loading || noData ? "- " : localProgress}% ${showJQX ? t("ai_title_jqx") : ""}`}
          </p>
        )}
      </div>
    );
  })
);

/**
 * Renders the JobspreaderAI Tag.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className=""] - The class name for the component.
 * @param {Object} [props.style={}] - The inline styles for the component.
 * @param {Function} [props.onClick=null] - The click event handler for the component.
 * @param {React.Ref} ref - The ref object for the component.
 * @returns {JSX.Element} The rendered JobspreaderAI tag.
 */
export const WMSJobspreaderAI = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { className = "", style = {}, onClick = null } = props;

  return (
    <div ref={ref} className={`wms-jobspreader-ai-tag ${className}`} style={style} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
          d="M6.12502 12.25C5.98699 12.25 5.86535 12.1594 5.82569 12.027L4.53148 7.71846L0.222552 6.42406C0.090385 6.38463 0 6.26305 0 6.12502C0 5.987 0.090385 5.86537 0.222552 5.82594L4.53148 4.53203L5.82569 0.223025C5.86535 0.0906238 5.98699 0 6.12502 0C6.26306 0 6.38465 0.0906238 6.42431 0.223025L7.71852 4.53203L12.0274 5.82594C12.1596 5.86537 12.25 5.987 12.25 6.12502C12.25 6.26305 12.1596 6.38463 12.0274 6.42406L7.71852 7.71846L6.42431 12.027C6.38465 12.1594 6.26306 12.25 6.12502 12.25Z"
          fill="white"
        />
        <path
          d="M11.3749 14C11.2623 14 11.1632 13.9261 11.1308 13.8181L10.6228 12.1269L8.93148 11.6189C8.8237 11.5867 8.75 11.4875 8.75 11.375C8.75 11.2625 8.8237 11.1633 8.93148 11.1311L10.6228 10.6231L11.1308 8.93185C11.1632 8.82389 11.2623 8.75 11.3749 8.75C11.4875 8.75 11.5866 8.82389 11.619 8.93185L12.1271 10.6231L13.8185 11.1311C13.9263 11.1633 14 11.2625 14 11.375C14 11.4875 13.9263 11.5867 13.8185 11.6189L12.1271 12.1269L11.619 13.8181C11.5866 13.9261 11.4875 14 11.3749 14Z"
          fill="white"
        />
      </svg>
      <p>{t("ai_title_jobspreader_ai")}</p>
    </div>
  );
});

/**
 * A text box component to match figma library.
 *
 * Usage:
 * ```jsx
 *
 * import { WMSTextBox } from "../common/figma-library";
 *
 * <WMSTextBox
 *   icon={<IconComponent />}
 *   endIcon={<IconComponent />}
 *   type="text | password"
 *   placeholder=""
 *   value=""
 *   setValue={setValueFunction}
 *   style={customStyle}
 * />
 * ```
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.icon - The icon component to display before the input field.
 * @param {ReactNode} props.endIcon - The icon component to display after the input field.
 * @param {string} [props.type="text"] - The type of the input field.
 * @param {string} [props.placeholder="input_text"] - The placeholder text for the input field.
 * @param {string} [props.value=""] - The value of the input field.
 * @param {function} [props.setValue=null] - The function to call when the input value changes.
 * @param {Object} [props.style={}] - The custom styles to apply to the component.
 * @returns {JSX.Element} The rendered WMSTextBox component.
 */
export const WMSTextBox = forwardRef((props, ref) => {
  const {
    icon = null,
    endIcon = null,
    type = "text",
    placeholder = "input_text",
    value = "",
    setValue = null,
    onBlur = () => { },
    style = {},
    className = "",
  } = props;
  const [localValue, setLocalValue] = useState(value);

  const _inputFieldRef = useRef(null);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setLocalValue("");
    },
  }));

  const handleFocus = () => {
    _inputFieldRef.current.focus();
  };

  const updateValue = (e) => {
    setLocalValue(e.target.value);
    setValue?.(e.target.value);
    // To handle the case of when paste only
    if ((localValue.length ?? 0) === 0 && e.target.value.length > 0) onBlur();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onBlur();
    }
  };

  return (
    <div className={`wms-text-box ${className}`} onClick={handleFocus} style={style}>
      {icon && icon}
      <input
        type={type}
        placeholder={placeholder}
        ref={_inputFieldRef}
        value={localValue}
        onChange={updateValue}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
      />
      {endIcon && endIcon}
    </div>
  );
});

/**
 * Renders a section with tabs.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.activeIndex - The index of the active tab.
 * @param {Array} props.buttons - The array of tab buttons.
 * @param {string} [props.size="large"] - The size of the tab buttons (either "large" or "small").
 * @returns {JSX.Element} The rendered section with tabs.
 */
export const WMSSectionTabs = forwardRef((props, ref) => {
  const { activeIndex = 0, buttons, size = "large" } = props;

  const selectedSize = size === "small" ? "btn-small" : "";
  return (
    <div className="wms-section-tabs" ref={ref}>
      {buttons.map((button, index) => {
        const { disabled = false, label, onClick = null } = button;
        return (
          <button
            type="button"
            key={index}
            className={`wms-tabs-btn ${selectedSize} ${activeIndex === index ? "active" : ""}`}
            onClick={onClick}
            disabled={disabled}
          >
            <span>{label}</span>
          </button>
        );
      })}
    </div>
  );
});

/**
 * A custom slider component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} [props.value=50] - The initial value of the slider.
 * @param {function} [props.setValue] - A callback function to update the value of the slider.
 * @param {Object} [props.style] - Additional styles to apply to the slider container.
 * @returns {JSX.Element} The slider component.
 */
export const WMSSlider = forwardRef((props, ref) => {
  const { value = 50, setValue, style = {}, min = 1, max = 100, step = 1 } = props;
  const [localValue, setLocalValue] = useState(value);
  const percentage = ((1 + ((localValue - min) * (100 - 1)) / (max - min)) / 100) * 100;

  const handleRangeChange = (e) => {
    if (typeof setValue !== "function") setLocalValue(e.target.value);
    setValue?.(e.target.value);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className="wms-slider" ref={ref} style={style}>
      <input
        style={{
          background: `linear-gradient(to right, #B0B4FF 0%, #B0B4FF ${percentage}%, #DCDCDC ${percentage}%, #DCDCDC 100%)`,
        }}
        type="range"
        min={min}
        max={max}
        step={step}
        value={localValue}
        onChange={handleRangeChange}
      />
    </div>
  );
});

export const WMSAccordion = forwardRef((props, ref) => {
  const { children, defaultOpen = false, className = "", title = "", style = {} } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const mainDivRef = useRef(null);

  const _animDOMRef = useRef(null);

  if (title === "") {
    throw new Error("Title is required");
  }

  useImperativeHandle(ref, () => ({
    main: mainDivRef.current,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen(!isOpen),
  }));

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`wms-accordion ${className}`} style={style} ref={mainDivRef}>
      <div className={`wms-accordion-header ${isOpen ? "wms-acc-open" : ""}`} onClick={handleToggle}>
        <p className="wms-accordion-header-title">{title}</p>
        <button type="button" className="figma-btn fg-secondary fg-small fg-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14" fill="none">
            <path d="M5.2 9.8L1 6.125L9.4 6.125L5.2 9.8Z" fill="#3C83BC" />
          </svg>
        </button>
      </div>
      <CSSTransition in={isOpen} timeout={500} classNames="wms-accordion-content" unmountOnExit nodeRef={_animDOMRef}>
        <div className="wms-accordion-content" ref={_animDOMRef}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
});

/**
 * A creatable text box for sourcing search builder.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.error=false] - Indicates if there is an error.
 * @param {string} [props.headingText=""] - The heading text.
 * @param {ReactNode} [props.startIcon=null] - The start icon for each tag.
 * @param {string} [props.tagsStyle="outlined"] - The style of the tags ("outlined" or "filled").
 * @param {string} [props.tagsColour="primary"] - The color of the tags ("primary" or "warn").
 * @param {boolean} [props.fullClick=false] - Indicates if the tag should be clickable.
 * @param {Array<string>} [props.inputArray=[]] - The array of existing tags.
 * @param {Array<string>} [props.restrictArray=[]] - The array of restricted tags.
 * @param {Object} [props.style={}] - The inline style for the component.
 * @param {Function} [props.handleTagClick=()=>{}] - The callback function when an existing tag is clicked.
 * @param {boolean} [props.showNewAdd=true] - Indicates if the "Add" button should be shown.
 * @param {Function} [props.handleNewAdd=()=>{}] - The callback function when a new tag is added.
 * @param {Object} [props.customAddIcon={}] - The custom add icon configuration.
 * @param {ReactNode} [props.customAddIcon.icon=null] - The custom add icon.
 * @param {string} [props.customAddIcon.text="Add"] - The text for the custom add icon.
 * @param {string} [props.customAddIcon.className=""] - The class name for the custom add icon.
 * @param {Function} [props.customAddIcon.onClick=()=>{}] - The callback function when the custom add icon is clicked.
 * @param {boolean} [props.customAddIcon.fullClick=false] - Indicates if the custom add icon should be clickable.
 * @returns {JSX.Element} The rendered creatable text box component.
 */
export const WMSCreatableTextBox = forwardRef((props, ref) => {
  const {
    error = false,
    headingText = "",
    startIcon = null,
    tagsStyle = "outlined",
    tagsColour = "primary",
    fullClick = false,
    inputArray = [],
    restrictArray = [],
    style = {},
    handleTagClick = () => { },
    showNewAdd = true,
    handleNewAdd = () => { },
    customAddIcon = {
      icon: null,
      text: "Add",
      className: "",
      onClick: () => { },
      fullClick: false,
    },
    loading = false,
    minLoadingTags = 3,
  } = props;

  const mainDivRef = useRef(null);
  const inputFieldRef = useRef(null);
  const inputValueRef = useRef("");

  const [inputActive, setInputActive] = useState(false);

  useImperativeHandle(ref, () => ({
    main: mainDivRef.current,
    input: inputFieldRef.current,
  }));

  const AddIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M12 6.85714H6.85714V12H5.14286V6.85714H0V5.14286H5.14286V0H6.85714V5.14286H12V6.85714Z" fill="white" />
    </svg>
  );

  const RemoveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z"
        fill="#3C83BC"
      />
    </svg>
  );

  const handleExistingTagClick = (item) => {
    handleTagClick(item);
  };

  /** handles the case of click on +, enabled the text field, hides the + */
  const localHandleNewAddClick = () => {
    setInputActive(true);
    setTimeout(() => {
      inputFieldRef.current.focus();
    }, 100);
  };

  /** input field focused and blurred */
  const handleInputBlur = () => {
    //added to be able to add on blur from input field
    handleInputNewAdd();

    setInputActive(false);
  };

  /** checks and calls the function passed as props */
  const handleInputNewAdd = () => {
    const trimmedValue = inputValueRef.current?.trim();
    if (trimmedValue !== "") {
      handleNewAdd(trimmedValue);
    }
    inputFieldRef.current.value = "";
    inputValueRef.current = "";
  };

  const crTagsClass = tagsStyle === "filled" ? "cr-tag-filled" : "cr-tag-outlined";
  const crTagsColour = tagsColour === "primary" ? "cr-tag-primary" : "cr-tag-warn";

  return (
    <div className={`wms-creatable-text-box ${error ? "cr-error" : ""}`} ref={mainDivRef} style={style}>
      {headingText && <p className="cr-heading">{headingText}</p>}
      <div className="cr-tags">
        {inputArray.map((item, index) => {
          const restrict = restrictArray.includes(item.toLowerCase());
          return (
            <span
              key={index}
              className={`cr-tag ${crTagsClass} ${crTagsColour} ${restrict ? "cr-restrict" : ""} ${fullClick ? "cr-tag-full-pointer" : ""}`}
              onClick={fullClick ? () => handleExistingTagClick(item) : null}
            >
              <span onClick={fullClick ? null : () => handleExistingTagClick(item)}>
                {startIcon && !restrict ? startIcon : <RemoveIcon />}
              </span>
              <p>{item}</p>
            </span>
          );
        })}
        {showNewAdd && !inputActive && (
          <span className="cr-add-tags" onClick={localHandleNewAddClick}>
            <AddIcon />
          </span>
        )}
        <input
          type="text"
          ref={inputFieldRef}
          onBlur={handleInputBlur}
          onChange={(e) => {
            inputValueRef.current = e.target.value;
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleInputNewAdd();
          }}
          style={{
            display: inputActive ? "" : "none",
            flexGrow: "1"
          }}
        />
        {customAddIcon.icon && (
          <span
            className={`cr-tag cr-tag-custom ${customAddIcon.className} ${customAddIcon.fullClick ? "cr-tag-full-pointer" : ""}`}
            onClick={customAddIcon.fullClick ? customAddIcon?.onClick : null}
          >
            <span onClick={customAddIcon.fullClick ? null : customAddIcon?.onClick}>{customAddIcon.icon}</span>
            <p>{customAddIcon.text}</p>
          </span>
        )}
        {loading &&
          Array.from({ length: minLoadingTags }, (_, i) => (
            <span key={i} className={`cr-tag cr-skeleton`}>
              <p>...</p>
            </span>
          ))}
      </div>
    </div>
  );
});

/**
 * WMSTooltip component.
 *
 * Usage
 * ```jsx
 * import { WMSTooltip } from "../common/figma-library";
 *
 * <WMSTooltip
 *    type="info"
 *    position="top"
 *    tooltipText="This is a tooltip"
 * >
 *    <button>Hover me</button>
 * </WMSTooltip>
 * ```
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be wrapped by the tooltip, hover on this triggers the tooltip show/hide.
 * @param {string} [props.type="info"] - Must be either 'info' or 'action'.
 * @param {string} [props.position="top"] - Must be either 'auto', 'top', 'bottom', 'left', 'right', or 'wms-auto'.
 * @param {Object} [props.delay={ show: 150, hide: 150 }] - The delay configuration for showing and hiding the tooltip.
 * @param {string} [props.tooltipText=""] - The text content of the tooltip.
 * @param {ReactNode} [props.tooltipComponent=null] - The custom component to be rendered as the tooltip.
 * @throws {Error} If the type is neither 'info' nor 'action'.
 * @throws {Error} If both tooltipText and tooltipComponent are provided.
 * @throws {Error} If the position is not one of the allowed values.
 * @throws {Error} If the position is 'auto' or 'wms-auto'. < this is temporary
 * @returns {ReactNode} The WMSTooltip component.
 */
export const WMSTooltip = (props) => {
  const {
    children,
    type = "info",
    position = "top",
    delay = { show: 150, hide: 150 },
    tooltipText = "",
    tooltipComponent = null,
    isEnabled = true,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const _showPendingTimerRef = useRef(null);
  const _hidePendingTimerRef = useRef(null);

  const _animDOMRef = useRef(null);

  if (!isEnabled) {
    return children;
  }

  if (type !== "info" && type !== "action") {
    throw new Error("Type must be either 'info' or 'action'");
  }

  if (tooltipText !== "" && tooltipComponent !== null) {
    throw new Error("Only one of tooltipText or tooltipComponent can be passed");
  }

  if (
    position !== "auto" &&
    position !== "top" &&
    position !== "bottom" &&
    position !== "left" &&
    position !== "right" &&
    position !== "wms-auto"
  ) {
    throw new Error("Position must be either 'auto', 'top', 'bottom', 'left', 'right' or 'wms-auto'");
  }

  if (position === "wms-auto" || position === "auto") {
    throw new Error("Position 'auto' is not yet supported by WMSTooltip");
  }

  /**
   * Handles the mouse enter event.
   */
  const handleMouseEnter = () => {
    if (_hidePendingTimerRef.current) {
      clearTimeout(_hidePendingTimerRef.current);
    }
    _showPendingTimerRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, delay.show);
  };

  /**
   * Handles the mouse leave event.
   */
  const handleMouseLeave = () => {
    if (_showPendingTimerRef.current) {
      clearTimeout(_showPendingTimerRef.current);
    }
    _hidePendingTimerRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, delay.hide);
  };

  const className = `wms-tooltip wms-tooltip-${type} wms-tooltip-${position}`;

  return (
    <React.Fragment>
      {React.cloneElement(children, {
        onPointerEnter: handleMouseEnter,
        onPointerLeave: handleMouseLeave,
        onPointerDown: () => {
          if (type === "action") {
            handleMouseLeave();
          }
        },
      })}
      <CSSTransition in={showTooltip} timeout={300} classNames="wms-tooltip-anim" unmountOnExit nodeRef={_animDOMRef}>
        <div className={className} ref={_animDOMRef}>
          {tooltipText !== "" ? <p className="tooltipText">{tooltipText}</p> : tooltipComponent}
        </div>
      </CSSTransition>
    </React.Fragment>
  );
};



/**
 * A creatable instant search box for automatic ticket creation settings.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.error=false] - Indicates if there is an error.
 * @param {string} [props.headingText=""] - The heading text.
 * @param {ReactNode} [props.startIcon=null] - The start icon for each tag.
 * @param {string} [props.tagsStyle="outlined"] - The style of the tags ("outlined" or "filled").
 * @param {string} [props.tagsColour="primary"] - The color of the tags ("primary" or "warn").
 * @param {boolean} [props.fullClick=false] - Indicates if the tag should be clickable.
 * @param {Array<string>} [props.inputArray=[]] - The array of existing tags.
 * @param {Array<string>} [props.restrictArray=[]] - The array of restricted tags.
 * @param {Object} [props.style={}] - The inline style for the component.
 * @param {Function} [props.handleTagClick=()=>{}] - The callback function when an existing tag is clicked.
 * @param {boolean} [props.showNewAdd=true] - Indicates if the "Add" button should be shown.
 * @param {Function} [props.handleNewAdd=()=>{}] - The callback function when a new tag is added.
 * @param {Object} [props.customAddIcon={}] - The custom add icon configuration.
 * @param {ReactNode} [props.customAddIcon.icon=null] - The custom add icon.
 * @param {string} [props.customAddIcon.text="Add"] - The text for the custom add icon.
 * @param {string} [props.customAddIcon.className=""] - The class name for the custom add icon.
 * @param {Function} [props.customAddIcon.onClick=()=>{}] - The callback function when the custom add icon is clicked.
 * @param {boolean} [props.customAddIcon.fullClick=false] - Indicates if the custom add icon should be clickable.
 * @param {Object} [props.options] - The search values.
 * @returns {JSX.Element} The rendered creatable text box component.
 */

export const WMSCreatableInstantSearchBox = forwardRef((props, ref) => {
  const {
    error = false,
    headingText = "",
    startIcon = null,
    tagsStyle = "outlined",
    tagsColour = "primary",
    fullClick = false,
    inputArray = [],
    restrictArray = [],
    style = {},
    handleTagClick = () => { },
    showNewAdd = true,
    handleNewAdd = () => { },
    customAddIcon = {
      icon: null,
      text: "Add",
      className: "",
      onClick: () => { },
      fullClick: false,
    },
    loading = false,
    minLoadingTags = 3,
    options = [],
    data_type = '',
    deletedJobGroupsOptions = [],
  } = props;
  const mainDivRef = useRef(null);
  const inputFieldRef = useRef(null);
  const inputValueRef = useRef("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputActive, setInputActive] = useState(false);
  const { t } = useTranslation();
  const [hasDeletedJobGroups, setHasDeletedJobGroups] = useState(false);


  useImperativeHandle(ref, () => ({
    main: mainDivRef.current,
    input: inputFieldRef.current,
  }));

  const AddIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M12 6.85714H6.85714V12H5.14286V6.85714H0V5.14286H5.14286V0H6.85714V5.14286H12V6.85714Z" fill="white" />
    </svg>
  );

  const RemoveIcon = ({ status }) => {

    const isDisabled = status === 'Disable';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z"
          fill={isDisabled ? "#C4C4C4" : "#3C83BC"}
        />
      </svg>
    );
  };
  
  const handleExistingTagClick = (item) => {
    handleTagClick(item); // Pass the value for tag click handling
  };


  const localHandleNewAddClick = () => {
    setInputActive(true);
    setFilteredOptions(options); 
    setTimeout(() => {
      inputFieldRef.current.focus();
    }, 100);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        mainDivRef.current &&
        !mainDivRef.current.contains(e.target) &&
        !e.target.closest('.search-tags')
      ) {
        inputFieldRef.current.value = "";

        setInputActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (data_type === 'jobgroup_icon') {
      const hasMatch = inputArray.some(item => {
        const matchedOption = options.find(option => option.value.toString() === item.value.toString());
        if (!matchedOption) {
          return deletedJobGroupsOptions.some(option => option.value.toString() === item.value.toString());
        }
        return false;
      });
      setHasDeletedJobGroups(hasMatch);
    } else {
      setHasDeletedJobGroups(false);
    }
  }, [inputArray, options, deletedJobGroupsOptions, data_type]);
  

  const handleInputBlur = (e) => {
    if (
      mainDivRef.current.contains(e.relatedTarget) || // Check if relatedTarget is inside the main div
      (e.relatedTarget && e.relatedTarget.closest('.search-tags')) // Check if relatedTarget is inside the search-tags
    ) {
      return; // Don't blur if the click was inside the search-tags div or the input field
    }
  };


  const handleInputNewAdd = (item) => {

    const trimmedValue = item.toString().trim();
    if (trimmedValue !== "") {
      handleNewAdd(item); // Pass the full item object
    }
    inputFieldRef.current.value = "";
    // setTimeout(() => {
    //   inputFieldRef.current.focus();
    // }, 100);
    setInputActive(false);
  };

  const handleInputSearch = (e) => {
    const trimmedValue = e.target.value.trim().toLowerCase();
    setFilteredOptions(
      options.filter(option => option.label.toLowerCase().includes(trimmedValue))
    );
  };
  

  const crTagsClass = tagsStyle === "filled" ? "cr-tag-filled" : "cr-tag-outlined";
  const crTagsColour = tagsColour === "primary" ? "cr-tag-primary" : "cr-tag-warn";

  return (
    <div className="relative-div">
      <div className={`wms-creatable-text-box ${error ? "cr-error" : ""}`} ref={mainDivRef}
        style={{
          ...style,
          borderBottomLeftRadius: inputActive ? "0px" : "12px",
          borderBottomRightRadius: inputActive ? "0px" : "12px",
        }}>
        {headingText && <p className="cr-heading">{headingText}</p>}

        <div className="cr-tags">
          {inputArray.map((item, index) => {
            const restrict = restrictArray.some(restricted => restricted.value === item.value);
            // Find the corresponding label from options based on the value
            const matchedOption = options.find(option => option.value.toString() === item.value.toString());

            let displayLabel;
            let matchedOption2;
            let removeIconStatus = null;
            if (data_type === 'jobgroup_icon') {
             matchedOption2 = !matchedOption
            ? deletedJobGroupsOptions.find(option => option.value.toString() === item.value.toString())
            : null;

             displayLabel = matchedOption
              ? matchedOption.label
              : matchedOption2
              ? matchedOption2.label
              : item.label;
              if (displayLabel === item.label) {
                return null;
              }
              if (matchedOption2) { removeIconStatus = 'Disable'; }
            }
            else{
               displayLabel = item.label;
            }

            return (
              <span
                key={index}
                className={`cr-tag ${crTagsClass} ${crTagsColour} ${restrict ? "cr-restrict" : ""} ${matchedOption2 ? "disabledButtonPartial" : ""} ${fullClick ? "cr-tag-full-pointer" : ""}`}
                onClick={fullClick ? () => handleExistingTagClick(item) : null}
              >
                <span onClick={fullClick ? null : () => handleExistingTagClick(item)}>
                  {startIcon && !restrict ? startIcon : <RemoveIcon status={removeIconStatus} />}
                </span>
                <p>{displayLabel}</p> 
              </span>
            );
          })}

          {showNewAdd && !inputActive && (
            <span className={"cr-add-tags "} onClick={localHandleNewAddClick}>
              <AddIcon />
            </span>
          )}
          <input
            type="text"
            ref={inputFieldRef}
            onBlur={handleInputBlur}
            onChange={handleInputSearch}
            style={{
              display: inputActive ? "" : "none",
              flexGrow: "1"
            }}
          />
          {customAddIcon.icon && (
            <span
              className={`cr-tag cr-tag-custom ${customAddIcon.className} ${customAddIcon.fullClick ? "cr-tag-full-pointer" : ""}`}
              onClick={customAddIcon.fullClick ? customAddIcon?.onClick : null}
            >
              <span onClick={customAddIcon.fullClick ? null : customAddIcon?.onClick}>{customAddIcon.icon}</span>
              <p>{customAddIcon.text}</p>
            </span>
          )}
          {loading &&
            Array.from({ length: minLoadingTags }, (_, i) => (
              <span key={i} className={`cr-tag cr-skeleton`}>
                <p>...</p>
              </span>
            ))}
        </div>
       
      </div>
     
      {inputActive && (
        <div className={`search-tags ${error ? "cr-error" : ""}`}>
          {filteredOptions.filter(item => !inputArray.some(input => input.value === item.value)).length > 0 ? (
            filteredOptions
              .filter(item => !inputArray.some(input => input.value === item.value)) 
              .map((item, index) => (
                <span key={index} onClick={() => handleInputNewAdd(item.value)}>
                <p>
              {item.label} 
              {data_type === 'jobgroup_icon' && (
                item.IsDefault === "Yes" ? 
                  <JobGroupIconGreen className="jobgrpup_icon_search_dropdown" /> 
                  : item.iconDisplay > 0 ? 
                  <JobGroupIcon className="jobgrpup_icon_search_dropdown" /> 
                  : ''
              )}
            </p></span>
              ))
          ) : (
            <span className="nohoverEf"><p>{t("atc_no_data_in_filter_location")}</p></span>
          )}

        </div>
      )}

      {hasDeletedJobGroups && data_type === 'jobgroup_icon' && (
        <div className="deleted-jobgroup-msg">
          {t("atc_deleted_jobgroup_msg")}
        </div>
      )}
    </div>
    
  );
});


/**
 * A creatable API search box for CD in automatic ticket creation settings .
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.error=false] - Indicates if there is an error.
 * @param {string} [props.headingText=""] - The heading text.
 * @param {ReactNode} [props.startIcon=null] - The start icon for each tag.
 * @param {string} [props.tagsStyle="outlined"] - The style of the tags ("outlined" or "filled").
 * @param {string} [props.tagsColour="primary"] - The color of the tags ("primary" or "warn").
 * @param {boolean} [props.fullClick=false] - Indicates if the tag should be clickable.
 * @param {Array<string>} [props.inputArray=[]] - The array of existing tags.
 * @param {Array<string>} [props.restrictArray=[]] - The array of restricted tags.
 * @param {Object} [props.style={}] - The inline style for the component.
 * @param {Function} [props.handleTagClick=()=>{}] - The callback function when an existing tag is clicked.
 * @param {boolean} [props.showNewAdd=true] - Indicates if the "Add" button should be shown.
 * @param {Function} [props.handleNewAdd=()=>{}] - The callback function when a new tag is added.
 * @param {Object} [props.customAddIcon={}] - The custom add icon configuration.
 * @param {ReactNode} [props.customAddIcon.icon=null] - The custom add icon.
 * @param {string} [props.customAddIcon.text="Add"] - The text for the custom add icon.
 * @param {string} [props.customAddIcon.className=""] - The class name for the custom add icon.
 * @param {Function} [props.customAddIcon.onClick=()=>{}] - The callback function when the custom add icon is clicked.
 * @param {boolean} [props.customAddIcon.fullClick=false] - Indicates if the custom add icon should be clickable.
 * @param {Object} [props.options] - The search values.
 * @returns {JSX.Element} The rendered creatable text box component.
 */
export const WMSCreatableSearchBoxCD = forwardRef((props, ref) => {
  const {
    error = false,
    headingText = "",
    startIcon = null,
    tagsStyle = "outlined",
    tagsColour = "primary",
    fullClick = false,
    inputArray = [],
    restrictArray = [],
    style = {},
    handleTagClick = () => { },
    handleSearch = () => { },
    showNewAdd = true,
    handleNewAdd = () => { },
    customAddIcon = {
      icon: null,
      text: "Add",
      className: "",
      onClick: () => { },
      fullClick: false,
    },
    options = {}, // Options now grouped by keys (e.g., cd2, cd3)
    
    custom_dimensions = [],
    loading = false,
    searchingTranslation = ''
  } = props;

  const mainDivRef = useRef(null);
  const inputFieldRef = useRef(null);
  const [filteredOptions, setFilteredOptions] = useState({});
  const [inputActive, setInputActive] = useState(false);
  const [trimmedValue, setTrimmedValue] = useState('');
  const { payload } = Session.get("login");
  const UserSession = payload;
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    main: mainDivRef.current,
    input: inputFieldRef.current,
  }));

  const AddIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M12 6.85714H6.85714V12H5.14286V6.85714H0V5.14286H5.14286V0H6.85714V5.14286H12V6.85714Z" fill="white" />
    </svg>
  );

  const RemoveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z"
        fill="#3C83BC"
      />
    </svg>
  );
  const handleExistingTagClick = (item) => {
    handleTagClick(item);
  };

  const localHandleNewAddClick = () => {
      setInputActive(true);
      setFilteredOptions({}); // Reset filteredOptions to empty initially
      setTimeout(() => {
          inputFieldRef.current.focus();
      }, 100);
  };
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        mainDivRef.current &&
        !mainDivRef.current.contains(e.target) &&
        !e.target.closest('.search-tags')
      ) {
        inputFieldRef.current.value = "";
        setInputActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);
  useEffect(() => {
    if (trimmedValue.length >= 2) {
      const newFilteredOptions = {};
      Object.keys(options).forEach(cdKey => {
        newFilteredOptions[cdKey] = options[cdKey]
          .filter(option =>
            option.localeCompare(trimmedValue, undefined, { sensitivity: 'base' }) >= 0
          )
          .filter(option =>
            !inputArray.some(inputItem =>
              inputItem.value === cdKey && inputItem.label === option
            )
          );
      });
      setFilteredOptions(newFilteredOptions);
    }
  }, [options, trimmedValue, inputArray]);
  

  const handleInputBlur = (e) => {
    if (
      mainDivRef.current.contains(e.relatedTarget) ||
      (e.relatedTarget && e.relatedTarget.closest('.search-tags'))
    ) {
      return;
    }
  };

  const handleInputNewAdd = (item) => {
    const trimmedValue = item.label.toString().trim();
    if (trimmedValue !== "") {
        const newItem = {
            value: item.value,
            label: trimmedValue,
        };
        handleNewAdd(newItem);
        
        inputFieldRef.current.value = "";
        setInputActive(false);
        setTrimmedValue(''); // Reset the trimmed value
        setFilteredOptions({}); // Reset the filtered options
    }
};


  const handleInputSearch = (e) => {
    const value = e.target.value.trim().toLowerCase();
    setTrimmedValue(value);

    if (value.length === 0) {
      setFilteredOptions({});
    } else if (value.length < 2) {
      setFilteredOptions({});
    } else {
      handleSearch(value); // Trigger API call

      // Directly apply filtering on current options after API call
      setFilteredOptions(() => {
        const newFilteredOptions = {};
        Object.keys(options).forEach(cdKey => {
          newFilteredOptions[cdKey] = options[cdKey].filter(option =>
            option.toLowerCase().localeCompare(value, undefined, { sensitivity: 'base' }) >= 0
          );
        });
        return newFilteredOptions;
      });
    }
  };

  const crTagsClass = tagsStyle === "filled" ? "cr-tag-filled" : "cr-tag-outlined";
  const crTagsColour = tagsColour === "primary" ? "cr-tag-primary" : "cr-tag-warn";
  return (
    <div className="relative-div">
      <div
        className={`wms-creatable-text-box ${error ? "cr-error" : ""}`}
        ref={mainDivRef}
        style={{
          ...style,
          borderBottomLeftRadius: inputActive && trimmedValue ? "0px" : "12px",
          borderBottomRightRadius: inputActive && trimmedValue ? "0px" : "12px",
        }}
      >
        {headingText && <p className="cr-heading">{headingText}</p>}

        <div className="cr-tags">
          {inputArray.map((item, index) => {
            const restrict = restrictArray.some(restricted => restricted.value === item.value && restricted.label === item.label);
            return (
              <span
                key={index}
                className={`cr-tag ${crTagsClass} ${crTagsColour} ${restrict ? "cr-restrict" : ""} ${fullClick ? "cr-tag-full-pointer" : ""}`}
                onClick={fullClick ? () => handleExistingTagClick(item) : null}
              >
                <span onClick={fullClick ? null : () => handleExistingTagClick(item)}>
                  {startIcon && !restrict ? startIcon : <RemoveIcon />}
                </span>
                <p>{item.label}</p>
              </span>
            );
          })}

          {showNewAdd && !inputActive && (
            <span className="cr-add-tags" onClick={localHandleNewAddClick}>
              <AddIcon />
            </span>
          )}
          <input
            type="text1"
            ref={inputFieldRef}
            onBlur={handleInputBlur}
            onChange={handleInputSearch}
            style={{
              display: inputActive ? "" : "none",
              flexGrow: "1"
            }}
          />
          {customAddIcon.icon && (
            <span
              className={`cr-tag cr-tag-custom ${customAddIcon.className} ${customAddIcon.fullClick ? "cr-tag-full-pointer" : ""}`}
              onClick={customAddIcon.fullClick ? customAddIcon?.onClick : null}
            >
              <span onClick={customAddIcon.fullClick ? null : customAddIcon?.onClick}>{customAddIcon.icon}</span>
              <p>{customAddIcon.text}</p>
            </span>
          )}
        </div>
      </div>

      {inputActive && trimmedValue && (
        <div className={`search-tags ${error ? "cr-error" : ""}`}>
          {loading ? (
            <span className="nohoverEf"><p>{t('pf_select2_searching')}</p></span>
          ) : (
            (() => {
              let hasValidItems = false;

              const groups = Object.keys(filteredOptions).map(cdKey => {
                const itemsInGroup = Array.from(new Set(filteredOptions[cdKey]));
                if (itemsInGroup.length > 0) {
                  hasValidItems = true;
                }
                return itemsInGroup.length === 0 ? null : (
                  <div key={cdKey} style={{ width: '100%' }}>
                    {cdKey && <p className="cdLabel">{custom_dimensions[cdKey]["visible_name"][UserSession.DashboardLanguage.toLowerCase()]}</p>}
                    {itemsInGroup.map((item, index) => (
                      <span key={index} onClick={() => handleInputNewAdd({ value: cdKey, label: item })}>
                        <p>{item}</p>
                      </span>
                    ))}
                  </div>
                );
              });

              if (hasValidItems) {
                return groups;
              } else {
                return (
                  trimmedValue.length === 0 ? (
                    <span className="nohoverEf"><p>{t('atc_no_data_in_filter_cd')}</p></span>
                  ) : (
                    trimmedValue.length < 2 ? (
                      <span className="nohoverEf"><p>{t("seect2_filter_type_message")}</p></span>
                    ) : (
                      <span className="nohoverEf"><p>{t('atc_no_data_in_filter_cd')}</p></span>
                    )
                  )
                );
              }
            })()
          )}
        </div>
      )}
    </div>
  );
});




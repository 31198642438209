import React from 'react';
import { useState } from 'react';
import { Session } from 'bc-react-session';

import { useTranslation } from 'react-i18next';
import '../../../styles/CookieConsentMain.css';
import CookieConsent2 from './cookie-consent-2';
import ModalComponent from '../modal-component';
import { ReactComponent as LogoLoaderDE } from '../../../assets/icons/LoaderGermanLogo.svg';
import { ReactComponent as LogoLoaderEN } from '../../../assets/icons/LoaderEnglishLogo.svg';
import i18n from '../../../i18n';

export default function CookieConsentMain({checkedGA, checkedSL, handleChangeGA, handleChangeSL, showGA, showSL, handleShowGA, handleShowSL, handleSubmitConsent,modal2,setModal2,hideModal2,cookieConsentExists }) {

  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState(t('feedback_beta_box_title'));
  const { payload } = Session.get('login');
  const UserSession = payload;
  return <>
    <div className="cookie-consent-main">


      <div className="cookie-modal-fullscreen-1280 screen">
      <span key={1} className="logoCookieConsent">
      {i18n.language === 'en' ? <LogoLoaderEN  loading="lazy" className={'full_logo'} /> : <LogoLoaderDE  loading="lazy" className={'full_logo'} />}
        </span>
       <div className="frame-318">
          <p className="welcome-to-our-jobsp" dangerouslySetInnerHTML={{__html: t('consent_1_welcome')}}>
          </p>
          {/* <p className="sed-ut-perspiciatis" dangerouslySetInnerHTML={{__html: t('consent_1_welcome_desc')}}>
          </p> */}
          <p className="what-we-use-to-make" dangerouslySetInnerHTML={{__html: t('consent_1_what_we_use_js_better')}}>
          </p>
          <div className="frame-230" id="toggle_ga" onClick={handleShowGA}>
            <div className="frame-232">
              <div className="google-analytics mulish-bold-big-stone-14px">
                {t('consent_ga')}
              </div>
              <span className="up-arrow" style={{ display: showGA ? 'none' : '' }}>
                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#1C2D3B" />
                </svg>
              </span>
              <span className="down-arrow" style={{ display: showGA ? '' : 'none' }}>
                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#1C2D3B" />
                </svg>
              </span>
            </div>
            <div className="lorem-ipsum-dolor-si toggle_ga" style={{ display: showGA ? 'none' : '' }} dangerouslySetInnerHTML={{__html: t('consent_ga_desc')}}>
            </div>
          </div>
          <div className="frame-233" id="toggle_sl" onClick={handleShowSL}>
            <div className="frame-232">
              <div className="smartlook mulish-bold-big-stone-14px">{t('consent_smartlook')}</div>
              <span className="up-arrow" style={{ display: showSL ? 'none' : '' }}>
                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#1C2D3B" />
                </svg>
              </span>
              <span className="down-arrow" style={{ display: showSL ? '' : 'none' }}>
                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#1C2D3B" />
                </svg>
              </span>
            </div>
            <div className="lorem-ipsum-dolor-si toggle_sl" style={{ display: showSL ? 'none' : '' }} dangerouslySetInnerHTML={{__html: t('consent_smartlook_desc')}}>
            </div>
          </div>
        </div>
        <div className="frame-314">
          <article className="button-1" id="consent_selection" onClick={() => { setModal2(true); setModalTitle(''); }}>
            <div className="button-2 valign-text-middle" >{t('consent_select_tool')}</div>
          </article>
          <article className="button-3" id="consent_no_selection" onClick={handleSubmitConsent}>
            <div className="label valign-text-middle">
              {t('consent_proceed_without_select')}
            </div>
          </article>
        </div>
      </div>
    </div>
    <ModalComponent
      show={modal2}
      modalClassName="cookie-modal"
      size='lg'
      modalId=""
      handleClose={() => { setModal2(false); }}
      handleShow={() => { setModal2(true); }}
      modalTitle={modalTitle}
      backdrop={UserSession.UIPreferences.cookie_consent === undefined ?"static":''}
      keyboard={UserSession.UIPreferences.cookie_consent === undefined ?false:true}
    >
      <CookieConsent2 checkedGA={checkedGA} checkedSL={checkedSL} handleChangeGA={handleChangeGA} handleChangeSL={handleChangeSL} showGA={showGA} showSL={showSL} handleShowGA={handleShowGA} handleShowSL={handleShowSL} handleSubmitConsent={handleSubmitConsent} modal2={modal2} setModal2={setModal2} cookieConsentExists={cookieConsentExists} hideModal2={hideModal2}/>
    </ModalComponent>
  </>

}
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../styles/CookieConsent2.css';
import { ReactComponent as LogoLoaderDE } from '../../../assets/icons/LoaderGermanLogo.svg';
import { ReactComponent as LogoLoaderEN } from '../../../assets/icons/LoaderEnglishLogo.svg';
import i18n from '../../../i18n';
// import { setPortfolioData } from '../../services';
export default function CookieConsent2({ checkedGA, checkedSL, handleChangeGA, handleChangeSL, showGA, showSL, handleShowGA, handleShowSL, handleSubmitConsent, hideModal2, cookieConsentExists }) {
  const { t } = useTranslation();
  return <>
    <div className="cookie-consent-2">
      <div className="cookie-modal-fullscreen-2-1280 screen">
        <span type="button" className="close-button" onClick={hideModal2} aria-label="Close" style={{ display: cookieConsentExists ? '' : 'none', cursor: 'pointer' }}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z" fill="var(--black)" className="closeModal"></path>
          </svg></span>
        {/* <LogoConsentIcon alt="Frame 317" className="frame-317-cookie-modal-fullscreen-2-1280"/> */}
        <span key={1} className="logoCookieConsent">
        {i18n.language === 'en' ? <LogoLoaderEN  loading="lazy" className={'full_logo'} /> : <LogoLoaderDE  loading="lazy" className={'full_logo'} />}
          
        </span>
        <div className="frame-318-cookie-modal-fullscreen-2-1280">
          <div className="frame-313">
            <p className="i-want-to-help-you-a">
              {t('consent_help_text')}
            </p>
            <div className="frame-3">
              <div className="frame-31">
                <div className="form-check form-switch ga_switch mr-4 pl-0">
                  <input className="form-check-input switch mt-0" style={{ cursor: 'pointer' }} type="checkbox" id="ga_switch" onChange={handleChangeGA} defaultChecked={checkedGA} />
                </div>
                <div className="google-analytics-approved ga_switch_approved" style={{ display: checkedGA ? '' : 'none' }}>
                  {t('consent_ga')} {t('consent_approved')}
                </div>
                <div className="smartlook-declined ga_switch_declined" style={{ display: checkedGA ? 'none' : '' }}>
                  {t('consent_ga')} {t('consent_declined')}
                </div>
              </div>
              <div className="frame-317-frame-3" onClick={handleShowGA}>
                <div className="frame-232 " id="toggle_ga">
                  <div className="info mulish-normal-black-16px">{t('consent_info_text')}</div>
                  <span className="up-arrow" style={{ display: showGA ? 'none' : '', cursor: 'pointer' }}>
                    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#1C2D3B" />
                    </svg>
                  </span>
                  <span className="down-arrow" style={{ display: showGA ? '' : 'none', cursor: 'pointer' }}>
                    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#1C2D3B" />
                    </svg>
                  </span>

                </div>
                <div className="lorem-ipsum-dolor-si toggle_ga" style={{ display: showGA ? 'none' : '' }} dangerouslySetInnerHTML={{ __html: t('consent_ga_desc') }}>
                </div>
              </div>
            </div>
            <div className="frame-3">
              <div className="frame-31">

                <div className="form-check form-switch sl_switch mr-4 pl-0">
                  <input className="form-check-input switch mt-0" style={{ cursor: 'pointer' }} type="checkbox" id="sl_switch" onChange={handleChangeSL} defaultChecked={checkedSL} />
                </div>
                <div className="google-analytics-approved sl_switch_approved" style={{ display: checkedSL ? '' : 'none' }}>
                  {t('consent_smartlook')} {t('consent_approved')}
                </div>
                <div className="smartlook-declined sl_switch_declined" style={{ display: checkedSL ? 'none' : '' }}>
                  {t('consent_smartlook')} {t('consent_declined')}
                </div>

              </div>
              <div className="frame-317-frame-3" onClick={handleShowSL}>
                <div className="frame-232 " id="toggle_sl">
                  <div className="info mulish-normal-black-16px">{t('consent_info_text')}</div>
                  <span className="up-arrow" style={{ display: showSL ? 'none' : '', cursor: 'pointer' }}>
                    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#1C2D3B" />
                    </svg>
                  </span>
                  <span className="down-arrow" style={{ display: showSL ? '' : 'none', cursor: 'pointer' }}>
                    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#1C2D3B" />
                    </svg>
                  </span>

                </div>
                <div className="lorem-ipsum-dolor-si toggle_sl" style={{ display: showSL ? 'none' : '' }} dangerouslySetInnerHTML={{ __html: t('consent_smartlook_desc') }}>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-314">
          <article className="button-1" style={{ display: cookieConsentExists ? '' : 'none', cursor: 'pointer' }} id="consent_selection" onClick={hideModal2}>
            <div className="button-2 valign-text-middle">{t('job_page_click_raise_limit_cancel_text')}</div>
          </article>
          <article className="button-3">
            <div className="label valign-text-middle" id="consent_selection_submit" onClick={handleSubmitConsent}>
              {t('consent_agree_and_proceed')}
            </div>
          </article>
        </div>
        {/* <div className="frame-314">
          <div className="button">
            <div className="label valign-text-middle" id="consent_selection_submit" onClick={handleSubmitConsent}>{t('consent_agree_and_proceed')}</div>
          </div>
        </div> */}
      </div>

    </div>
  </>

}
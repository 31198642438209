import log from "loglevel";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/icon-success.svg';
import ModalBodyScrollIcon from "./scroll-down";

const SuccessFailModal = (props) => {
  const { t } = useTranslation();
  const { show, modalClassName, className, contentClassName, handleClose, size, modalTitle, modalId, backdrop, keyboard, successFailModal, setSuccessFailModal,activeTabValue, dialogClassName, modalMessage, backToSettingsButton, backButton, backButtonText, setAnotherModal, sessionChanged, isSmallModal,enableSuccessIcon } = props;
  const onClosePopup = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsUserID = params.get("theuserid");
    if(paramsUserID === '' || paramsUserID === null){
      window.location.href=activeTabValue;
    }else{
      window.location.href= activeTabValue+'?theuserid='+paramsUserID;
    } 
    
  }

  return <><Modal
    className={(modalClassName !== undefined?modalClassName:'') + (isSmallModal !== undefined && isSmallModal === true ? ' small-dailog ' : '')}
    dialogClassName={(dialogClassName !== undefined?dialogClassName:'') + (isSmallModal !== undefined && isSmallModal === true ? ' small-dailog ' : '')}
    id={'messageModal'}
    contentClassName={(contentClassName!== undefined?contentClassName:'') + (isSmallModal !== undefined && isSmallModal === true ? ' smallModal ' : '')}
    onClose={() => { setSuccessFailModal(false); log.debug('modal popup close');
      if(setAnotherModal !== undefined){
        setAnotherModal(false);
        
      }
      
      
      if (sessionChanged) { window.location.reload() } }}
    show={successFailModal}
    onHide={() => { setSuccessFailModal(false); log.debug('modal popup close'); if(setAnotherModal !== undefined){
      setAnotherModal(false);
      
    } if (sessionChanged) { window.location.reload() } }}
  >
    <Modal.Header closeButton style={{ minHeight: '60px' }}>
      <Modal.Title><span>{modalTitle}</span></Modal.Title>
    </Modal.Header>
    <ModalBodyScrollIcon>
      <div style={{ display: 'flex', gap: '16px', fontSize: "16px" }}>
        {enableSuccessIcon !== undefined?<SuccessIcon />:<></>} {modalMessage}
      </div>
    </ModalBodyScrollIcon> 
    <Modal.Footer>
      {backToSettingsButton !== undefined ? <>
      <Button variant="primary" className="pull-right primary_action_button" onClick={() => { 
        setSuccessFailModal(false); onClosePopup();
        if(setAnotherModal !== undefined){
          setAnotherModal(true);
          
        } 
        }}>{t('md_beta_back_to_settings')}</Button></> : <></>}
      {backButton !== undefined && backButton !== false? <Button variant="primary" className={'primary_action_button'} onClick={() => { setSuccessFailModal(false); if(setAnotherModal !== undefined){
          setAnotherModal(false);
          
        }  if (sessionChanged) { window.location.reload() } }}>{backButtonText !== undefined ? backButtonText : t('job_page_click_raise_limit_cancel_text')}</Button> : <></>}
    </Modal.Footer>
  </Modal>

  </>
};

export default SuccessFailModal;
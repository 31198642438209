import React, { useState, useEffect } from 'react';
import Carousel from "./inc/Carousel";
import Footer from "./inc/Footer";
import Language from "./inc/LoginLanguage";
import { getLoginData } from '../services';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { Session } from "bc-react-session";

import "../styles/Login.scss"; 

import { useWMSContext } from '../wmsContext';
import { AESGCM_DECRYPT, AESGCM_ENCRYPT } from '../utils/aes-256-GCM';
import { useGoogleLogin } from '@react-oauth/google';

import axios from 'axios';
import Loader from './blocks/common/loader-block';

import { IS_LOCALHOST, MS_CLIENT_ID } from '../config/Constants';
import { SITE_URL } from '../services/ServiceConstants';

const MSLOGIN_LINK = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id="+MS_CLIENT_ID+"&scope=openid%20email&response_type=token&redirect_uri="+encodeURIComponent(SITE_URL+'login')


const LoginPage = () => {

  const [hasLoader,setHasLoader]= useState(false)
  function popupwindow(url, title, w, h) {
    
    var left = (window.screen.width / 2) - (w / 2);
    var top = (window.screen.height / 2) - (h / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  }

  const languageSuffix = i18n.language === 'en' ? '_EN' : '_DE';
  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  const { hasSession, setHasSession } = useWMSContext()

  const [email, setEmail] = useState('');
// pradeep@wollmilchsau.de
  const [password, setPassword] = useState('');
  const [isHoveredGoogle, setIsHoveredGoogle] = useState(false);
  const [isHoveredMicrosoft, setIsHoveredMicrosoft] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginAttempt, setLoginAttempt] = useState('');
  const [emailError, setEmailError] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loginPage, setLoginPage] = useState(true);


  const [showLogo, setShowLogo] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const redirectURL = params.get("redirect");
 
  
  // Login with Google
  const [user, setUser] = useState([]);
  const login =useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log('Google Signin', codeResponse);
      setUser(codeResponse)
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
      if (user.access_token !== undefined) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            // console.log('Google Signin', res.data)
            let postdata = { SignInWithSocial: 'google-login-credentials', userData: res.data ,currentLanguage:i18n.language,redirect:redirectURL }
            getSessionData(postdata)
            // return false;
          })
          .catch((err) => console.log(err));
      }
  },[user]);
  // Login with Google


  
  

  let locationHash = window.location.hash;
  let queryArr = locationHash.replace('#access_token', 'access_token').split('&');
  

  useEffect(()=>{
    
    if(queryArr !== undefined){
      // setHasLoader(true);
      // if(loginWithMS){
        
      // }
      let queryParams = {};
      for (var q = 0; q < queryArr.length; q++) {
        var qArr = queryArr[q].split('=');
        queryParams[qArr[0]] = qArr[1];
      }
      
      console.log('MS Signin', queryParams)
      if(queryParams.access_token !== undefined){
        setHasLoader(true);
        let postdata = { SignInWithSocial: 'ms-login-credentials', ms_data: queryParams ,currentLanguage:i18n.language,redirect:redirectURL}
        // window.opener.location = "/kunden_beta/myjobs";
        // window.close();
        getSessionData(postdata,true)
      }
      
      // return false;
    }
    
  },[queryArr])

  // Login with Microsoft

  const getSessionData = (postdata,loginWithMS = false) => {
    
    getLoginData(postdata)
      .then(
        (portfolioDataRes) => {
          // console.log('Google Signin Social Login', portfolioDataRes)
          // console.log(portfolioDataRes, 'check this portfolio res')
          setShowLogo(false);
          if (portfolioDataRes.status === 'success') {
            // alert('Set Session')
            setHasSession(true);

            var decryptedString = AESGCM_DECRYPT(portfolioDataRes.sessionData);
            let decryptedArr = JSON.parse(decryptedString);
            if (decryptedArr.status === 'loggedin') {
              i18n.changeLanguage((decryptedArr.DashboardLanguage).toLowerCase())
              Session.start('login', { expiration: decryptedArr.expirationReact ? decryptedArr.expirationReact : 2000 });
              Session.setPayload(decryptedArr)
            }
            // alert(postdata.redirect);
            let currentRedirectUrl = portfolioDataRes.page;
            if(postdata.redirect !== null){
              currentRedirectUrl = atob(postdata.redirect);
            }
            if(!IS_LOCALHOST){
              currentRedirectUrl = '/kunden_beta/loginWithReact?sessionData='+AESGCM_ENCRYPT(JSON.stringify({email:decryptedArr.UserEmail,redirectURL:currentRedirectUrl}));
            }
            
            if(loginWithMS){
              window.opener.location = currentRedirectUrl;
              window.close();
            }else{
              window.location.href = currentRedirectUrl;
            }
          } else if (portfolioDataRes.status === 'fail') {
            setHasSession(false)
            setErrorMessage(t('header_index_login_form_error_message'));
            setLoginAttempt(portfolioDataRes.loginAttempt);
          } else {
            setHasSession(false)
            console.log("Unexpected response received");
          }
        },
      )
  }

  const handleRememberMeChange = (e) => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    getLoginData({ login_carousel: 1, requestFromOld: 1 })
      .then(
        (result) => {
          const filteredItems = result
            .filter(item => item.Status === "Enabled")
            .map(item => ({
              imgSrc: `https://tools.jobspreader.com/manage/images/tools-uploaded/teaser/` + item[`ImageURL${languageSuffix}`],
              mainHeading: item[`HeadLine${languageSuffix}`],
              subHeading: item[`SubLine${languageSuffix}`],
              link: item[`Link${languageSuffix}`],
              newTag: item.NewTag === "Enabled",
              startDate: item.StartDate
            }));
          setItems(filteredItems);
        },
      )
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  

  const handleLogin = () => {
    setEmailError('');
    setPasswordError('');
    setErrorMessage('');
    setLoginAttempt('');
    setShowLogo(true);
    if (password !== '' && email !== '') {
      if (!validateEmail(email)) {
        setEmailError(t('header_index_login_form_invalid_email_message'));
        setShowLogo(false);
        return;
      }
      let postDataProtFolioTable = { loginAPI: 1, password: password, email: email,rememberMe:rememberMe,currentLanguage:i18n.language,redirect:redirectURL };
      
      getSessionData(postDataProtFolioTable)
    } else {
      setTimeout(() => {
        setShowLogo(false);
      }, 500);
      const emptyErr = t('header_index_login_form_required_message');
      if (email === '') {
        setEmailError(emptyErr);
      }
      if (password === '') {
        setPasswordError(emptyErr);
      }
    }
  }

  const handleForgotPasswordPage = () => {
    setShowLogo(true);
    window.location.href = 'forgot_password';
  }

  return (
    <>
    <div className='pageWrapper'>
      
    <div className="login">
    {hasLoader?<Loader loadingTimer={0} style={{ width: "120px", height: "120px" }} />:<>
      {showLogo && (
        <div className="logo-container">
          <img
            className="logo"
            src={require("../assets/images/login/logo-1.svg").default}
            alt="Logo"
          />
        </div>
      )}
      <Carousel items={items} />
      <div className={`welcome-container`}>
        {/* <GoogleLogin onSuccess={googleResponseMessage} onError={googleErrorMessage} /> */}
        {/* <div className='topDiv'></div> */}
        <div className={`form welcome-containerlogin ${loginPage ? '' : 'd-none'}`}>
          <div className="form-header">
            <div className="form-title">{t('login_heading')}</div>
            <p className="form-subtitle">{t('login_sub_heading')}</p>
          </div>
          <div className="div">
            <div className="div-2">
              <div className="text-wrapper">{t('login_email_address')}*</div>
              <div className={`input-container ${emailError || errorMessage ? 'error-border' : ''}`} >
                <input type="email" className="email-input" placeholder={t('login_email_address_placeholder')} value={email} onChange={(e) => setEmail(e.target.value)} />
                <img className="img" src={require("../assets/images/login/iconaction-10.svg").default} />
              </div>
              {emailError && <span className="error-message error-msg">{emailError}</span>}
            </div>
            <div className="div-2">
              <div className="text-wrapper">{t('login_password')}*</div>

              <div className={`input-container ${passwordError || errorMessage ? 'error-border' : ''}`}>
                <input type="password" className="email-input" placeholder={t('login_password_placeholder')} value={password} onChange={(e) => setPassword(e.target.value)} />
                <img className="img" src={require("../assets/images/login/iconaction-11.svg").default} />
              </div>
              {passwordError && <span className="error-message error-msg">{passwordError}</span>}
            </div>
            <div className="options-container">
              <div className="login-components-2">
                <div className="checkbox"><input type="checkbox" className="checkbox-default checkbox cursor-pointer" checked={rememberMe} onChange={handleRememberMeChange} /><i className="input-helper"></i></div>
                <div className="option-description cursor-pointer" onClick={handleRememberMeChange}>{t('login_remember_me')}</div>
              </div>
              <div className="login-components-2"><a className="option-description-2 cursor-pointer" onClick={handleForgotPasswordPage}>{t('login_forgot_password')}</a></div>
            </div>
            <div className='loginErrorDIV'>
              {errorMessage && <div className="error-message error-msg" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
              {loginAttempt && <div className="login-attempt-message error-msg" dangerouslySetInnerHTML={{ __html: loginAttempt }} />}
            </div>
          </div>
          <div className="div">
            <div className="div">
              <button className="submit-button-text-wrapper" onClick={(e) => { handleLogin() }}><span className="submit-button-text">{t('login_sign_in_btn')}</span></button>
            </div>
            <div className="separator">
              <div className="separator-line"></div>
              <div className="option-description">{t('login_or')}</div>
              <div className="separator-line"></div>
            </div>
            <div className="div-wrapper cursor-pointer google-div"
              onMouseEnter={() => setIsHoveredGoogle(true)}
              onMouseLeave={() => setIsHoveredGoogle(false)}
            >

              <div className="div-3" onClick={() => login()}>
                <img className="google"
                  src={isHoveredGoogle ? require("../assets/images/login/google_hover.svg").default : require("../assets/images/login/google-1-2.svg").default}
                />
                <div className="text-wrapper-2">{t('login_signin_google')}</div>
              </div>
            </div>
            {/* <MsalAuthenticationTemplate interactionType={InteractionType.Popup}> */}
            <div className="div-wrapper cursor-pointer google-div"
              onMouseEnter={() => setIsHoveredMicrosoft(true)}
              onMouseLeave={() => setIsHoveredMicrosoft(false)}
              onClick={() => popupwindow(MSLOGIN_LINK, t('login_with_microsoft_button_text'), 800, 600)}
              
            >
              <div className="div-3">
                <img className="google" src={isHoveredMicrosoft ? require("../assets/images/login/microsoft_hover.svg").default : require("../assets/images/login/vector-2.svg").default} />
                <div className="text-wrapper-2">{t('login_signin_microsoft')}</div>
              </div>
            </div>
            {/* </MsalAuthenticationTemplate> */}
          </div>
        </div>

        <Language />
      </div>
      <Footer /></>}
    </div>
    </div>
    </>
  );
};

export default LoginPage;

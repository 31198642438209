/**
 * React entrypoint
 *
 * File updated for 404 implementation, no changes in
 * any other session or login related code.
 */
import log from "loglevel";
import React, { useEffect, useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import { Session } from "bc-react-session";

import App from "./App";
import AppLogin from "./AppLogin";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import NotFound from "./components/inc/NotFound";

import { LOGIN_URL, SITE_URL } from "./services/ServiceConstants";
import { IS_LOCALHOST } from "./config/Constants";
import { HelmetProvider } from "react-helmet-async";
import { RouteMap, SettingRoutes, InnovationLabRoutes, URLAccessibleWithoutSession } from "./config/RouteMap";
import { getLoginData, getPortfolioData, getSessionData } from "./services";
import { WMSContextProvider, useWMSContext } from "./wmsContext";

import "./styles/index.scss";

import ForgotPassword from "./components/ForgotPassword"
import { AESGCM_DECRYPT } from "./utils/aes-256-GCM";

/****** original password *******/

// import loginDEV from "./dev/mainAccountJson.json";
// import loginDEV from './dev/masterAccount.json';
// import loginDEV from './dev/subAccount.json'; //use with theuserid
// import loginDEV from './dev/demoAccount.json';

/****** master password *********/

import loginDEV from "./dev/mainAccountMasterPass.json";

// import loginDEV from "./dev/masterAccountDB.json";
// import loginDEV from './dev/masterAccountMasterPass.json';
// import loginDEV from './dev/subAccountMasterPass.json'; //use with theuserid

/******* older imports **********/
// import loginDEV from '../src/dev/realAccountTestJson.json';
// import loginDEV from '../src/dev/superAdminJson.json';
// import loginDEV from '../src/dev/signInAsAccount.json';

if (IS_LOCALHOST) log.enableAll();
else if (localStorage.getItem("wmsEnableConsoleLogs")) log.enableAll();
else log.disableAll();

log.debug("Index.js Load");


function WMSRootApp() {
  const { is404, set404 } = useWMSContext();
  const {hasSession,setHasSession} = useWMSContext()
  
  const currentPath = (window.location.origin + window.location.pathname).replace(SITE_URL, "");
  const redirectPath = window.location.pathname + window.location.search;
  const validPath = Object.values({...RouteMap, ...SettingRoutes, ...InnovationLabRoutes,...URLAccessibleWithoutSession}).indexOf(currentPath) > -1;
  const params = new URLSearchParams(window.location.search);
  const subUserID = params.get("theuserid");
  let theuserid = "";

  if (subUserID != null) {
    theuserid = "theuserid=" + subUserID;
  }
  const { payload } = Session.get("login");
  const UserSession = payload;
  
  useEffect(()=>{
    
  })
  
  useLayoutEffect(()=>{
    console.log('hasSession',UserSession)
    // TODO: Why below 3 lines added ? - Shubham
    // if (UserSession.status !== undefined && UserSession.status === "loggedin") {
    //   setHasSession(true)
    // }
    // Session.destroy("login");
// if (IS_LOCALHOST) {
    //  else {
      if (hasSession || theuserid !== '') {
        
        // const data = {
        //   getInnovationLab: 1,
        //   sessionData: UserSession,
        //   action: "get_all_labs",
        // };
    
        // getLoginData(data)
        //   .then((response) => {
        //     // setAllLabs(response);
        //   })
        //   .finally(() => {
        //     // setPageProps({
        //     //   ...pageProps,
        //     //   loading: false,
        //     // });
        //   });

        // Login Service Start
        getSessionData(theuserid).then(
          (result) => {
            console.log("Login is already Set");
            console.log("Session is set", result);
          },
          (error) => {
            // alert(theuserid)
            log.debug("Root index.js getSessionData Service Error", error);
            if(error ==='NoSession'){
              setHasSession(false);
            }
            if(error === "Invalid theuserid" ){
              set404(true);
            }
            
            // if(validPath) {
            //   window.location = LOGIN_URL + "?redirect=" + btoa(redirectPath);
            // }
            console.log(currentPath);
            console.log(Object.values({...URLAccessibleWithoutSession}));
            console.log(validPath && Object.values({...URLAccessibleWithoutSession}).indexOf(currentPath) > -1);
            if(validPath && Object.values({...URLAccessibleWithoutSession}).indexOf(currentPath) > -1) {
              window.location = UserSession.DefaultLandingPageURL;
              return false;
            }
          }
        );
      }
      if(!hasSession && currentPath !== RouteMap.forgot_password && !theuserid){
        if(validPath && currentPath === 'logout') {
          window.location = LOGIN_URL;
        }else if(validPath && currentPath !== 'login' && params.get("redirect") === null) {
          window.location = LOGIN_URL +"?redirect=" + btoa(redirectPath);
        }
      }
      // Login Service End
    // }
    // // Comment below
    // if (IS_LOCALHOST) {
    //   Session.start("login", { expiration: 3600000 });

    //   let decryptedString = AESGCM_DECRYPT(loginDEV.data);
    //   // console.log(loginDEV.data)
    //   let decryptedArr = JSON.parse(decryptedString);
    //   decryptedArr['DashboardLanguage'] = 'EN';
    //   decryptedArr['intlLocale'] = 'en';
    //   // delete decryptedArr['UIPreferences']['cookie_consent'];
    //   console.log(decryptedArr)
    //   Session.setPayload(decryptedArr);
    // }
    // Comment below
    // eslint-disable-next-line
  }, []);
  
  // return <HelmetProvider>{((is404 || !validPath ? <NotFound /> :<App theuserid={theuserid} />)) }</HelmetProvider>;
  // return <HelmetProvider>{is404 || !validPath ? <NotFound /> : (hasSession?<App theuserid={theuserid} />:<AppLogin theuserid={theuserid} />)}</HelmetProvider>;
  return (
    <HelmetProvider>
      {currentPath === RouteMap.forgot_password ? (
        <ForgotPassword />
      ) : is404 || !validPath ? (
        <NotFound />
      ) : hasSession || theuserid ? (
        <App theuserid={theuserid} />
      ) : (
        <AppLogin theuserid={theuserid} />
      )}
    </HelmetProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <WMSContextProvider>
    <WMSRootApp />
  </WMSContextProvider>
);
//React.StrictMode calls App.js 2 times.

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

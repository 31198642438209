import React, { useState, useEffect, useRef } from "react";
import "../../../styles/scroll-down.css";
import { ModalBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ScrollDownIcon = (props) => {
  return (
    <div
      id="scroll-down-for-more"
      style={{ position: "absolute", bottom: "16px", left: "-45px" }}
      className={props.className}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="62" viewBox="0 0 29 62" fill="none">
        <path
          d="M1 20.2012H27.6775M14.3389 7.76758V14.4409M1 31.9515C1 35.6389 2.50001 38.9944 4.91939 41.4251C7.33585 43.8589 10.6717 45.3679 14.3402 45.3679C18.0057 45.3679 21.3415 43.8589 23.758 41.4251C26.1775 38.9944 27.6775 35.6389 27.6775 31.9515V14.4192C27.6775 10.732 26.1775 7.37643 23.758 4.94272C21.3415 2.51184 18.0057 1 14.3402 1H14.3373C10.6717 1.00293 7.33585 2.51184 4.91939 4.94272C2.50001 7.37643 1 10.732 1 14.4192V31.9515Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="arrow_b"
          d="M4.87891 54.2549L14.3391 61.0001L23.6963 54.2549"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="arrow_a"
          d="M4.87891 48.4536L14.3391 55.1988L23.6963 48.4536"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const setScrollViewFromRef = (element, setHook, setShowBackToTop) => {
  if (element === null) return;
  const { scrollHeight, scrollTop, clientHeight } = element;
  const modalHeaderHeight = element.parentNode.querySelector('.modal-header') && element.parentNode.querySelector('.modal-header').clientHeight || 0;
  const modalFooterHeight = element.parentNode.querySelector('.modal-footer') && element.parentNode.querySelector('.modal-footer').clientHeight || 0;

  if (scrollTop + clientHeight + 5 >= scrollHeight) {
    setHook(false);
  } else {
    setHook(true);
  }

  if( scrollHeight/2 > (window.innerHeight - modalHeaderHeight - modalFooterHeight) ){
    setShowBackToTop(true);
  }
  else{
    setShowBackToTop(false);
  }
};

/**
 * Renders a modal body with scroll functionality and a scroll down icon.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be rendered inside the modal body.
 * @returns {JSX.Element} The rendered modal body with scroll functionality and scroll down icon.
 */
export default function ModalBodyScrollIcon({ children, ...props }) {
  const [showComponent, setShowComponent] = useState(false);
  const [isTopModal, setIsTopModal] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const modalBodyRef = useRef(null);
  const { t } = useTranslation();

  let timeoutId = null;
  const handleScroll = (e) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setScrollViewFromRef(e.target, setShowComponent, setShowBackToTop);
    }, 50);
  };

  const scrollToTop = () => {
    modalBodyRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setScrollViewFromRef(modalBodyRef.current, setShowComponent, setShowBackToTop);

    let timeoutObserver = null;
    const observer1 = new MutationObserver(() => {
      if (timeoutObserver) clearTimeout(timeoutObserver);
      timeoutObserver = setTimeout(() => {
        setScrollViewFromRef(modalBodyRef.current, setShowComponent, setShowBackToTop);
      }, 50);
    });

    const observer2 = new MutationObserver(() => {
      const modalsOpen = document.querySelectorAll(".modal.show");
      if (modalsOpen.length > 0) {
        setIsTopModal(modalBodyRef.current && modalsOpen[modalsOpen.length - 1].contains(modalBodyRef.current));
      }
    });

    if (modalBodyRef.current) {
      observer1.observe(modalBodyRef.current, { childList: true, subtree: true, attributes: true });
      observer2.observe(document.body, { childList: true });
    }

    return () => {
      observer1.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <React.Fragment>
      <ModalBody ref={modalBodyRef} onScroll={handleScroll} {...props} style={{ scrollbarGutter: "stable both-edges" }}>
        {children}
        {
          showBackToTop && (
          <button onClick={scrollToTop} className={ "figma-btn fg-small fg-secondary footer_back_to_top_modal d-flex " }>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 16V3.825L1.9 9.425L0.5 8L8.5 0L16.5 8L15.1 9.425L9.5 3.825V16H7.5Z" fill="#3C83BC"></path>
            </svg>
            <span>{t("back_to_top")}</span>
          </button>
          )
        }
      </ModalBody>
      <ScrollDownIcon className={showComponent && isTopModal ? "fade-in-scroll-down" : "fade-out-scroll-down"} />
    </React.Fragment>
  );
}

import log from "loglevel";
import { useTranslation } from "react-i18next";
import { Session } from 'bc-react-session';
import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as IconAddJobGroupSVG } from "../../../assets/icons/IconAddJobGroup.svg";
import { ReactComponent as IconAddJobGroupDisabledSVG } from "../../../assets/icons/IconAddJobGroupDisabled.svg";
import { ReactComponent as RedWarningSVG } from '../../../assets/icons/Red Warning Icon.svg';
import { RouteMap, SettingRoutes } from "../../../config/RouteMap";
import { validateEmail } from "../common-blocks";
import ModalBodyScrollIcon from "../common/scroll-down";
import { getPortfolioData } from "../../../services";
import { WMSCreatableInstantSearchBox } from "../common/wms-figma-library";
import { AddIcon } from "../iconClasses/AddIcon";

import { useWMSContext } from "../../../wmsContext";
import { DeleteExternalRecipientModal } from "./settings_tab_notifications";

export default function NewsletterNotifications({ arrUsers, settingsData, isManual = true, setArrUsers, NewsletterData, setNewsletterData }) {
    const { t } = useTranslation();
    const { payload } = Session.get('login');
    const UserSession = payload;
    const { setIsModifiedGlobal } = useWMSContext()
    let arrDataUsers = [];
    const [modal, setModal] = useState(false);
    const [RecipientToRemove, setRecipientToRemove] = useState('')
    if (arrUsers !== undefined && settingsData !== undefined) {
        if (settingsData.defaultAdmin !== undefined) {
            Object.keys(settingsData.defaultAdmin).map((item, index) => {
                arrDataUsers.push(settingsData.defaultAdmin[item]);
            })
        }
        Object.keys(arrUsers).map((item, index) => {
            arrDataUsers.push(arrUsers[item]);
        })
    }
    arrDataUsers = arrDataUsers.sort(function (a, b) { return String(a.name).localeCompare(String(b.name), 'de', { sensitivity: "base" }) })


    const [externalUser, setExternalUser] = useState("");
    const [showBlock, setshowBlock] = useState(true);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const handleshowBlock = () => {
        setshowBlock(!showBlock);
    };
    const handleJobBudgetRow = (event) => {

        // Single User (All) (select All)
        if (event.target.id === 'AllSelectedRecipients') {
            if (event.target.checked === true) {
                let tempUsers = []
                arrUsers !== undefined && arrDataUsers.map((element, index) => {
                    tempUsers.push(parseInt(element.ID))
                })
                NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                    tempUsers.push(element.mail)
                })
                log.debug(tempUsers)
                setSelectedRecipients(tempUsers)
                // Set Main CheckBox
                setNewsletterData({
                    ...NewsletterData,
                    AllSelectedRecipients: "Enabled"
                });
            }
            if (event.target.checked === false) {
                let tempUsers = []
                setSelectedRecipients(tempUsers)
                // Set Main CheckBox
                setNewsletterData({
                    ...NewsletterData,
                    AllSelectedRecipients: "Disabled"
                });
            }

        }

        // Single Users Internal Checkbox (Select/Deselect One)
        if (event.target.id.startsWith("NewsletterDataSingleUsersInternal-")) {
            if (event.target.checked === true) {
                let tempUsers = []
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    tempUsers.push(element)
                })
                tempUsers.push(parseInt(event.target.getAttribute('data-subuserid')))

                setSelectedRecipients(tempUsers)
                // Set Main CheckBox
                var checkedItems = document.querySelectorAll("#NewsletterDataTable tbody .form-check-flat input:checked");
                var uncheckedItems = document.querySelectorAll("#NewsletterDataTable tbody .form-check-flat input");
                setNewsletterData({
                    ...NewsletterData,
                    AllSelectedRecipients: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
            }
            if (event.target.checked === false) {
                let tempUsers = []
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    if (parseInt(event.target.getAttribute('data-subuserid')) !== element) { tempUsers.push(element) }
                })
                setSelectedRecipients(tempUsers)

                // Set Main CheckBox
                var checkedItems = document.querySelectorAll("#NewsletterDataTable tbody .form-check-flat input:checked");
                var uncheckedItems = document.querySelectorAll("#NewsletterDataTable tbody .form-check-flat input");
                setNewsletterData({
                    ...NewsletterData,
                    AllSelectedRecipients: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
            }

        }
        // Single Users External Checkbox (Select/Deselect One)
        if (event.target.id.startsWith("NewsletterDataSingleUsersExternal-")) {
            if (event.target.checked === true) {
                let tempUsers = []
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    tempUsers.push(element)
                })
                tempUsers.push((event.target.value))
                setSelectedRecipients(tempUsers)

                // Set Main CheckBox
                var checkedItems = document.querySelectorAll("#NewsletterDataTable tbody .form-check-flat input:checked");
                var uncheckedItems = document.querySelectorAll("#NewsletterDataTable tbody .form-check-flat input");
                setNewsletterData({
                    ...NewsletterData,
                    AllSelectedRecipients: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
            }
            if (event.target.checked === false) {
                let tempUsers = []
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    if (element !== event.target.value) { tempUsers.push(element) }
                })
                setSelectedRecipients(tempUsers)

                // Set Main CheckBox
                var checkedItems = document.querySelectorAll("#NewsletterDataTable tbody .form-check-flat input:checked");
                var uncheckedItems = document.querySelectorAll("#NewsletterDataTable tbody .form-check-flat input");
                setNewsletterData({
                    ...NewsletterData,
                    // ExternalRecipient: tempExt,
                    AllSelectedRecipients: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });

            }

        }


        // Main Radio Button Events
        if (event.target.id === 'SelectedEnabledNewsletter') {
            log.debug('selectedRecipients', selectedRecipients)
            let tempInternal = []
            let arrProcessedRecipients = [];
            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (selectedRecipients.includes(parseInt(element.SubUserID))) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.status = "Enabled"
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (selectedRecipients.includes(element.mail)) {
                    arrProcessedRecipients.push(element.mail)
                    element.status = "Enabled"
                }
                tempExt.push(element)
            })

            if (selectedRecipients.length !== arrProcessedRecipients.length) {
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    if (!arrProcessedRecipients.includes(element)) {
                        let presentInInternal = false;
                        arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                            if (parseInt(elementUser.ID) == parseInt(element)) {
                                tempInternal.push({ SubUserID: parseInt(element), status: "Enabled", frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                                presentInInternal = true;
                            }
                        })
                        if (!presentInInternal) {
                            tempExt.push({ mail: (element), status: "Enabled", frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        }
                    }
                })
            }

            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }
        // Main Radio Button Events
        if (event.target.id === 'SelectedDisabled') {
            log.debug('selectedRecipients', selectedRecipients)
            let tempInternal = []
            let arrProcessedRecipients = [];
            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (selectedRecipients.includes(parseInt(element.SubUserID))) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.status = "Disabled"
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (selectedRecipients.includes(element.mail)) {
                    arrProcessedRecipients.push(element.mail)
                    element.status = "Disabled"
                }
                tempExt.push(element)
            })

            if (selectedRecipients.length !== arrProcessedRecipients.length) {
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    if (!arrProcessedRecipients.includes(element)) {
                        let presentInInternal = false;
                        arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                            if (parseInt(elementUser.ID) == parseInt(element)) {
                                tempInternal.push({ SubUserID: parseInt(element), status: "Disabled", frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                                presentInInternal = true;
                            }
                        })
                        if (!presentInInternal) {

                            tempExt.push({ mail: (element), status: "Disabled", frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        }
                    }
                })
            }

            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }

        // Main Radio Button Events
        if (event.target.id === 'SelectedWeekly') {
            log.debug('selectedRecipients', selectedRecipients)
            let tempInternal = []
            let arrProcessedRecipients = [];
            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (selectedRecipients.includes(parseInt(element.SubUserID))) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.frequency = "Weekly"
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (selectedRecipients.includes(element.mail)) {
                    arrProcessedRecipients.push(element.mail)
                    element.frequency = "Weekly"
                }
                tempExt.push(element)
            })

            if (selectedRecipients.length !== arrProcessedRecipients.length) {
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    if (!arrProcessedRecipients.includes(element)) {
                        let presentInInternal = false;
                        arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                            if (parseInt(elementUser.ID) == parseInt(element)) {
                                tempInternal.push({ SubUserID: parseInt(element), status: "Disabled", frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                                presentInInternal = true;
                            }
                        })
                        if (!presentInInternal) {

                            tempExt.push({ mail: (element), status: "Disabled", frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        }
                    }
                })
            }

            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }
        // Main Radio Button Events
        if (event.target.id === 'SelectedMonthly') {
            log.debug('selectedRecipients', selectedRecipients)
            let tempInternal = []
            let arrProcessedRecipients = [];
            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (selectedRecipients.includes(parseInt(element.SubUserID))) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.frequency = "Monthly"
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (selectedRecipients.includes(element.mail)) {
                    arrProcessedRecipients.push(element.mail)
                    element.frequency = "Monthly"
                }
                tempExt.push(element)
            })

            if (selectedRecipients.length !== arrProcessedRecipients.length) {
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    if (!arrProcessedRecipients.includes(element)) {
                        let presentInInternal = false;
                        arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                            if (parseInt(elementUser.ID) == parseInt(element)) {
                                tempInternal.push({ SubUserID: parseInt(element), status: "Disabled", frequency: "Monthly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                                presentInInternal = true;
                            }
                        })
                        if (!presentInInternal) {

                            tempExt.push({ mail: (element), status: "Disabled", frequency: "Monthly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        }
                    }
                })
            }

            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }
        // Main Radio Button Events
        if (event.target.id === 'SelectedMonthlyWeekly') {
            log.debug('selectedRecipients', selectedRecipients)
            let tempInternal = []
            let arrProcessedRecipients = [];
            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (selectedRecipients.includes(parseInt(element.SubUserID))) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.frequency = "MonthlyWeekly"
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (selectedRecipients.includes(element.mail)) {
                    arrProcessedRecipients.push(element.mail)
                    element.frequency = "MonthlyWeekly"
                }
                tempExt.push(element)
            })

            if (selectedRecipients.length !== arrProcessedRecipients.length) {
                selectedRecipients !== undefined && selectedRecipients.map((element, index) => {
                    if (!arrProcessedRecipients.includes(element)) {
                        let presentInInternal = false;
                        arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                            if (parseInt(elementUser.ID) == parseInt(element)) {
                                tempInternal.push({ SubUserID: parseInt(element), status: "Disabled", frequency: "MonthlyWeekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                                presentInInternal = true;
                            }
                        })
                        if (!presentInInternal) {

                            tempExt.push({ mail: (element), status: "Disabled", frequency: "MonthlyWeekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        }
                    }
                })
            }

            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }
        // Main Radio Button Events
        if (event.target.id.startsWith("CurrentSelectedEnableDisable-")) {
            log.debug('selectedRecipients CurrentSelectedEnableDisable', event.target.value)
            let currentUniqueId = event.target.getAttribute('data-subuserid')
            let newStatus = event.target.value === 'Enabled' ? 'Disabled' : 'Enabled'
            let tempInternal = []
            let arrProcessedRecipients = [];

            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (parseInt(currentUniqueId) === parseInt(element.SubUserID)) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.status = newStatus
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (arrProcessedRecipients.length === 0 && currentUniqueId === element.mail) {
                    arrProcessedRecipients.push(element.mail)
                    element.status = newStatus
                }
                tempExt.push(element)
            })

            if (arrProcessedRecipients.length === 0) {
                let presentInInternal = false;
                arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                    if (parseInt(elementUser.ID) == parseInt(currentUniqueId)) {
                        tempInternal.push({ SubUserID: parseInt(currentUniqueId), status: newStatus, frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        presentInInternal = true;
                    }
                })
                if (!presentInInternal) {
                    tempExt.push({ mail: (currentUniqueId), status: newStatus, frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                }
            }


            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }
        // Main Radio Button Events
        if (event.target.id.startsWith("CurrentSelectedWeekly-")) {
            log.debug('selectedRecipients CurrentSelectedWeekly', event.target.value)
            let currentUniqueId = event.target.getAttribute('data-subuserid')
            let newFrequency = 'Weekly';
            let tempInternal = []
            let arrProcessedRecipients = [];

            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (parseInt(currentUniqueId) === parseInt(element.SubUserID)) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.frequency = newFrequency
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (arrProcessedRecipients.length === 0 && currentUniqueId === element.mail) {
                    arrProcessedRecipients.push(element.mail)
                    element.frequency = newFrequency
                }
                tempExt.push(element)
            })
            // if(arrProcessedRecipients.length === 0 && validateEmail(currentUniqueId)){
            //     tempExt.push({mail:(element),status:newStatus,frequency:"Weekly",AllJobs:true,SelectedJGs:[],SelectedCustomDimensions:[]})
            // }
            // if(arrProcessedRecipients.length === 0 && !validateEmail(currentUniqueId)){
            //     tempInternal.push({SubUserID:parseInt(element),status:newStatus,frequency:"Weekly",AllJobs:true,SelectedJGs:[],SelectedCustomDimensions:[]})
            // }
            if (arrProcessedRecipients.length === 0) {
                let presentInInternal = false;
                arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                    if (parseInt(elementUser.ID) == parseInt(currentUniqueId)) {
                        tempInternal.push({ SubUserID: parseInt(currentUniqueId), status: "Disabled", frequency: newFrequency, AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        presentInInternal = true;
                    }
                })
                if (!presentInInternal) {
                    tempExt.push({ mail: (currentUniqueId), status: "Disabled", frequency: newFrequency, AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                }


            }


            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }
        log.debug('selectedRecipients CurrentSelectedMonthly', event.target)
        // Main Radio Button Events
        if (event.target.id.startsWith("CurrentSelectedMonthly-")) {

            let currentUniqueId = event.target.getAttribute('data-subuserid')
            let newFrequency = 'Monthly';
            let tempInternal = []
            let arrProcessedRecipients = [];

            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (parseInt(currentUniqueId) === parseInt(element.SubUserID)) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.frequency = newFrequency
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (arrProcessedRecipients.length === 0 && currentUniqueId === element.mail) {
                    arrProcessedRecipients.push(element.mail)
                    element.frequency = newFrequency
                }
                tempExt.push(element)
            })

            if (arrProcessedRecipients.length === 0) {
                let presentInInternal = false;
                arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                    if (parseInt(elementUser.ID) == parseInt(currentUniqueId)) {
                        tempInternal.push({ SubUserID: parseInt(currentUniqueId), status: "Disabled", frequency: newFrequency, AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        presentInInternal = true;
                    }
                })
                if (!presentInInternal) {
                    tempExt.push({ mail: (currentUniqueId), status: "Disabled", frequency: newFrequency, AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                }


            }


            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }

        if (event.target.id.startsWith("CurrentSelectedMonthlyWeekly-")) {

            let currentUniqueId = event.target.getAttribute('data-subuserid')
            let newFrequency = 'MonthlyWeekly';
            let tempInternal = []
            let arrProcessedRecipients = [];

            NewsletterData.InternalSubUsers !== undefined && NewsletterData.InternalSubUsers.map((element, index) => {
                if (parseInt(currentUniqueId) === parseInt(element.SubUserID)) {
                    arrProcessedRecipients.push(parseInt(element.SubUserID))
                    element.frequency = newFrequency
                }
                tempInternal.push(element)
            })

            let tempExt = []
            NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
                if (arrProcessedRecipients.length === 0 && currentUniqueId === element.mail) {
                    arrProcessedRecipients.push(element.mail)
                    element.frequency = newFrequency
                }
                tempExt.push(element)
            })

            if (arrProcessedRecipients.length === 0) {
                let presentInInternal = false;
                arrUsers !== undefined && arrDataUsers.map((elementUser, indexUser) => {
                    if (parseInt(elementUser.ID) == parseInt(currentUniqueId)) {
                        tempInternal.push({ SubUserID: parseInt(currentUniqueId), status: "Disabled", frequency: newFrequency, AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                        presentInInternal = true;
                    }
                })
                if (!presentInInternal) {
                    tempExt.push({ mail: (currentUniqueId), status: "Disabled", frequency: newFrequency, AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })
                }


            }


            setNewsletterData({
                ...NewsletterData,
                InternalSubUsers: tempInternal,
                ExternalRecipient: tempExt,
            });
            setIsModifiedGlobal(true)
        }

    };



    const handleAddExternalReceipient = () => {
        let tempExt = []
        NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
            tempExt.push(element)
        })
        tempExt.push({ mail: externalUser, status: "Enabled", frequency: "Weekly", AllJobs: true, SelectedJGs: [], SelectedCustomDimensions: [] })

        setNewsletterData({
            ...NewsletterData,
            ExternalRecipient: tempExt
        });
        setIsModifiedGlobal(true)
    }

    const handleRemoveExternalReceipient = (event, recipientMail) => {
        let tempExt = []
        NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
            if (recipientMail !== element.mail) {
                tempExt.push({ mail: element.mail, status: element.status })
            }
        })
        setNewsletterData({
            ...NewsletterData,
            ExternalRecipient: tempExt
        });
        setIsModifiedGlobal(true)
    }
    const removeExternalReceipient = (event, recipientMail, isManual) => {
        setRecipientToRemove(recipientMail);
        setModal(true);
        return false;
    }

    const [showAdvancedSetting, setShowAdvancedSetting] = useState(false)
    const handleShowAdvancedSettings = (e) => {
        setShowAdvancedSetting(true);
    };

    arrDataUsers = arrDataUsers.sort(function (a, b) { return String(a.name).localeCompare(String(b.name), 'de', { sensitivity: "base" }) })
    useEffect(() => {
        log.debug('SettingsNoti ', settingsData)
        if (settingsData !== undefined) {
            setArrUsers(settingsData.arrSubAccounts)
            setNewsletterData(settingsData.NewsletterNotification);
            let tempSelectedRecipients = []
            // Initial selection (TODO:)
            // settingsData.JobBudgetNotification.InternalSubUsers !== undefined && settingsData.JobBudgetNotification.InternalSubUsers.map((element, index) => {
            //     if(element.status === "Enabled"){
            //         tempSelectedRecipients.push(element.SubUserID)
            //     }
            // })
            // NewsletterData.ExternalRecipient !== undefined && NewsletterData.ExternalRecipient.map((element, index) => {
            //     if(element.status === "Enabled"){
            //         tempSelectedRecipients.push(element.mail)
            //     }
            // })
            setSelectedRecipients(tempSelectedRecipients)
        }
        // eslint-disable-next-line
    }, [settingsData]);
    log.debug(NewsletterData)
    return <>

        <div className="job_budget_notifications mb-3">

            <div className="frame">
                <div className="div">
                    <div className="text-wrapper">{t('nl_notif_head')}</div>
                    <div onClick={handleshowBlock} >
                        <span className="up-arrow" style={{ display: !showBlock ? 'none' : '', cursor: 'pointer' }}>
                            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#1C2D3B" />
                            </svg>
                        </span>
                        <span className="down-arrow" style={{ display: !showBlock ? '' : 'none', cursor: 'pointer' }}>
                            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#1C2D3B" />
                            </svg>
                        </span>

                    </div>
                </div>
                {showBlock ? <><p className="who-shall-receive" dangerouslySetInnerHTML={{ __html: t('nl_notif_head_description') }}>

                </p>
                    <div className="div-2">
                        <div className="div-3">
                            <EnableDisableDropdown handleJobBudgetRow={handleJobBudgetRow} isDisabled={selectedRecipients.length === 0} />
                            <FrequencyDropdownHeader handleJobBudgetRow={handleJobBudgetRow} isDisabled={selectedRecipients.length === 0} />
                        </div>
                        <div className="div-3">
                            <button className={"button button-3 " + (selectedRecipients.length === 0 ? ' disable-button' : '')} onClick={(e) => { if (selectedRecipients.length !== 0) { handleShowAdvancedSettings(e) } }}><div className="label">{t('nl_notif_customize_btn_text')}</div></button>
                        </div>
                    </div>
                    <div className="div-4">
                        <SelectionSection arrSynonyms={NewsletterData} arrDataUsers={arrDataUsers} arrUsers={arrUsers} handleJobBudgetRow={handleJobBudgetRow} removeExternalReceipient={removeExternalReceipient} selectedRecipients={selectedRecipients} />

                    </div>
                    <AddExternalReceipient arrSynonyms={NewsletterData} arrDataUsers={arrDataUsers} arrUsers={arrUsers} handleAddExternalReceipient={handleAddExternalReceipient} externalUser={externalUser} setExternalUser={setExternalUser} />
                    <DeleteExternalRecipientModal modal={modal} setModal={setModal} handleRemoveExternalReceipient={handleRemoveExternalReceipient} isManual={isManual} RecipientToRemove={RecipientToRemove} />
                </> : <></>}

                {showAdvancedSetting ? <OverviewModal showAdvancedSetting={showAdvancedSetting} setShowAdvancedSetting={setShowAdvancedSetting} arrUsers={arrUsers} arrDataUsers={arrDataUsers} arrSynonyms={NewsletterData} selectedRecipients={selectedRecipients} /> : <></>}
            </div>


        </div>
    </>
}

function EnableDisableDropdown({ selectedJobs, handleJobBudgetRow, isDisabled }) {

    const { t } = useTranslation();
    const ChangeJobGroupssToggle = React.forwardRef(({ children, onClick }, ref) => (

        <Button
            type='button'
            variant='secondary'
            className={"button dropdown-toggle"}
            id='enableDisableDropdown'

            drop={'up'}
            to="#"
            ref={ref}
            data-flip="false"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);

            }}
        >
            {t('nl_notif_enable_disable_dropdown_text')}
        </Button>
    ));
    return <>
        {<Dropdown className={"d-inline EnableDisableDropdown " + (isDisabled ? 'disabledButton' : '')}>
            <Dropdown.Toggle as={ChangeJobGroupssToggle} ></Dropdown.Toggle>
            <Dropdown.Menu className="" align="start">

                <Dropdown.Item key={1} id="SelectedEnabledNewsletter" onClick={(e) => handleJobBudgetRow(e)}>{t('nl_notif_enable_dropdown_text')}</Dropdown.Item>
                <Dropdown.Item key={2} id="SelectedDisabled" onClick={(e) => handleJobBudgetRow(e)}>{t('nl_notif_disable_dropdown_text')}</Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>}</>
}
function FrequencyDropdown({ handleJobBudgetRow, selection, isDisabled, uniqueId }) {

    const { t } = useTranslation();
    const ChangeJobGroupssToggle = React.forwardRef(({ children, onClick }, ref) => (

        <Button
            type='button'
            variant='outline-primary'
            className={"dropdown-toggle ml-2 " + (isDisabled ? ' disable-button clickable' : '')}
            id='FrequencyDropdown'

            drop={'up'}
            to="#"
            ref={ref}
            data-flip="false"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);

            }}
        >
            {selection === 'Monthly' ? t('nl_notif_fre_Monthly_text') : ''}
            {selection === 'Weekly' ? t('nl_notif_freq_Weekly_text') : ''}
            {selection === 'MonthlyWeekly' ? t('nl_notif_fre_weekly_Monthly_text') : ''}
        </Button>
    ));
    return <>
        {<Dropdown className="d-inline FrequencyDropdown">
            <Dropdown.Toggle as={ChangeJobGroupssToggle} ></Dropdown.Toggle>
            <Dropdown.Menu className="" align="start">
                {selection !== 'Monthly' ? <><Dropdown.Item key={2} id={"CurrentSelectedMonthly-" + uniqueId}
                    name={"CurrentSelectedMonthly-" + uniqueId} data-subuserid={uniqueId} onClick={(e) => handleJobBudgetRow(e)}>{t('nl_notif_fre_Monthly_text')}</Dropdown.Item></> : <></>}
                {selection !== 'Weekly' ? <><Dropdown.Item key={1} id={"CurrentSelectedWeekly-" + uniqueId}
                    name={"CurrentSelectedWeekly-" + uniqueId}
                    data-subuserid={uniqueId}
                    onClick={(e) => handleJobBudgetRow(e)}>{t('nl_notif_freq_Weekly_text')}</Dropdown.Item></> :
                    <></>}
                {selection !== 'MonthlyWeekly' ? <><Dropdown.Item key={3} id={"CurrentSelectedMonthlyWeekly-" + uniqueId}
                    name={"CurrentSelectedMonthlyWeekly-" + uniqueId} data-subuserid={uniqueId} onClick={(e) => handleJobBudgetRow(e)}>{t('nl_notif_fre_weekly_Monthly_text')}</Dropdown.Item></> : <></>}




            </Dropdown.Menu>
        </Dropdown>}</>
}
function FrequencyDropdownHeader({ selectedJobs, handleJobBudgetRow, isDisabled }) {
    // log.debug('ManualActionDropdown',jobGroupId)
    const { t } = useTranslation();
    const ChangeJobGroupssToggle = React.forwardRef(({ children, onClick }, ref) => (

        <Button
            type='button'
            variant='secondary'
            className={"button dropdown-toggle ml-2 " + (selectedJobs > 0 ? '' : 'disabledButton')}
            id='FrequencyDropdownHeader'

            drop={'up'}
            to="#"
            ref={ref}
            data-flip="false"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);

            }}
        >
            {t('nl_notif_frequency_text')}
        </Button>
    ));
    return <>
        {<Dropdown className={"d-inline FrequencyDropdownHeader " + (isDisabled ? ' disable-button' : '')}>
            <Dropdown.Toggle as={ChangeJobGroupssToggle} ></Dropdown.Toggle>
            <Dropdown.Menu className="" align="start">
                <Dropdown.Item key={1} id="SelectedWeekly" onClick={(e) => handleJobBudgetRow(e)}>{t('nl_notif_freq_Weekly_text')}</Dropdown.Item>
                <Dropdown.Item key={2} id="SelectedMonthly" onClick={(e) => handleJobBudgetRow(e)}>{t('nl_notif_fre_Monthly_text')}</Dropdown.Item>
                <Dropdown.Item key={2} id="SelectedMonthlyWeekly" onClick={(e) => handleJobBudgetRow(e)}>{t('nl_notif_fre_weekly_Monthly_text')}</Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>}</>
}
function SelectionSection({ arrSynonyms, handleJobBudgetRow, isManual, arrDataUsers, arrUsers, removeExternalReceipient, selectedRecipients }) {
    const { t } = useTranslation();
    const { payload } = Session.get('login');
    const UserSession = payload;
    log.debug(arrDataUsers.length)
    const handleJobBudgetJobs = (e) => {

    }
    log.debug('SelectionSection', arrSynonyms)
    let keycounter = 0;
    return <>
        <div className="div-2">
            <div className={"div-5 "}>
                <table className={"table "} id="NewsletterDataTable">
                    <thead><tr><th className="th_recipients" >
                        <div className="th_recipients_div">
                            <div className="form-check form-check-flat d-inline-block">
                                <label className="form-check-label checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={"AllSelectedRecipients"}
                                        name={"AllSelectedRecipients"}
                                        value="test"
                                        defaultChecked={arrSynonyms.AllSelectedRecipients !== undefined && arrSynonyms.AllSelectedRecipients === "Enabled"}
                                        onChange={handleJobBudgetRow}
                                    />
                                    {t("nl_notif_recipients_head")} <i className="input-helper"></i>
                                </label>
                            </div>
                        </div>
                    </th>
                        <th className="th_enable_disable">{t("nl_notif_enable_disable_head")} </th>
                        <th className="th_frequency">{t("nl_notif_frequency_head")} </th>
                        <th className="th_all_jobs">{t("nl_notif_all_jobs_head")} </th>
                        <th className="th_selected_jobs">{t("nl_notif_selected_jobs_head")} </th>
                        <th className="th_view_selection">{t("nl_notif_view_selection_head")} </th>
                    </tr></thead>
                    <tbody>
                        {arrUsers !== undefined && arrUsers !== "" && arrDataUsers.length > 0 && arrDataUsers.map((element, index) => {
                            let isDisabled = false;
                            keycounter++;
                            let currentUserJobBudgetSetting = arrSynonyms.InternalSubUsers.filter(x => x['SubUserID'] === parseInt(element.ID))
                            log.debug('Settingsnotif ' + element.ID, arrSynonyms.InternalSubUsers)
                            if (currentUserJobBudgetSetting.length > 0) {
                                currentUserJobBudgetSetting = currentUserJobBudgetSetting[0];
                            }
                            if (currentUserJobBudgetSetting.length === 0 || (currentUserJobBudgetSetting.status !== undefined && currentUserJobBudgetSetting.status === "Disabled")) {
                                isDisabled = true;
                            }
                            let notAllJobs = currentUserJobBudgetSetting.AllJobs !== undefined && currentUserJobBudgetSetting.AllJobs === false && ((currentUserJobBudgetSetting.SelectedJGs !== undefined && currentUserJobBudgetSetting.SelectedJGs.length > 0) || (currentUserJobBudgetSetting.SelectedCustomDimensions !== undefined && currentUserJobBudgetSetting.SelectedCustomDimensions.length > 0))


                            // log.debug('Settingsnotif '+)
                            log.debug('Settingsnotif ' + element.name, currentUserJobBudgetSetting)
                            return <><UserRow element={element} isInternal={true} UserSession={UserSession} currentUserJobBudgetSetting={currentUserJobBudgetSetting} isDisabled={isDisabled} handleJobBudgetRow={handleJobBudgetRow} notAllJobs={notAllJobs} selectedRecipients={selectedRecipients} key={keycounter} /></>;

                        })}


                        {arrUsers !== undefined && arrSynonyms.ExternalRecipient.map((element, index) => {
                            keycounter++
                            let isDisabled = false;
                            let currentUserJobBudgetSetting = arrSynonyms.ExternalRecipient.filter(x => x['mail'] === (element.mail))
                            if (currentUserJobBudgetSetting.length > 0) {
                                currentUserJobBudgetSetting = currentUserJobBudgetSetting[0];
                            }
                            if (currentUserJobBudgetSetting.length === 0 || (currentUserJobBudgetSetting.status !== undefined && currentUserJobBudgetSetting.status === "Disabled")) {
                                isDisabled = true;
                            }
                            let notAllJobs = currentUserJobBudgetSetting.AllJobs !== undefined && currentUserJobBudgetSetting.AllJobs === false && ((currentUserJobBudgetSetting.SelectedJGs !== undefined && currentUserJobBudgetSetting.SelectedJGs.length > 0) || (currentUserJobBudgetSetting.SelectedCustomDimensions !== undefined && currentUserJobBudgetSetting.SelectedCustomDimensions.length > 0))

                            // log.debug('Settingsnotif '+element.ID+NewsletterData.InternalSubUsers.includes(element.ID))
                            log.debug('Settingsnotif ', currentUserJobBudgetSetting)

                            return <><UserRow element={element} isInternal={false} UserSession={UserSession} currentUserJobBudgetSetting={currentUserJobBudgetSetting} isDisabled={isDisabled} handleJobBudgetRow={handleJobBudgetRow} notAllJobs={notAllJobs} selectedRecipients={selectedRecipients} removeExternalReceipient={removeExternalReceipient} key={keycounter} /></>;
                        })}


                    </tbody></table>

            </div>
        </div></>
}

function AddExternalReceipient({ arrSynonyms, handleAddExternalReceipient, externalUser, setExternalUser }) {
    const { t } = useTranslation();
    const [isValid, setIsValid] = useState(false)

    const handleChangeInput = (event) => {
        setExternalUser(event.target.value)
        if (validateEmail(event.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }
    return <><div className={"frame-wrapper externalUser "}>
        <div className="div-7">
            <div className="">
                <input className="form-control text-box" placeholder={t("syn_notif_add_external_email_placeholder")} type="email" id="externalUserSynonymsNewsletter" onChange={handleChangeInput} value={externalUser} />
            </div>
            <button className={"button-3 " + (!isValid ? 'disable-button' : '')}>
                {(!isValid ? <AddIcon height='14px' width='14px' color="#C4C4C4" /> : <AddIcon height='14px' width='14px' />)}

                <span className={"label valign-text-middle "} onClick={(e) => { if (isValid) { handleAddExternalReceipient(e); setExternalUser(''); setIsValid(false); } }}>{t("syn_notif_add_external_email")}</span>
            </button>
        </div>
    </div></>
}

function UserRow({ isInternal = true, element, currentUserJobBudgetSetting, UserSession, handleJobBudgetRow, removeExternalReceipient, notAllJobs, handleJobBudgetJobs, isDisabled, selectedRecipients }) {
    let uniqueId = isInternal ? parseInt(element.ID) : element.mail
    let Username = isInternal ? element.name : element.mail
    // console.log('currentUserJobBudgetSetting 111', currentUserJobBudgetSetting)
    return <><tr key={'newsletter-notif-' + element.ID}>
        <td>
            <div className="external">
                <div className={'form-check form-check-flat mb-0 mt-0 h-auto'}>
                    <label className='form-check-label checkbox' data-sl="mask">

                        <input type='checkbox' className={'form-check-input '} id={(isInternal ? 'NewsletterDataSingleUsersInternal-' : 'NewsletterDataSingleUsersExternal-') + uniqueId} name={'subusers[' + uniqueId + '][Type]'} checked={selectedRecipients !== undefined && selectedRecipients.includes(uniqueId) ? true : false} value={uniqueId} data-subuserid={uniqueId} onChange={(e) => { handleJobBudgetRow(e) }} />{Username}<i className="input-helper"></i>
                    </label>
                </div>{
                    isInternal ? <></> : <>
                        <div onClick={(e) => removeExternalReceipient(e, uniqueId)} className="externalDeleteIcon"><svg className="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M2.85714 14.2222C2.85714 15.2 3.62857 16 4.57143 16H11.4286C12.3714 16 13.1429 15.2 13.1429 14.2222V3.55556H2.85714V14.2222ZM4.57143 5.33333H11.4286V14.2222H4.57143V5.33333ZM11 0.888889L10.1429 0H5.85714L5 0.888889H2V2.66667H14V0.888889H11Z" fill="#1C2D3B" />
                        </svg></div>
                    </>
                }
            </div>

        </td>
        <td>
            <div className="form-check form-switch ga_switch mr-4 pl-0">
                <input className="form-check-input switch mt-0" style={{ cursor: 'pointer' }} type="checkbox"
                    id={'CurrentSelectedEnableDisable-' + uniqueId}
                    value={currentUserJobBudgetSetting.status !== undefined && currentUserJobBudgetSetting.status === "Enabled" ? "Enabled" : "Disabled"}
                    data-subuserid={uniqueId}
                    onChange={(e) => {
                        handleJobBudgetRow(e);

                    }} checked={currentUserJobBudgetSetting.status !== undefined && currentUserJobBudgetSetting.status === "Enabled" ? true : false} />
            </div>
        </td>
        <td><FrequencyDropdown handleJobBudgetRow={handleJobBudgetRow} selection={currentUserJobBudgetSetting.frequency !== undefined ? currentUserJobBudgetSetting.frequency : 'Monthly'} isDisabled={isDisabled} uniqueId={uniqueId} handleFrequency={handleJobBudgetRow} /></td>
        <td><div className={"form-radio d-inline-block" + (isDisabled ? ' disable-button' : '')}>
            <label className="form-check-label">
                <input
                    type="radio"
                    className="form-check-input"
                    id={"AllJobs" + uniqueId}
                    name={"AllJobs-SelectedJobs-" + uniqueId}
                    value={currentUserJobBudgetSetting.AllJobs !== undefined && currentUserJobBudgetSetting.AllJobs}
                    defaultChecked={currentUserJobBudgetSetting.AllJobs !== undefined && currentUserJobBudgetSetting.AllJobs === true || !notAllJobs}
                    onChange={handleJobBudgetJobs}
                /><i className="input-helper"></i>
            </label>
        </div></td>
        <td><div className={"form-radio d-inline-block" + (isDisabled ? ' disable-button' : '')}>
            <label className="form-check-label">
                <input
                    type="radio"
                    className="form-check-input"
                    id={"SelectedJobs-Newsletter-" + uniqueId}
                    name={"AllJobs-SelectedJobs-" + uniqueId}
                    value={notAllJobs ? 'Disabled' : 'Enabled'}
                    defaultChecked={notAllJobs}
                    onChange={handleJobBudgetJobs}
                /><i className="input-helper"></i>
            </label>
        </div></td>
        <td>{notAllJobs ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M7.99995 4.72711C6.18167 4.72711 4.72705 6.18173 4.72705 8.00001C4.72705 9.81828 6.18167 11.2729 7.99995 11.2729C9.81822 11.2729 11.2728 9.81828 11.2728 8.00001C11.2728 6.18173 9.81822 4.72711 7.99995 4.72711Z" fill="#3C83BC" />
            <path d="M8 2C4.36366 2 1.23638 4.48004 0 8C1.23638 11.52 4.36366 14 8 14C11.6363 14 14.7636 11.52 16 8C14.7636 4.48004 11.6363 2 8 2ZM8 12.4C5.23635 12.4 2.76365 10.72 1.60001 8C2.76365 5.28002 5.23635 3.60003 8 3.60003C10.7636 3.60003 13.2364 5.28002 14.4 8C13.2364 10.72 10.7636 12.4 8 12.4Z" fill="#3C83BC" />
        </svg> : <></>} </td>
    </tr></>
}

function OverviewModal({ showAdvancedSetting, setShowAdvancedSetting, arrUsers, arrDataUsers, arrSynonyms, selectedRecipients, handleAdvancedSettings }) {

    const { t } = useTranslation();
    const { payload } = Session.get('login');
    const UserSession = payload;
    const modalCloseAdvancedSettings = () => {
        setShowAdvancedSetting(false)
    }
    const [jobGroupsOptions, setJobGroupOptions] = useState([]);
    const jobGroupsTableHandler = (postDataProtFolioTable) => {
        if (postDataProtFolioTable.page === undefined) {
            postDataProtFolioTable['page'] = 1;
        }

        // setFilteredPortfolioTableData([]);
        getPortfolioData(postDataProtFolioTable).then((portfolioDataRes) => {
            const options = portfolioDataRes.jobGroups.map(group => ({
                label: group.DisplayName,
                value: group.ID, // Assuming the ID field is named 'Id'
                iconDisplay: group.totalContributors
            }));
            log.debug('checkthis cb', portfolioDataRes.jobGroups);
            // setJobgroupData({
            //     jobgroups: portfolioDataRes.jobGroups,
            // });

            setJobGroupOptions(options);
        });
    };
    const [errorModal, setErrorModal] = useState(false);
    useEffect(() => {
        if (jobGroupsOptions.length === 0) {
            let postDataProtFolioTable = { jobGroupsTableAPI: 1, userID: UserSession.UserID, itemsPerPage: 5000, sessionData: UserSession };
            jobGroupsTableHandler(postDataProtFolioTable)
        }
    })

    return (
        <>
            <Modal show={showAdvancedSetting} onHide={modalCloseAdvancedSettings} id="createJobGroupConflictsPreview">
                <Modal.Header closeButton>
                    <Modal.Title>{t("notif_adv_head")}</Modal.Title>
                </Modal.Header>
                <div className="modal-subtitle" dangerouslySetInnerHTML={{ __html: t("notif_adv_subheading") }}></div>
                <ModalBodyScrollIcon>
                    <>
                        <div className="div-2">
                            <div className={"div-5 "}>
                                <table className={"table "} id="NewsletterDataTable">
                                    <thead><tr><th className="th_recipients" >

                                        {t("notif_recipients_head")}
                                    </th>
                                        <th className="th_selected_jobs">{t("notif_Job_group_head")} {t("notif_Job_group_head_assinged_text")} </th>
                                        <th className="th_view_selection">{t("notif_custom_dimensions_head")} </th>
                                    </tr></thead>
                                    <tbody>
                                        {arrUsers !== undefined && arrUsers !== "" && arrDataUsers.length > 0 && arrDataUsers.map((element, index) => {
                                            let isDisabled = false;
                                            let currentUserJobBudgetSetting = arrSynonyms.InternalSubUsers.length > 0 && arrSynonyms.InternalSubUsers.filter(x => x['SubUserID'] === parseInt(element.ID))
                                            if (currentUserJobBudgetSetting.length > 0) {
                                                currentUserJobBudgetSetting = currentUserJobBudgetSetting[0];
                                            }
                                            if (currentUserJobBudgetSetting.length === 0 || (currentUserJobBudgetSetting.status !== undefined && currentUserJobBudgetSetting.status === "Disabled")) {
                                                isDisabled = true;
                                            }
                                            let notAllJobs = currentUserJobBudgetSetting.AllJobs !== undefined && currentUserJobBudgetSetting.AllJobs === false && ((currentUserJobBudgetSetting.SelectedJGs !== undefined && currentUserJobBudgetSetting.SelectedJGs.length > 0) || (currentUserJobBudgetSetting.SelectedCustomDimensions !== undefined && currentUserJobBudgetSetting.SelectedCustomDimensions.length > 0))
                                            // log.debug('Settingsnotif '+)
                                            log.debug('Settingsnotif ' + element.name, currentUserJobBudgetSetting)
                                            return <><UserRowAdvanced jobGroupsOptions={jobGroupsOptions} element={element} isInternal={true} currentUserJobBudgetSetting={currentUserJobBudgetSetting} isDisabled={isDisabled} handleAdvancedSettings={handleAdvancedSettings} notAllJobs={notAllJobs} selectedRecipients={selectedRecipients} /></>;

                                        })}


                                        {arrUsers !== undefined && arrSynonyms.ExternalRecipient.map((element, index) => {

                                            let isDisabled = false;
                                            let currentUserJobBudgetSetting = arrSynonyms.ExternalRecipient.filter(x => x['mail'] === (element.mail))
                                            if (currentUserJobBudgetSetting.length > 0) {
                                                currentUserJobBudgetSetting = currentUserJobBudgetSetting[0];
                                            }
                                            if (currentUserJobBudgetSetting.length === 0 || (currentUserJobBudgetSetting.status !== undefined && currentUserJobBudgetSetting.status === "Disabled")) {
                                                isDisabled = true;
                                            }
                                            let notAllJobs = currentUserJobBudgetSetting.AllJobs !== undefined && currentUserJobBudgetSetting.AllJobs === false && ((currentUserJobBudgetSetting.SelectedJGs !== undefined && currentUserJobBudgetSetting.SelectedJGs.length > 0) || (currentUserJobBudgetSetting.SelectedCustomDimensions !== undefined && currentUserJobBudgetSetting.SelectedCustomDimensions.length > 0))

                                            // log.debug('Settingsnotif '+element.ID+NewsletterData.InternalSubUsers.includes(element.ID))
                                            log.debug('Settingsnotif ', currentUserJobBudgetSetting)
                                            return <><UserRowAdvanced jobGroupsOptions={jobGroupsOptions} element={element} isInternal={false} currentUserJobBudgetSetting={currentUserJobBudgetSetting} isDisabled={isDisabled} handleAdvancedSettings={handleAdvancedSettings} notAllJobs={notAllJobs} selectedRecipients={selectedRecipients} /></>;
                                        })}


                                    </tbody></table>

                            </div>
                        </div></>
                </ModalBodyScrollIcon>
                <Modal.Footer>
                    <div className="btn-cancel" onClick={modalCloseAdvancedSettings}>
                        {t("notif_adv_cancel")}
                    </div>
                </Modal.Footer>
            </Modal>{" "}
            {<AlertSettingsAdvancedModal modal={errorModal} setModal={setErrorModal} modalMessage={t('notif_adv_no_group_or_cd_msg')} />}
        </>
    );
}


function UserRowAdvanced({ isInternal = true, element, jobGroupsOptions, currentUserJobBudgetSetting, UserSession, handleAdvancedSettings, notAllJobs, handleJobBudgetJobs, isDisabled, selectedRecipients }) {
    let uniqueId = isInternal ? parseInt(element.ID) : element.mail
    let Username = isInternal ? element.name : element.mail
    const { t } = useTranslation();
    log.debug(jobGroupsOptions)
    let arrIncludeJobGroup = []
    return <><tr key={'admin-' + element.ID}>
        <td>
            {Username}
        </td>

        <td> <WMSCreatableInstantSearchBox
            style={{ borderRadius: '12px', border: "1px solid #3C83BC" }}
            fullClick={true}
            inputArray={arrIncludeJobGroup}
            handleNewAdd={(enteredItem) => {
                const newObject = {
                    "value": enteredItem,
                    "label": enteredItem, // Use the label provided in enteredItem
                };

                const alreadyExists = arrIncludeJobGroup.some(item => item.value === enteredItem);
                // if (!alreadyExists) {
                //     setArrIncludeJobGroup([...arrIncludeJobGroup, newObject]);
                // }
            }}

            // tagsStyle={hasIncludeJobGroupError ? 'error' : 'outlined'} // filled
            showNewAdd={true} //hide + button
            // handleTagClick={(jobgroup) => {
            //     setArrIncludeJobGroup([...arrIncludeJobGroup.filter((removeJobgroup) => removeJobgroup.value !== jobgroup.value)]);
            // }}
            options={jobGroupsOptions}
            noOptionsTranslation={t("atc_no_data_in_filter_jobgroup")}
            data_type={'jobgroup_icon'}
        /></td>
        <td> <WMSCreatableInstantSearchBox
            style={{ borderRadius: '12px', border: "1px solid #3C83BC" }}
            fullClick={true}
            inputArray={arrIncludeJobGroup}
            handleNewAdd={(enteredItem) => {
                const newObject = {
                    "value": enteredItem,
                    "label": enteredItem, // Use the label provided in enteredItem
                };

                const alreadyExists = arrIncludeJobGroup.some(item => item.value === enteredItem);
                // if (!alreadyExists) {
                //     setArrIncludeJobGroup([...arrIncludeJobGroup, newObject]);
                // }
            }}

            // tagsStyle={hasIncludeJobGroupError ? 'error' : 'outlined'} // filled
            showNewAdd={true} //hide + button
            // handleTagClick={(jobgroup) => {
            //     setArrIncludeJobGroup([...arrIncludeJobGroup.filter((removeJobgroup) => removeJobgroup.value !== jobgroup.value)]);
            // }}
            options={jobGroupsOptions}
            noOptionsTranslation={t("atc_no_data_in_filter_jobgroup")}
            data_type={'jobgroup_icon'}
        /></td>
    </tr></>
}



export function AlertSettingsAdvancedModal({ modal, setModal, modalTitle, modalMessage, backButtonText, submitButton, handleSubmitSettings, submitButtonText, cancelBtnUrl, backButtonClassName }) {

    const handleCancel = (event) => {
        setModal(false)

    }
    // log.debug(cancelBtnUrl)

    return <>
        {/* <div className="modal-backdrop modal-stack extraModalBackDrop"></div> */}
        <Modal
            className={'extraModal'}
            dialogClassName={'small-dailog small modal-sm'}
            id={'extraModal'}
            onClose={() => { setModal(false); }}
            show={modal}
            backdropClassName={'extraModalBackDrop'}
            contentClassName={'whitebg smallModal'}
            onHide={() => { setModal(false); }}
        >
            <Modal.Header closeButton >
                <Modal.Title><span>{modalTitle}</span></Modal.Title>
            </Modal.Header>
            <ModalBodyScrollIcon className="smallModalBody"><div className="alert alert-warning ">
                <div className="row">
                    <div className="col-md-1"><i style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}><RedWarningSVG /></i></div>
                    <div className="col-md-11 msg">{modalMessage}</div>
                </div>
            </div></ModalBodyScrollIcon>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={(e) => { handleCancel(e); }} className={backButtonClassName}>{backButtonText}</Button>
                <Button variant="primary" className={'finalSubmitSettings greenPrimary'} style={{ marginLeft: "0px" }} onClick={handleSubmitSettings} >{submitButtonText}</Button>
            </Modal.Footer>
        </Modal>
    </>
}


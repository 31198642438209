/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const AddIcon = ({ color = "#3C83BC" }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.2646 9.14286H9.40751V16H7.12179V9.14286H0.264648V6.85714H7.12179V0H9.40751V6.85714H16.2646V9.14286Z" fill={color}/>
</svg>


  );
};

AddIcon.propTypes = {
  color: PropTypes.string,
};

import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as IconAction13 } from "../../assets/images/login/iconaction-13.svg";
import { ReactComponent as IconAction14 } from "../../assets/images/login/iconaction-14.svg"; 
import i18n from "i18next";
import { useTranslation } from 'react-i18next';

const Language = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownReturn, setdropdownReturn] = useState(i18n.language === 'en' ? true : false);
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  function UpdateLanguage(v) {
        i18n.changeLanguage(v);
        setdropdownReturn(v === 'en' ? true : false);
        setIsOpen(false);
  }
  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`language-selector-wrapper ${isOpen ? 'show' : ''}`} ref={dropdownRef}>
      <div className="language-selector" onClick={toggleDropdown}>
        <IconAction13 className="google" />
        <div className="language">{dropdownReturn ? t('settings_language_form_english_checkbok_label'):t('settings_language_form_german_checkbok_label')}</div>
        <IconAction14 className="icon-action-2" />
      </div>
      {isOpen && (
        <div className="dropdown-content">
          {dropdownReturn ? (
            <>
          <div className="language-option" onClick={() => UpdateLanguage('de')}>{t('settings_language_form_german_checkbok_label')}</div>
          </>):(<>
            <div className="language-option" onClick={() => UpdateLanguage('en')}>{t('settings_language_form_english_checkbok_label')}</div>
          </>)}

        </div>
      )}
    </div>
  );
};

export default Language;

import React, { useState, useEffect } from 'react';
import Carousel from "./inc/Carousel";
import Footer from "./inc/Footer";
import Language from "./inc/LoginLanguage";
import { getLoginData } from '../services';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import "../styles/Login.scss"; 
import Loader from './blocks/common/loader-block';

const ForgotPassword = () => {

  const [hasLoader,setHasLoader]= useState(false)

  const languageSuffix = i18n.language === 'en' ? '_EN' : '_DE';
  const [items, setItems] = useState([]);
  const { t } = useTranslation();

  const [emailForgotPassword, setEmailForgotPassword] = useState('');
  const [emailForgotPasswordError, setEmailForgotPasswordError] = useState('');
  const [emailForgotPasswordError2, setEmailForgotPasswordError2] = useState('');
  const [forgotPasswordPage, setForgotPasswordPage] = useState(true);
  const [changePasswordPage, setChangePasswordPage] = useState(false);
  const [changePasswordPageInvalidLink, setChangePasswordPageInvalidLink] = useState('');
  const [changePassword, setChangePassword] = useState('');
  const [changePasswordConfirm, setChangePasswordConfirm] = useState('');
  const [changePasswordError, setChangePasswordError] = useState('');
  const [changePasswordConfirmError, setChangePasswordConfirmError] = useState('');
  const [changePasswordConfirmError2, setChangePasswordConfirmError2] = useState('');
  const [changePasswordSuccess, setChangePasswordSuccess] = useState('');

  const [showLogo, setShowLogo] = useState(false);
  const searchparams = new URLSearchParams(window.location.search);
  const step = searchparams.get('step');
  const userEmail = searchparams.get('user');
  const verify = searchparams.get('verify');
  const [forgotPasswordMailPage, setForgotPasswordMailPage] = useState(false);


  useEffect(() => {
    if (step === '2') {
      setShowLogo(true);
      let postDataProtFolioTable = { CheckPasswordChangeAPI: 1, verify: verify };
      getLoginData(postDataProtFolioTable).then((portfolioDataRes) => {
        setShowLogo(false);
        if (portfolioDataRes.status === 'success') {
            if (portfolioDataRes.count === 0) {
                setForgotPasswordMailPage(true);
                setForgotPasswordPage(false);
                setChangePasswordPage(false);
                setChangePasswordPageInvalidLink(t('forget_password_step2_link_notvalid_text'));

            }else if (portfolioDataRes.count === 1){
                setForgotPasswordMailPage(false);
                setForgotPasswordPage(false);
                setChangePasswordPage(true);
            }else{
                setForgotPasswordMailPage(false);
                setForgotPasswordPage(true);
                setChangePasswordPage(false);
            }
        } 
      });
    }
  }, [step]);

  useEffect(() => {
    getLoginData({ login_carousel: 1, requestFromOld: 1 })
      .then(
        (result) => {
          const filteredItems = result
            .filter(item => item.Status === "Enabled")
            .map(item => ({
              imgSrc: `https://tools.jobspreader.com/manage/images/tools-uploaded/teaser/` + item[`ImageURL${languageSuffix}`],
              mainHeading: item[`HeadLine${languageSuffix}`],
              subHeading: item[`SubLine${languageSuffix}`],
              link: item[`Link${languageSuffix}`],
              newTag: item.NewTag === "Enabled",
              startDate: item.StartDate
            }));
          setItems(filteredItems);
        },
      )
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const ForgotPasswordHandler = (postDataProtFolioTable) => {
    setShowLogo(true);
    getLoginData(postDataProtFolioTable).then((portfolioDataRes) => {
      setShowLogo(false);
      if (portfolioDataRes.status === 'success') {
        setForgotPasswordMailPage(true);
        setForgotPasswordPage(false);
      } else if (portfolioDataRes.status === 'fail') {
        setEmailForgotPasswordError2(t('forget_password_step1_email_notfound_message'));
      } else {
        console.log("Unexpected response received");
      }


    });
  };

  const handleLoginPage = () => {
    setShowLogo(true);
    window.location.href = 'login';
  }

  const handleForgotPasswordMailPage = () => {
    ForgotPasswordHandler('');
    setEmailForgotPasswordError('');
    if (emailForgotPassword !== '') {
      if (!validateEmail(emailForgotPassword)) {
        setEmailForgotPasswordError(t('header_index_login_form_invalid_email_message'));
        return; // Do not proceed if the email is invalid
      }

      let postDataProtFolioTable = { forgotPasswordAPI: 1, email: emailForgotPassword };
      ForgotPasswordHandler(postDataProtFolioTable)
    } else {
      const emptyErr = t('header_index_login_form_required_message');

      setEmailForgotPasswordError(emptyErr);

    }
  }

  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password)
    };
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setChangePassword(newPassword);
  
    const validationResults = validatePassword(newPassword);
    setChangePasswordError(validationResults);
  };

  const passwordValidationCriteria = [
    { key: 'length', text: t('login_create_pass_min_8') },
    { key: 'uppercase', text: t('login_create_pass_uppercase') },
    { key: 'lowercase', text: t('login_create_pass_lowercase') },
    { key: 'number', text: t('login_create_pass_number') }
  ];

  const handleCreatePassword = (e) => {
    setShowLogo(true);
    setChangePasswordConfirmError2('');
    setChangePasswordConfirmError('');

    if (changePassword !== '' && changePasswordConfirm !== '') {
        if(changePassword !== changePasswordConfirm){
            setTimeout(() => {
                setShowLogo(false);
              }, 500);
        setChangePasswordConfirmError(t('password_match_error_msg'));
        }else{
        
        let postDataProtFolioTable = { SetPasswordAPI: 1, verify: verify ,txtNewPassword:changePassword,txtConfirmNewPassword:changePasswordConfirm};
        getLoginData(postDataProtFolioTable).then((portfolioDataRes) => {
            setShowLogo(false);
            if (portfolioDataRes.status === 'success') {
                setChangePasswordSuccess('1');
            } else if(portfolioDataRes.status === 'fail') {
            if(portfolioDataRes.code === 1){
                setChangePasswordConfirmError(t('password_match_error_msg'));
            }else{
                setForgotPasswordMailPage(true);
                setForgotPasswordPage(false);
                setChangePasswordPage(false);
                setChangePasswordPageInvalidLink(t('forget_password_step2_link_notvalid_text'));
            }
            }
        });

        }
    }else{
        setTimeout(() => {
            setShowLogo(false);
          }, 500);
          const emptyErr = t('header_index_login_form_required_message');
          if (changePassword === '') {
            setChangePasswordConfirmError2(emptyErr);
          }
          if (changePasswordConfirm === '') {
            setChangePasswordConfirmError(emptyErr);
          }
    }
  };
  
  return (
    <>
    <div className='pageWrapper'>
      
    <div className="login">
    {hasLoader?<Loader loadingTimer={0} style={{ width: "120px", height: "120px" }} />:<>
      {showLogo && (
        <div className="logo-container">
          <img
            className="logo"
            src={require("../assets/images/login/logo-1.svg").default}
            alt="Logo"
          />
        </div>
      )}
      <Carousel items={items} />
      <div className={`welcome-container`}>
        {/* <GoogleLogin onSuccess={googleResponseMessage} onError={googleErrorMessage} /> */}
        {/* Forgot password */}
        {forgotPasswordPage && (
        <div className={`forgot-password`}>
          <div className="container">
            <div className="forgot-password-header"></div>
            <div className="login-components">
              <div className="language-selector" onClick={handleLoginPage}>
                <img className="arrow-back" src={require("../assets/images/login/arrow-back.svg").default} />
                <div className="text-wrapper cursor-pointer" >{t('forgot_password_back_to_login')}</div>
              </div>
            </div>
            <div className="container-wrapper">
              <div className="div">
                <div className="reset-link-message">
                  <div className="instructions-title">{t('forgot_password_head')}</div>
                  <p className="instructions">{t('forgot_password_sub_head')}</p>
                </div>
                <div className="div">
                  <div className="div-2">
                    <div className="form-field-container">
                      <div className="text-wrapper">{t('login_email_address')}*</div>
                      <div className={`input-container ${emailForgotPasswordError ? 'error-border' : ''}`}>
                        <input  autoComplete="off"   type="email" className="email-input" placeholder={t('login_email_address_placeholder')} value={emailForgotPassword} onChange={(e) => setEmailForgotPassword(e.target.value)} />
                        <img className="img" src={require("../assets/images/login/iconaction-10.svg").default} />
                      </div>
                      {emailForgotPasswordError && <span className="error-message error-msg" dangerouslySetInnerHTML={{ __html: emailForgotPasswordError }} />}
                      {emailForgotPasswordError2 && <span className="error-message noerror-msg" dangerouslySetInnerHTML={{ __html: emailForgotPasswordError2 }} />}
                    </div>
                  </div>
                </div>

                <div className="div">
                  <button className="submit-button-text-wrapper" onClick={(e) => { handleForgotPasswordMailPage() }}><span className="submit-button-text">{t('forgot_password_sign_in_btn')}</span></button>
                </div>
                <div className="forgot-password-bottom"></div>
              </div>
            </div>
          </div>
        </div>
        )}

        {/* Forgot password Mail Sent*/}
        {forgotPasswordMailPage && (
        <div className={`forgot-password`}>
          <div className="container">
            <div className="forgot-password-header"></div>
            <div className="login-components">
              <div className="language-selector">
                <img className="arrow-back" src={require("../assets/images/login/arrow-back.svg").default} />
                <div className="text-wrapper cursor-pointer" onClick={handleLoginPage}>{t('forgot_password_back_to_login')}</div>
              </div>
            </div>
            <div className="container-wrapper">
              <div className="div">
                <div className="reset-link-message">
                  <div className="instructions-title">{changePasswordPageInvalidLink !== '' ? (<span dangerouslySetInnerHTML={{ __html: changePasswordPageInvalidLink }}></span>) : (<>{t('forgot_password_mail_head')}</>)}</div>
                  {changePasswordPageInvalidLink === '' && (<p className="instructions">{t('forgot_password_mail_sub_head')}</p>)}

                </div>
                <div className="div">
                  <div className="div-2">
                    {changePasswordPageInvalidLink === '' && (
                      <div className="form-field-container">

                        <div className="text-wrapper">{t('login_email_address')}*</div>
                        <div className={`input-container ${emailForgotPasswordError ? 'error-border' : ''}`} >
                          <input  autoComplete="off" type="email" className="email-input" placeholder={t('login_email_address_placeholder')} value={emailForgotPassword} onChange={(e) => setEmailForgotPassword(e.target.value)} />
                          <img className="img" src={require("../assets/images/login/iconaction-10.svg").default} />
                        </div>
                        {emailForgotPasswordError && <span className="error-message error-msg" dangerouslySetInnerHTML={{ __html: emailForgotPasswordError }} />}
                      </div>)}
                  </div>
                </div>

                <div className="div">
                  {changePasswordPageInvalidLink === '' && (
                    <button className="submit-button-text-wrapper" onClick={(e) => { handleForgotPasswordMailPage() }}><span className="submit-button-text">{t('forgot_password_mail_sign_in_btn')}</span></button>
                  )}
                </div>
                <div className="forgot-password-bottom"></div>
              </div>
            </div>
          </div>
        </div>
        )}

        {/* Change password */}
        {changePasswordPage && (
          <div className="forgot-password">
            <div className="container">
              <div className="forgot-password-header"></div>
              <div className="login-components">
                <div className="language-selector">
                  <img className="arrow-back" src={require("../assets/images/login/arrow-back.svg").default} alt="Arrow Back" />
                  <div className="text-wrapper cursor-pointer" onClick={handleLoginPage}>{t('forgot_password_back_to_login')}</div>
                </div>
              </div>
              <div className="container-wrapper">
                <div className="div">
                  <div className="reset-link-message">
                    <div className="instructions-title">{t('change_password_mail_head')}</div>
                    {changePasswordSuccess && (<p className="instructions">{t('forget_password_change_success_message')}</p>)}

                  </div>
                  <div className="div">
                    <div className="div-2">
                    {!changePasswordSuccess && (
                      <div className="form-field-container">
                        <div className='change-password-container'>
                        <div className="text-wrapper">{t('login_password')}*</div>
                        <div className="input-container">
                          <input autoComplete="off" type="password" className="email-input" placeholder={t('login_password_placeholder')} value={changePassword} onChange={handlePasswordChange} />
                          <img className="img" src={require("../assets/images/login/iconaction-11.svg").default} />
                        </div>
                        {changePassword && changePassword.length > 0 && (
                          <>
                          {passwordValidationCriteria.map((criteria, index) => (
                            <p key={index} className="change-password-error">
                              <img
                                className="icon"
                                src={require(changePasswordError[criteria.key] ? '../assets/images/login/set-password-tick.svg' : '../assets/images/login/set-password-cross.svg').default}
                                alt="icon"
                              />
                              <span className={  changePasswordError[criteria.key] ?'noerror-msg':'error-msg'}> {criteria.text}</span>
                            </p>
                          ))}
                        </>
                        )}
                        {changePasswordConfirmError2 && <span className="error-message error-msg">{changePasswordConfirmError2}</span>}

                        </div>
                        <div className="text-wrapper">{t('login_password_confirm')}*</div>
                        <div className="input-container">
                          <input autoComplete="off" type="password" className="email-input" placeholder={t('login_password_confirm')} value={changePasswordConfirm} onChange={(e) => setChangePasswordConfirm(e.target.value)} />
                          <img className="img" src={require("../assets/images/login/iconaction-11.svg").default} />
                        </div>
                        {changePasswordConfirmError && <span className="error-message error-msg">{changePasswordConfirmError}</span>}
                      </div>
                       )}
                    </div>
                  </div>
                  <div className="div">
                    <button className="submit-button-text-wrapper" onClick={(e) => { handleCreatePassword() }}>
                      <span className="submit-button-text">{t('forgot_password_mail_sign_in_btn')}</span>
                    </button>
                  </div>
                  <div className="forgot-password-bottom"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Language />
      </div>
      <Footer /></>}
    </div>
    </div>
    </>
  );
};

export default ForgotPassword;

import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import "../../../styles/figma-buttons.scss";

/**
 * Renders a back-to-top button on the footer.
 * Clicking the button scrolls the window to the top with a smooth animation.
 * The button is only shown if the document height is greater than twice of the window height.
 *
 * @returns {JSX.Element|null} The back-to-top button component.
 */
export default function BackToTop() {
  const { t } = useTranslation();
  const [showBtn, setShowBtn] = useState(false);
  const showBtnRef = useRef(showBtn);

  useEffect(() => {
    showBtnRef.current = showBtn;
  }, [showBtn]);

  useEffect(() => {
    let timeoutObserver = null;
    /**
     * Resize observer used to track changes in the size of the document element.
     * Alternatively window.scroll and window.resize could have been used
     */
    const resizeObserver = new ResizeObserver(() => {
      if (timeoutObserver) clearTimeout(timeoutObserver);
      timeoutObserver = setTimeout(() => {
        const shouldShowBtn = document.documentElement.scrollHeight / 2 > window.innerHeight;
        if (shouldShowBtn !== showBtnRef.current) {
          setShowBtn(shouldShowBtn);
        } else if (!shouldShowBtn && showBtnRef.current) {
          setShowBtn(shouldShowBtn);
        }
      }, 100);
    });
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  /**
   * Scrolls the window to the top with a smooth animation.
   * Also disables the thead transform property is sticky is applied
   */
  const scrollToTop = () => {
    let tableHead = document.querySelector("thead"); //a class to be added here to indicate table head is sticky
    if (tableHead) {
      tableHead.classList.add("remove_transitions");
      setTimeout(() => {
        tableHead.classList.remove("remove_transitions");
      }, 1000);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    showBtn && (
      <button id="footer_back_to_top" onClick={scrollToTop} className="figma-btn fg-large fg-secondary">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 16V3.825L1.9 9.425L0.5 8L8.5 0L16.5 8L15.1 9.425L9.5 3.825V16H7.5Z" fill="#3C83BC"></path>
        </svg>
        <span>{t("back_to_top")}</span>
      </button>
    )
  );
}

import { ReactComponent as Logo } from "../../assets/images/login/logo-footer.svg"; 
import { ReactComponent as PrivacyPolicy } from "../../assets/images/login/privacy-policy-separator.svg"; 
import { ReactComponent as LinkedIn  } from "../../assets/images/login/iconmonstr-linkedin-3-1.svg"; 
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t } = useTranslation();

  return (
    <div className="login-footer">
    <div className="footer-container">
      <div className="links-container">
        <Logo className="logo-image" />
        <div className="policies-container">
        <a 
              href="https://wollmilchsau.de/datenschutzerklaerung/" 
              target="_blank" 
              rel="noopener noreferrer"  className="privacy-policy-link">
            <PrivacyPolicy className="privacy-policy" />
            <div className="privacy-policy-text" >{t('login_footer_privacy_policy')}</div>
          </a>
          <a href="https://wollmilchsau.de/datenschutzerklaerung/" target="_blank" 
              rel="noopener noreferrer" className="imprint-link">
            <div className="imprint-text">{t('login_footer_imprint')}</div>
            <PrivacyPolicy className="imprint-separator"/>
          </a>
          <a href="https://www.linkedin.com/company/wollmilchsau" target="_blank" rel="noopener noreferrer"  className="social-media">
            <div className="social-media-text">{t('login_footer_socialmedia')}</div>
            <LinkedIn  className="iconmonstr-linkedin" />
          </a>
        </div>
      </div>
      <p className="footer-text">
        <span className="text-wrapper" dangerouslySetInnerHTML={{ __html: t('login_footer_address')	 }} /> 
        <span className="span">{t('login_footer_email')}</span>
        <span className="text-wrapper"><br></br>{t('login_footer_rights_reserved')}</span>
      </p>
    </div>
  </div>
  );
};

export default Footer;

import log from "loglevel";
import { useTranslation } from "react-i18next";
import { Session } from 'bc-react-session';
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ReactComponent as IconAddJobGroupSVG } from "../../../assets/icons/IconAddJobGroup.svg";
import { ReactComponent as IconAddJobGroupDisabledSVG } from "../../../assets/icons/IconAddJobGroupDisabled.svg";

import { RouteMap, SettingRoutes } from "../../../config/RouteMap";
import { AddIcon } from "../iconClasses/AddIcon";
import ModalBodyScrollIcon from "../common/scroll-down";
import { DeleteExternalRecipientModal } from "./settings_tab_notifications";
import { useWMSContext } from "../../../wmsContext";


export default function SynonymsNotifications({ arrUsers, settingsData, isManual = true, setArrUsers, ManuallyCreatedSynonyms, setManuallyCreatedSynonyms, AutoCreatedSynonyms, setAutoCreatedSynonyms }) {

    const { t } = useTranslation();
    const { payload } = Session.get('login');
    const UserSession = payload;
    let arrDataUsers = [];
    const { isModifiedGlobal, setIsModifiedGlobal, setSaveSettingsModifiedModal, saveSettingsModifiedModal } = useWMSContext()
    const [autoCreateEnabled, setAutoCreateEnabled] = useState(false);
    const [modal, setModal] = useState(false);
    const [RecipientToRemove, setRecipientToRemove] = useState('')
    if (arrUsers !== undefined) {
        if (settingsData.defaultAdmin !== undefined) {
            Object.keys(settingsData.defaultAdmin).map((item, index) => {
                arrDataUsers.push(settingsData.defaultAdmin[item]);
            })
        }
        Object.keys(arrUsers).map((item, index) => {
            arrDataUsers.push(arrUsers[item]);
        })
    }
    arrDataUsers = arrDataUsers.sort(function (a, b) { return String(a.name).localeCompare(String(b.name), 'de', { sensitivity: "base" }) })

    const [externalUserManual, setExternalUserManual] = useState("");
    const [externalUserAuto, setExternalUserAuto] = useState("")
    const [showBlock, setshowBlock] = useState(true);
    const handleshowBlock = () => {
        setshowBlock(!showBlock);
    };
    const handleManuallyCreatedSynonyms = (event) => {
        // Main Radio Button Events
        if (event.target.id === 'ManuallyCreatedSynonymsEnabledAllUsers' || event.target.id === "ManuallyCreatedSynonymsEnabledForIndividual" || event.target.id === "ManuallyCreatedSynonymsDisabledAllUsers") {
            setManuallyCreatedSynonyms({
                ...ManuallyCreatedSynonyms,
                EnabledFor: event.target.value,
            });
            setIsModifiedGlobal(true)
        }
        // Ticket Owner Only
        if (event.target.id === 'ManuallyCreatedSynonymsOwnerOnly') {
            setManuallyCreatedSynonyms({
                ...ManuallyCreatedSynonyms,
                OwnerOnly: (event.target.checked === true ? 'Enabled' : 'Disabled'),
            });
            setIsModifiedGlobal(true)
        }
        // Assigned JG
        if (event.target.id === 'ManuallyCreatedSynonymsAssignedJGs') {
            setManuallyCreatedSynonyms({
                ...ManuallyCreatedSynonyms,
                AssignedJGs: (event.target.checked === true ? 'Enabled' : 'Disabled'),
            });
            setIsModifiedGlobal(true)
        }
        // Single User (All)
        if (event.target.id === 'ManuallyCreatedSynonymsSingleUsers') {
            if (event.target.checked === true) {
                let tempUsers = []
                arrUsers !== undefined && arrDataUsers.map((element, index) => {
                    tempUsers.push(parseInt(element.ID))
                })
                let tempExt = []
                ManuallyCreatedSynonyms.ExternalRecipient !== undefined && ManuallyCreatedSynonyms.ExternalRecipient.map((element, index) => {
                    tempExt.push({ mail: element.mail, status: 'Enabled' })
                })
                // log.debug(tempUsers)
                setManuallyCreatedSynonyms({
                    ...ManuallyCreatedSynonyms,
                    InternalSubUsers: tempUsers,
                    ExternalRecipient: tempExt,
                    SingleUsers: "Enabled"
                });
                setIsModifiedGlobal(true)
            }
            if (event.target.checked === false) {
                let tempUsers = []
                let tempExt = []
                ManuallyCreatedSynonyms.ExternalRecipient !== undefined && ManuallyCreatedSynonyms.ExternalRecipient.map((element, index) => {
                    tempExt.push({ mail: element.mail, status: 'Disabled' })
                })
                // log.debug(tempUsers)
                setManuallyCreatedSynonyms({
                    ...ManuallyCreatedSynonyms,
                    InternalSubUsers: tempUsers,
                    ExternalRecipient: tempExt,
                    SingleUsers: "Disabled"
                });
                setIsModifiedGlobal(true)
            }
        }
        // Single Users Internal
        if (event.target.id.startsWith("ManuallyCreatedSynonymsSingleUsersInternal-")) {
            if (event.target.checked === true) {

                let tempInternal = []
                ManuallyCreatedSynonyms.InternalSubUsers !== undefined && ManuallyCreatedSynonyms.InternalSubUsers.map((element, index) => {
                    tempInternal.push(parseInt(element))
                })
                tempInternal.push(parseInt(event.target.getAttribute('data-subuserid')))
                let enableSingleUser = arrDataUsers.length + ManuallyCreatedSynonyms.ExternalRecipient.length === tempInternal.length
                log.debug(arrDataUsers)
                var checkedItems = document.querySelectorAll(".ManuallyCreatedSynonymsTable input:checked");
                var uncheckedItems = document.querySelectorAll(".ManuallyCreatedSynonymsTable input");
                setManuallyCreatedSynonyms({
                    ...ManuallyCreatedSynonyms,
                    InternalSubUsers: tempInternal,
                    SingleUsers: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
                setIsModifiedGlobal(true)
            }
            if (event.target.checked === false) {

                let tempInternal = []
                ManuallyCreatedSynonyms.InternalSubUsers !== undefined && ManuallyCreatedSynonyms.InternalSubUsers.map((element, index) => {
                    if (parseInt(event.target.getAttribute('data-subuserid')) !== parseInt(element)) {
                        tempInternal.push(parseInt(element))
                    }
                })
                var checkedItems = document.querySelectorAll(".ManuallyCreatedSynonymsTable input:checked");
                var uncheckedItems = document.querySelectorAll(".ManuallyCreatedSynonymsTable input");
                setManuallyCreatedSynonyms({
                    ...ManuallyCreatedSynonyms,
                    InternalSubUsers: tempInternal,
                    SingleUsers: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
                setIsModifiedGlobal(true)
            }

        }
        if (event.target.id.startsWith("ManuallyCreatedSynonymsSingleUsersExternal-")) {
            if (event.target.checked === true) {
                let tempExt = []
                let enableExtLength = 0;
                ManuallyCreatedSynonyms.ExternalRecipient !== undefined && ManuallyCreatedSynonyms.ExternalRecipient.map((element, index) => {
                    if (element.mail === event.target.value) {
                        tempExt.push({ mail: element.mail, status: 'Enabled' })
                        enableExtLength++;
                    } else {
                        tempExt.push({ mail: element.mail, status: element.status })
                        if (element.status == 'Enabled') enableExtLength++;
                    }
                })
                var checkedItems = document.querySelectorAll(".ManuallyCreatedSynonymsTable input:checked");
                var uncheckedItems = document.querySelectorAll(".ManuallyCreatedSynonymsTable input");
                setManuallyCreatedSynonyms({
                    ...ManuallyCreatedSynonyms,
                    ExternalRecipient: tempExt,
                    SingleUsers: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
                setIsModifiedGlobal(true)
            }
            if (event.target.checked === false) {

                let tempExt = []
                let enableExtLength = 0;
                ManuallyCreatedSynonyms.ExternalRecipient !== undefined && ManuallyCreatedSynonyms.ExternalRecipient.map((element, index) => {
                    if (element.mail === event.target.value) {
                        tempExt.push({ mail: element.mail, status: 'Disabled' })
                        enableExtLength++;
                    } else {
                        tempExt.push({ mail: element.mail, status: element.status })
                        if (element.status == 'Enabled') enableExtLength++;
                    }
                })
                var checkedItems = document.querySelectorAll(".ManuallyCreatedSynonymsTable input:checked");
                var uncheckedItems = document.querySelectorAll(".ManuallyCreatedSynonymsTable input");
                setManuallyCreatedSynonyms({
                    ...ManuallyCreatedSynonyms,
                    ExternalRecipient: tempExt,
                    SingleUsers: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
                setIsModifiedGlobal(true)


            }

        }
        // setManuallyCreatedSynonyms(ManuallyCreatedSynonyms);
    };


    const handleAutoCreatedSynonyms = (event) => {
        // Main Radio Button Events
        if (event.target.id === 'AutoCreatedSynonymsEnabledAllUsers' || event.target.id === "AutoCreatedSynonymsEnabledForIndividual" || event.target.id === "AutoCreatedSynonymsDisabledAllUsers") {
            setAutoCreatedSynonyms({
                ...AutoCreatedSynonyms,
                EnabledFor: event.target.value,
            });
            setIsModifiedGlobal(true)
        }
        // Ticket Owner Only
        if (event.target.id === 'AutoCreatedSynonymsOwnerOnly') {
            setAutoCreatedSynonyms({
                ...AutoCreatedSynonyms,
                OwnerOnly: (event.target.checked === true ? 'Enabled' : 'Disabled'),
            });
            setIsModifiedGlobal(true)
        }
        // Assigned JG
        if (event.target.id === 'AutoCreatedSynonymsAssignedJGs') {
            setAutoCreatedSynonyms({
                ...AutoCreatedSynonyms,
                AssignedJGs: (event.target.checked === true ? 'Enabled' : 'Disabled'),
            });
            setIsModifiedGlobal(true)
        }
        // Single User (All)
        if (event.target.id === 'AutoCreatedSynonymsSingleUsers') {
            if (event.target.checked === true) {
                let tempUsers = []
                arrUsers !== undefined && arrDataUsers.map((element, index) => {
                    tempUsers.push(parseInt(element.ID))
                })
                let tempExt = []
                AutoCreatedSynonyms.ExternalRecipient !== undefined && AutoCreatedSynonyms.ExternalRecipient.map((element, index) => {
                    tempExt.push({ mail: element.mail, status: 'Enabled' })
                })
                // log.debug(tempUsers)
                setAutoCreatedSynonyms({
                    ...AutoCreatedSynonyms,
                    InternalSubUsers: tempUsers,
                    ExternalRecipient: tempExt,
                    SingleUsers: "Enabled"
                });
                setIsModifiedGlobal(true)
            }
            if (event.target.checked === false) {
                let tempUsers = []
                let tempExt = []
                AutoCreatedSynonyms.ExternalRecipient !== undefined && AutoCreatedSynonyms.ExternalRecipient.map((element, index) => {
                    tempExt.push({ mail: element.mail, status: 'Disabled' })
                })
                // log.debug(tempUsers)
                setAutoCreatedSynonyms({
                    ...AutoCreatedSynonyms,
                    InternalSubUsers: tempUsers,
                    ExternalRecipient: tempExt,
                    SingleUsers: "Disabled"
                });
                setIsModifiedGlobal(true)
            }
        }
        // Single Users Internal
        if (event.target.id.startsWith("AutoCreatedSynonymsSingleUsersInternal-")) {
            if (event.target.checked === true) {

                let tempInternal = []
                AutoCreatedSynonyms.InternalSubUsers !== undefined && AutoCreatedSynonyms.InternalSubUsers.map((element, index) => {
                    tempInternal.push(parseInt(element))
                })
                tempInternal.push(parseInt(event.target.getAttribute('data-subuserid')))
                let enableSingleUser = arrDataUsers.length + AutoCreatedSynonyms.ExternalRecipient.length === tempInternal.length
                log.debug(arrDataUsers)
                var checkedItems = document.querySelectorAll(".AutoCreatedSynonymsTable input:checked");
                var uncheckedItems = document.querySelectorAll(".AutoCreatedSynonymsTable input");
                setAutoCreatedSynonyms({
                    ...AutoCreatedSynonyms,
                    InternalSubUsers: tempInternal,
                    SingleUsers: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
                setIsModifiedGlobal(true)
            }
            if (event.target.checked === false) {

                let tempInternal = []
                AutoCreatedSynonyms.InternalSubUsers !== undefined && AutoCreatedSynonyms.InternalSubUsers.map((element, index) => {
                    if (parseInt(event.target.getAttribute('data-subuserid')) !== parseInt(element)) {
                        tempInternal.push(parseInt(element))
                    }
                })
                var checkedItems = document.querySelectorAll(".AutoCreatedSynonymsTable input:checked");
                var uncheckedItems = document.querySelectorAll(".AutoCreatedSynonymsTable input");
                setAutoCreatedSynonyms({
                    ...AutoCreatedSynonyms,
                    InternalSubUsers: tempInternal,
                    SingleUsers: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
                setIsModifiedGlobal(true)
            }

        }
        if (event.target.id.startsWith("AutoCreatedSynonymsSingleUsersExternal-")) {
            if (event.target.checked === true) {
                let tempExt = []
                let enableExtLength = 0;
                AutoCreatedSynonyms.ExternalRecipient !== undefined && AutoCreatedSynonyms.ExternalRecipient.map((element, index) => {
                    if (element.mail === event.target.value) {
                        tempExt.push({ mail: element.mail, status: 'Enabled' })
                        enableExtLength++;
                    } else {
                        tempExt.push({ mail: element.mail, status: element.status })
                        if (element.status == 'Enabled') enableExtLength++;
                    }
                })
                var checkedItems = document.querySelectorAll(".AutoCreatedSynonymsTable input:checked");
                var uncheckedItems = document.querySelectorAll(".AutoCreatedSynonymsTable input");
                setAutoCreatedSynonyms({
                    ...AutoCreatedSynonyms,
                    ExternalRecipient: tempExt,
                    SingleUsers: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
                setIsModifiedGlobal(true)
            }
            if (event.target.checked === false) {

                let tempExt = []
                let enableExtLength = 0;
                AutoCreatedSynonyms.ExternalRecipient !== undefined && AutoCreatedSynonyms.ExternalRecipient.map((element, index) => {
                    if (element.mail === event.target.value) {
                        tempExt.push({ mail: element.mail, status: 'Disabled' })
                        enableExtLength++;
                    } else {
                        tempExt.push({ mail: element.mail, status: element.status })
                        if (element.status == 'Enabled') enableExtLength++;
                    }
                })
                var checkedItems = document.querySelectorAll(".AutoCreatedSynonymsTable input:checked");
                var uncheckedItems = document.querySelectorAll(".AutoCreatedSynonymsTable input");
                setAutoCreatedSynonyms({
                    ...AutoCreatedSynonyms,
                    ExternalRecipient: tempExt,
                    SingleUsers: checkedItems.length === uncheckedItems.length ? "Enabled" : "Disabled"
                });
                setIsModifiedGlobal(true)

            }

        }
    };
    const handleAddExternalReceipientManual = () => {
        let tempExt = []
        ManuallyCreatedSynonyms.ExternalRecipient !== undefined && ManuallyCreatedSynonyms.ExternalRecipient.map((element, index) => {
            tempExt.push({ mail: element.mail, status: element.status })
        })
        tempExt.push({ mail: externalUserManual, status: 'Enabled' })
        setManuallyCreatedSynonyms({
            ...ManuallyCreatedSynonyms,
            ExternalRecipient: tempExt
        });
        setIsModifiedGlobal(true)
    }
    const handleRemoveExternalReceipientManual = (event, userMail) => {
        let tempExt = []
        ManuallyCreatedSynonyms.ExternalRecipient !== undefined && ManuallyCreatedSynonyms.ExternalRecipient.map((element, index) => {
            if (userMail !== element.mail) {
                tempExt.push({ mail: element.mail, status: element.status })
            }
        })
        setManuallyCreatedSynonyms({
            ...ManuallyCreatedSynonyms,
            ExternalRecipient: tempExt
        });
        setIsModifiedGlobal(true)
        setModal(false);
    }
    const handleAddExternalReceipientAuto = (event) => {
        let tempExt = []
        AutoCreatedSynonyms.ExternalRecipient !== undefined && AutoCreatedSynonyms.ExternalRecipient.map((element, index) => {
            tempExt.push({ mail: element.mail, status: element.status })
        })
        tempExt.push({ mail: externalUserAuto, status: 'Enabled' })
        setAutoCreatedSynonyms({
            ...AutoCreatedSynonyms,
            ExternalRecipient: tempExt
        });
        setIsModifiedGlobal(true)
    }
    const handleRemoveExternalReceipientAuto = (event, userMail) => {
        let tempExt = []
        AutoCreatedSynonyms.ExternalRecipient !== undefined && AutoCreatedSynonyms.ExternalRecipient.map((element, index) => {
            if (userMail !== element.mail) {
                tempExt.push({ mail: element.mail, status: element.status })
            }
        })
        setAutoCreatedSynonyms({
            ...AutoCreatedSynonyms,
            ExternalRecipient: tempExt
        });
        setIsModifiedGlobal(true)
    }
    const removeExternalReceipient = (event, recipientMail, isManual) => {
        setRecipientToRemove(recipientMail);
        setModal(true);
        return false;
    }



    arrDataUsers = arrDataUsers.sort(function (a, b) { return String(a.name).localeCompare(String(b.name), 'de', { sensitivity: "base" }) })
    useEffect(() => {
        log.debug('SettingsNoti ', settingsData)

        if (settingsData !== undefined) {
            setArrUsers(settingsData.arrSubAccounts)
            if (isManual) {
                setManuallyCreatedSynonyms(settingsData.ManualCreatedSynonymsNotification);
            } else {
                log.debug(settingsData.AutoCreatedSynonymsNotification)
                setAutoCreatedSynonyms(settingsData.AutoCreatedSynonymsNotification);
            }
            setAutoCreateEnabled((settingsData.autoCreateEnabled !== undefined && parseInt(settingsData.autoCreateEnabled) === 1));

            // defaultNewsletterOptions = settingsData.arrNewsletterNotificationPreference.NewsletterCC;

        }

        // eslint-disable-next-line
    }, [settingsData]);


    // console.log('getSettingsAPI', autoCreateEnabled)
    return <>
        <div className="synonyms_notifications mb-3">

            <div className={"frame "}>
                <div className="div">
                    <p className="text-wrapper">{isManual ? t('syn_notif_manual_head') : t('syn_notif_auto_head')}</p>
                    <div onClick={handleshowBlock} >
                        <span className="up-arrow" style={{ display: !showBlock ? 'none' : '', cursor: 'pointer' }}>
                            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.2 5.4978L9.4 9.6978L1 9.6978L5.2 5.4978Z" fill="#1C2D3B" />
                            </svg>
                        </span>
                        <span className="down-arrow" style={{ display: !showBlock ? '' : 'none', cursor: 'pointer' }}>
                            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.2 11.6978L1 7.4978L9.4 7.4978L5.2 11.6978Z" fill="#1C2D3B" />
                            </svg>
                        </span>

                    </div>
                </div>
                {showBlock ? <><p className={"p " + (isManual?'':(!autoCreateEnabled ? 'disable-button' : ''))} >{isManual ? t('syn_notif_manual_head_question') : t('syn_notif_auto_head_question')}</p>
                    <div className="div" >
                        {isManual ? <SelectionSection autoCreateEnabled={autoCreateEnabled} arrSynonyms={ManuallyCreatedSynonyms} arrDataUsers={arrDataUsers} arrUsers={arrUsers} isManual={isManual} handleSynonyms={handleManuallyCreatedSynonyms} removeExternalReceipient={removeExternalReceipient} /> : <SelectionSection autoCreateEnabled={autoCreateEnabled} arrSynonyms={AutoCreatedSynonyms} arrDataUsers={arrDataUsers} arrUsers={arrUsers} isManual={isManual} handleSynonyms={handleAutoCreatedSynonyms} removeExternalReceipient={removeExternalReceipient} />}
                        <div className="warning-wrapper">
                            {isManual?<></>:<>{!autoCreateEnabled ? <div className="warning-2 warning">
                                <Iconaction2 />
                                <div className="typography">
                                    <span className="typography-bold">
                                        {t("syn_notif_note_text")}{" "}
                                    </span>
                                    {t("syn_notif_note_message")}
                                </div>
                            </div> : <></>}</>}


                            <div className="warning-new">
                                <p className="note-lorem-ipsum">
                                    {isManual ? t('syn_notif_manual_info') : t('syn_notif_auto_info')}

                                </p>
                                {isManual ? <></> : <div className="auto_notif_btn"><a href={SettingRoutes.settings_auto_synonyms_tickets} className="btn btn-primary button-40"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M1.33 7C1.33 5.803 2.303 4.83 3.5 4.83H6.3V3.5H3.5C1.568 3.5 0 5.068 0 7C0 8.932 1.568 10.5 3.5 10.5H6.3V9.17H3.5C2.303 9.17 1.33 8.197 1.33 7ZM4.2 7.7H9.8V6.3H4.2V7.7ZM10.5 3.5H7.7V4.83H10.5C11.697 4.83 12.67 5.803 12.67 7C12.67 8.197 11.697 9.17 10.5 9.17H7.7V10.5H10.5C12.432 10.5 14 8.932 14 7C14 5.068 12.432 3.5 10.5 3.5Z" fill="#3C83BC" />
                                </svg>{t('automatic_ticket_settings_text')}</a></div>
                                }
                            </div>
                        </div>

                    </div>
                    {isManual ? <AddExternalReceipient arrSynonyms={ManuallyCreatedSynonyms} arrDataUsers={arrDataUsers} arrUsers={arrUsers} isManual={isManual} handleAddExternalReceipient={handleAddExternalReceipientManual} externalUser={externalUserManual} setExternalUser={setExternalUserManual} /> : <AddExternalReceipient arrSynonyms={AutoCreatedSynonyms} arrDataUsers={arrDataUsers} arrUsers={arrUsers} isManual={isManual} handleAddExternalReceipient={handleAddExternalReceipientAuto} externalUser={externalUserAuto} setExternalUser={setExternalUserAuto} />}</> : <></>}
                {isManual ? <DeleteExternalRecipientModal modal={modal} setModal={setModal} handleRemoveExternalReceipient={handleRemoveExternalReceipientManual} isManual={isManual} RecipientToRemove={RecipientToRemove} /> : <DeleteExternalRecipientModal modal={modal} setModal={setModal} handleRemoveExternalReceipient={handleRemoveExternalReceipientAuto} isManual={isManual} RecipientToRemove={RecipientToRemove} />}


            </div>
        </div>
    </>
}

function SelectionSection({ sectionName, autoCreateEnabled, arrSynonyms, handleChangeDirectCheckboxes, handleSynonyms, isManual, arrDataUsers, arrUsers, removeExternalReceipient }) {
    const { t } = useTranslation();
    const { payload } = Session.get('login');
    const UserSession = payload;
    log.debug('arrSynonyms', arrSynonyms)
    return <>
        <div className={"div-2 " + (!isManual && !autoCreateEnabled ? 'disable-button' : '')}>
            <div className="form-radio d-inline-block">
                <label className="form-check-label" htmlFor={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + 'DisabledAllUsers'}>
                    <input
                        type="radio"
                        className="form-check-input"
                        id={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + 'DisabledAllUsers'}
                        name={isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms"}
                        value="DisabledAllUsers"
                        checked={arrSynonyms.EnabledFor !== undefined && arrSynonyms.EnabledFor === "DisabledAllUsers"}
                        onChange={handleSynonyms}
                    />
                    {t("syn_notif_disable_for_all")} <i className="input-helper"></i>
                </label>
            </div>
            <div className="form-radio d-inline-block">
                <label className="form-check-label" htmlFor={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + 'EnabledAllUsers'}>
                    <input
                        type="radio"
                        className="form-check-input"
                        id={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + 'EnabledAllUsers'}
                        name={isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms"}
                        value="EnabledAllUsers"
                        checked={arrSynonyms.EnabledFor !== undefined && arrSynonyms.EnabledFor === "EnabledAllUsers"}
                        onChange={handleSynonyms}
                    />
                    {t("syn_notif_enable_for_all")} <i className="input-helper"></i>
                </label>
            </div>
            <div className="form-radio d-inline-block">
                <label className="form-check-label" htmlFor={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + 'EnabledForIndividual'}>
                    <input
                        type="radio"
                        className="form-check-input"
                        id={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + 'EnabledForIndividual'}
                        name={isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms"}
                        value="EnabledForIndividual"
                        checked={arrSynonyms.EnabledFor !== undefined && arrSynonyms.EnabledFor === "EnabledForIndividual"}
                        onChange={handleSynonyms}
                    />
                    {t("syn_notif_enabled_for_individual")} <i className="input-helper"></i>
                </label>
            </div>
            <div className={"div-4 " + (arrSynonyms.EnabledFor !== undefined && arrSynonyms.EnabledFor !== "EnabledForIndividual" ? 'disable-button' : '')}>
                {isManual ? <div className="form-check form-check-flat d-inline-block">
                    <label className="form-check-label checkbox">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + "OwnerOnly"}
                            name={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + "OwnerOnly"}
                            value={arrSynonyms.OwnerOnly}
                            checked={arrSynonyms.OwnerOnly !== undefined && arrSynonyms.OwnerOnly === "Enabled"}
                            onChange={handleSynonyms}
                        />
                        {t("syn_notif_ticket_creator")} <i className="input-helper"></i>
                    </label>
                </div> : <></>}
                <div className="form-check form-check-flat d-inline-block">
                    <label className="form-check-label checkbox">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + "AssignedJGs"}
                            name={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + "AssignedJGs"}
                            value={arrSynonyms.AssignedJGs}
                            checked={arrSynonyms.AssignedJGs !== undefined && arrSynonyms.AssignedJGs === "Enabled"}
                            onChange={handleSynonyms}
                        />
                        {t("syn_notif_all_users_job_groups")} <i className="input-helper"></i>
                    </label>
                </div>
                <div className="form-check form-check-flat d-inline-block">
                    <label className="form-check-label checkbox">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + "SingleUsers"}
                            name={(isManual ? "ManuallyCreatedSynonyms" : "AutoCreatedSynonyms") + "SingleUsers"}
                            value="test"
                            checked={arrSynonyms.SingleUsers !== undefined && arrSynonyms.SingleUsers === "Enabled"}
                            onChange={handleSynonyms}
                        />
                        {t("syn_notif_single_users")} <i className="input-helper"></i>
                    </label>
                </div>

            </div>
            <div className={"div-5 " + (arrSynonyms.EnabledFor !== undefined && arrSynonyms.EnabledFor !== "EnabledForIndividual" ? 'disable-button' : '')}>

                <table className={"table " + (isManual ? "ManuallyCreatedSynonymsTable" : "AutoCreatedSynonymsTable")}>
                    {/* <thead><tr><td ><div className='form-check form-check-flat mb-0 mt-0'>
                                <label className='form-check-label checkbox'>
                                    <input type='checkbox' className='form-check-input' id='subuser-admin' name='JCDVisiblePages[]' value='AdminAll' onChange={handleSubUserSelectAll} checked={allAdmin} />{t('md_beta_select_all_sub_user')} <i className='input-helper'></i></label></div></td></tr></thead> */}
                    <tbody>
                        {arrUsers !== undefined && arrUsers !== "" && arrDataUsers.length > 0 && arrDataUsers.map((element, index) => {
                            // log.debug('Settingsnotif '+element.ID+ManuallyCreatedSynonyms.InternalSubUsers.includes(element.ID))
                            log.debug('Settingsnotif ', arrSynonyms.InternalSubUsers.includes(parseInt(element.ID)))
                            return <tr key={'admin-' + element.ID}>
                                <td>
                                    <div className={'form-check form-check-flat mb-0 mt-0 h-auto' + (UserSession !== undefined && parseInt(element.ID) === parseInt(UserSession.SubUserID) ? 'disabled' : '')}>
                                        <label className='form-check-label checkbox' data-sl="mask">

                                            <input type='checkbox' className={'form-check-input '} id={(isManual ? 'ManuallyCreatedSynonymsSingleUsersInternal-' : 'AutoCreatedSynonymsSingleUsersInternal-') + element.ID} name={'subusers[' + element.ID + '][Type]'} checked={arrSynonyms.InternalSubUsers.includes(parseInt(element.ID))} value={element.ID} data-subuserid={element.ID} onChange={(e) => { handleSynonyms(e) }} />{element.name}<i className="input-helper"></i>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        })}
                        {arrUsers !== undefined && arrSynonyms.ExternalRecipient.map((element, index) => {
                            log.debug('Settingsnotif ', arrSynonyms.InternalSubUsers.includes(parseInt(element.ID)))
                            return <tr key={'admin-' + element.ID}>
                                <td>
                                    <div className={'form-check form-check-flat mb-0 mt-0 h-auto' + (UserSession !== undefined && parseInt(element.ID) === parseInt(UserSession.SubUserID) ? 'disabled' : '')}>
                                        <label className='form-check-label checkbox' data-sl="mask">

                                            <input type='checkbox' className={'form-check-input '} id={(isManual ? 'ManuallyCreatedSynonymsSingleUsersExternal-' : 'AutoCreatedSynonymsSingleUsersExternal-') + element.mail} name={'subusers[' + element.mail + '][Type]'} checked={element.status === "Enabled"} value={element.mail} data-userid={element.mail} onChange={(e) => { handleSynonyms(e) }} />{element.mail}<i className="input-helper"></i>
                                        </label>
                                    </div>
                                    <div onClick={(e) => removeExternalReceipient(e, element.mail, isManual)}><svg className="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M2.85714 14.2222C2.85714 15.2 3.62857 16 4.57143 16H11.4286C12.3714 16 13.1429 15.2 13.1429 14.2222V3.55556H2.85714V14.2222ZM4.57143 5.33333H11.4286V14.2222H4.57143V5.33333ZM11 0.888889L10.1429 0H5.85714L5 0.888889H2V2.66667H14V0.888889H11Z" fill="#1C2D3B" />
                                    </svg></div>
                                </td>
                            </tr>
                        })}
                    </tbody></table>

            </div>
        </div></>
}

function AddExternalReceipient({ arrSynonyms, handleAddExternalReceipient, externalUser, setExternalUser, isManual }) {
    const { t } = useTranslation();
    const [isValid, setIsValid] = useState(false)
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const handleChangeInput = (event) => {
        setExternalUser(event.target.value)
        if (validateEmail(event.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }
    return <><div className={"frame-wrapper externalUser " + (arrSynonyms.EnabledFor !== undefined && arrSynonyms.EnabledFor !== "EnabledForIndividual" ? 'disable-button' : '')}>
        <div className="div-7">
            <div className="">
                <input className="form-control text-box" placeholder={t("syn_notif_add_external_email_placeholder")} type="email" id={isManual ? "externalUserManualSynonyms" : "externalUserAutoSynonyms"} onChange={handleChangeInput} value={externalUser} />
            </div>
            <button className={"button-3 " + (!isValid ? 'disable-button' : '')}>
                {(arrSynonyms.EnabledFor !== undefined && arrSynonyms.EnabledFor !== "EnabledForIndividual" || !isValid ? <AddIcon height='14px' width='14px' color="#C4C4C4" /> : <AddIcon height='14px' width='14px' color="#3c83bc" />)}

                <span className={"label valign-text-middle "} onClick={(e) => { if (isValid) { handleAddExternalReceipient(e); setExternalUser(''); setIsValid(false); } }}>{t("syn_notif_add_external_email")}</span>
            </button>

        </div>
    </div></>
}


const Iconaction2 = ({ color = "#3C83BC", className }) => {
    return (
        <svg
            className={`iconaction-2 ${className}`}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="path"
                d="M0 22.5H24L12 1.5L0 22.5ZM3.78545 20.2895L12 5.91L20.2145 20.2895C20.2145 20.2895 3.78545 20.2895 3.78545 20.2895ZM10.9091 16.9737H13.0909V19.1842H10.9091V16.9737ZM10.9091 10.3421H13.0909V15.7382H10.9091V10.3421Z"
                fill={color}
            />
        </svg>
    );
};


import React, { useState, useEffect, forwardRef } from "react";

export const Carousel = forwardRef((props, ref) => {
  const { items = [] } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  // const items = [
  //   {
  //     imgSrc: require('../../assets/images/login/product-image-1.png'),
  //     mainHeading: 'You want to know how a job will perform?',
  //     subHeading: 'Test the new Click Performance Indicator (CPI) tool in the Jobspreader Innovation Lab!',
  //   },
  //   {
  //     imgSrc: require('../../assets/images/login/product-image-1.png'),
  //     mainHeading: 'Learn how to improve your job postings',
  //     subHeading: 'Join our workshop on optimizing job performance metrics.',
  //   },
  //   {
  //     imgSrc: require('../../assets/images/login/product-image-1.png'),
  //     mainHeading: 'Boost your career with our tools',
  //     subHeading: 'Explore the Jobspreader tools that can help you succeed.',
  //   }
  // ];

  useEffect(() => {
    const interval = setInterval(() => {
      moveToNextSlide();
    }, 5000); // 5 seconds interval

    return () => clearInterval(interval); // Clean up on component unmount
  }, [currentIndex]);
  const moveToSlide = (index) => {
    setCurrentIndex(index);
  };

  const moveToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };
  const isNewTagVisible = (startDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const timeDifference = Math.abs(currentDate - start);
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference <= 14; // Show if within 14 days from start date
  };
  return (
    <div className="login-teaser">
      <img className="logo" src={require("../../assets/images/login/logo-1.svg").default} alt="Logo" />

      <div className="login-carousel-container">
        <div className="login-carousel" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {items.map((item, index) => (
            <div key={index} className="login-carousel-item">
              <a href={item.link} >
                <img className="product-image" src={item.imgSrc} alt="Product" />
                <div className="information">
                  <div className="new-tag">
                    <div className="frame">
                    {item.startDate && isNewTagVisible(item.startDate) ? <div className="NEU">NEW</div> : ""}
                    </div>
                  </div>
                  <p className="main-heading">{item.mainHeading}</p>
                  <p className="subheading">{item.subHeading}</p>
                </div>
              </a>
            </div>
          ))}
        </div>

        <div className="dot-indicators">
          {items.map((_, index) => (
            <div
              key={index}
              className={`pagination-dot ${currentIndex === index ? "active-dot" : ""}`}
              onClick={() => moveToSlide(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Carousel;

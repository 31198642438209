import { getPortfolioData } from "../../../services";
import { arrSigninAsAdminAuthId } from "../../../config/Constants";

/**
 * Fetches prototype details from the server.
 * @param {string} session_data - The UserSession data.
 * @param {number} prototype_id - The ID of the prototype.
 * @param {object} navData - The navigation data.
 * @returns {Promise<object>} A promise that resolves with the prototype details or rejects with an error.
 * @throws {object} Throws empty {} if the prototype ID is not provided.
 */
const fetchPrototypeDetailsPromise = (props) => {
  const { session_data, prototype_id } = props;
  if (!prototype_id) return Promise.reject({});
  return new Promise((resolve, reject) => {
    const data = {
      getInnovationLab: 1,
      sessionData: session_data,
      action: "get_lab_details",
      payload: {
        labId: prototype_id,
      },
    };

    getPortfolioData(data)
      .then((response) => {
        if (Object.keys(response).length !== 0) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Fetches the usage of the innovation lab.
 * @param {boolean} isDemo - Doesn't proceeds if demo user.
 * @param {boolean} isWMSRoot - Doesn't proceeds if WMS admin/master password.
 * @param {any} session_data - The UserSession data.
 * @param {number} prototype_id - The ID of the prototype.
 * @returns {Promise<boolean>} A promise that resolves to true if the lab usage is fetched successfully, or false if the lab usage count is greater than 0.
 * @throws {boolean} Throws true if the prototype ID is not provided.
 */
const fetchLabUsagePromise = (props) => {
  const { isDemo, isWMSRoot, session_data, prototype_id } = props;
  if (!prototype_id) return Promise.reject(true);
  return new Promise((resolve, reject) => {
    if (isDemo || isWMSRoot) {
      //set first visit to true, which will not increase the usage count
      //and not show the questions/feedback
      resolve(true);
      return;
    }

    const data = {
      getInnovationLab: 1,
      sessionData: session_data,
      action: "get_lab_usage",
      payload: {
        labId: prototype_id,
      },
    };

    getPortfolioData(data)
      .then((response) => {
        if (response.id === prototype_id) if (response.count > 0) resolve(false);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Records an event in the Innovation Lab.
 *
 * @param {Object} props - The properties for the event.
 * @param {Object} props.session_data - The session data.
 * @param {string} props.event - The event name.
 * @param {string} props.type - The event type.
 * @param {string} props.prototype_id - The prototype ID.
 * @param {string} props.button_name - The button name.
 * @returns {Promise} A promise that resolves with the response from the server.
 */
const recordInnovationLabEvent = (props) => {
  const { session_data, event, type, prototype_id, button_name, filter_name } = props;
  const buttonNameFormatted = button_name ? button_name.replace(/\s/g, "_") : null;
  const filterNameFormatted = filter_name ? filter_name.replace(/\s/g, "_") : null;

  const demoUser = session_data.UserName && session_data.UserName === "Demo";
  const masterPass = session_data.LoginByMasterPass === true;
  const signInAs = session_data.intAuthID && arrSigninAsAdminAuthId.includes(parseInt(session_data.intAuthID));

  return new Promise((resolve, reject) => {
    if (demoUser || masterPass || signInAs) {
      resolve({});
      return;
    }
    const data = {
      innovationLabTracking: 1,
      sessionData: session_data,
      payload: {
        event: event,
        type: type,
        lab_id: prototype_id,
        btn_name: buttonNameFormatted,
        filter_name: filterNameFormatted,
      },
    };

    getPortfolioData(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { fetchPrototypeDetailsPromise, fetchLabUsagePromise, recordInnovationLabEvent };

import log from "loglevel";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { Session } from 'bc-react-session';
import { updatePHPSession } from '../../services/sessionServices'
import { AESGCM_ENCRYPT } from "../../utils/aes-256-GCM";
import { ReactComponent as SettingsEnIcon } from '../../assets/icons/new_settings_en.svg';
import { ReactComponent as SettingsDeIcon } from '../../assets/icons/new_settings_de.svg';
import i18n from "i18next";
import { recordUserEvent } from "../blocks/common/record-user-event";
import { UserEvents } from "../../config/UserEvents";
import { useWMSContext } from "../../wmsContext";

export default function ChangeLanguage() {
    const { t } = useTranslation();
    const { payload } = Session.get('login');
    const UserSession = payload;
    log.debug(i18n.language);
    const [dropdownReturn, setdropdownReturn] = useState(i18n.language === 'en' ? true : false);
    const {isModifiedGlobal,setSaveSettingsModifiedModal,setFailRedirectUrl} = useWMSContext()
    function UpdateLanguage(v) {
        if(isModifiedGlobal) {setSaveSettingsModifiedModal(true);setFailRedirectUrl(window.location.href);return false;}

        if(UserSession !== undefined){
            recordUserEvent({
                session_data: UserSession,
                eventName: UserEvents.topbar_language_update
            });
          }
        // API to update php language session val.
        const encPostData = JSON.stringify({ updateSessionAPI: 1, DashboardLanguage: v.toLowerCase(), sessionData: UserSession });
        updatePHPSession({ SessionAPI: true, 'data': AESGCM_ENCRYPT(encPostData) }).then((consentResponse) => {
            i18n.changeLanguage(v);
            setdropdownReturn(v === 'en' ? true : false); // Set dropdownReturn

            // Session.setPayload({
            //     DashboardLanguage: v
            // });
            window.location.reload();
        });

    }


    // Set/Update Nav Dropdown Language Btn
    if (dropdownReturn) {

        return (
            <>
                <Dropdown.Item className=" font-weight-medium" style={{ fontSize: '16px', color: '#1c2d38', lineHeight: '27px', fontWeight: 400 }} onClick={() => UpdateLanguage('de')}>
                    <SettingsDeIcon />
                    {t('settings_language_form_german_checkbok_label_menu')}
                </Dropdown.Item>
            </>
        )

    } else {

        return (
            <>
                <Dropdown.Item className=" font-weight-medium" style={{ fontSize: '16px', color: '#1c2d38', lineHeight: '27px', fontWeight: 400 }} onClick={() => UpdateLanguage('en')}>
                    <SettingsEnIcon />
                    {t('settings_language_form_english_checkbok_label_menu')}
                </Dropdown.Item>
            </>
        )

    }
}
import React from "react";
import { Button, Modal } from "react-bootstrap";
import ModalBodyScrollIcon from "./common/scroll-down";

const ModalComponent = (props) => {
  const { show, modalClassName, className, contentClassName, handleClose, size, modalTitle, modalId, backdrop, keyboard, modelImage } = props;
  return (
    <Modal className={modalClassName} contentClassName={contentClassName} dialogClassName={className} id={modalId} onClose={handleClose} size={size} show={show} onHide={handleClose} backdrop={backdrop}
      keyboard={keyboard}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle} {modelImage !== '' ? <img className="ml-2" src={modelImage} alt="close"/> : <></>}</Modal.Title>
      </Modal.Header>
      <ModalBodyScrollIcon>{props.children}</ModalBodyScrollIcon>
      <Modal.Footer>
        <Button className="secondary-btn">Secondary Button</Button>
        <Button className="primary-btn">Primary Button</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
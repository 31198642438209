// any app specific constants that you don't want to keep in config can go here.
export const SYSTEM_ERROR = "System error. Please try again later!";
export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_CANCEL = "Cancel";
export const DEV = 1;
export const IS_LOCALHOST = (window.location.origin).includes('localhost')?1:0;
export const BASE_URL = (window.location.origin).includes('localhost')?"":'kunden_beta/revamp/';//DEV? '/':(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/"))).split('/')[1];
export const ENCRYPT_KEY = "62cc728db15b888842dac70dfe697562"
export const ENC_KEY_AES256GCM = "a3c91e89e4805de9713ea4117b557ed292b7a4e3d8792a91ad6782e34e49a570"
export const arrSigninAsAdminAuthId = [1,2];
export const MS_CLIENT_ID = "8ec728cb-a57b-4947-9e8f-985f27fb0d70";
export const GOOGLE_SIGN_IN_CLIENT_ID = "214671355735-oee0qs7fqd7bddlknmr483091h2n2aa1.apps.googleusercontent.com";





import log from "loglevel";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import SynonymsNotifications from "./synonyms_notifications";
import BudgetNotifications from "./budget_notifications";
import NewsletterNotifications from "./newsletter_notifications";
import { Helmet } from "react-helmet-async";
import { Session } from 'bc-react-session';
import '../../../styles/Settings.scss';
import Navigation from "../../inc/Navigation";
import { AlertModal, HeadingBlock, LoaderNew } from "../common-blocks";
import { Button, Modal } from "react-bootstrap";
import Footer from "../footer";
import SuccessFailModal from "../common/success-modal";
import { SettingRoutes } from "../../../config/RouteMap";
import { getPortfolioData, setPortfolioData } from "../../../services";
import { useWMSContext } from "../../../wmsContext";
import ModalBodyScrollIcon from "../common/scroll-down";
import { ReactComponent as RedWarningSVG } from '../../../assets/icons/Red Warning Icon.svg';
// var Select = require('react-select');

export default function SettingsTabNotifications({
  setModal
  // ,settingsData,arrUsers,setArrUsers
}) {
  const { t } = useTranslation();
  const { isModifiedGlobal, failRedirectUrl, setSaveSettingsModifiedModal, saveSettingsModifiedModal } = useWMSContext()
  const [settingsData, setSettingsData] = useState(false);
  const [arrUsers, setArrUsers] = useState('');
  const [TheUserID, setTheUserID] = useState();

  useEffect(() => {
    // alert(1111)
    // Get Campaigns D
    const params = new URLSearchParams(window.location.search);
    const paramsUserID = params.get("theuserid");
    let theuserid = UserSession.UserID;
    setTheUserID(theuserid)

    setNavigationData({});



    if (paramsUserID != null) {
      theuserid = paramsUserID;
      setTheUserID(atob(theuserid))
    }

    log.debug("Settings useeffect", settingsData)
    if (!settingsData) {
      getPortfolioData({ getSettingsAPI: 1, userID: theuserid, sessionData: UserSession })
        .then(
          (result) => {

            log.debug("Myjobs > Settings > res=", result);
            // if ((UserSession.UserType !== undefined && (UserSession.UserType === 'Admin' || UserSession.UserType === 'SuperAdmin'))) {
            //   setshowSubAccountsTab(true)
            //   setshowPersonalTab(false);
            // }
            // Show Job Group tab if referrer is Job Group Table
            // if (paramsJG !== null) {
            //   setshowSubAccountsTab(false)
            //   setshowPersonalTab(false);
            //   setshowSEATab(false);
            //   setshowJobGroupTab(true);
            // }
            // log.debug(result.arrSubAccounts)
            if ((UserSession.JSCDUserType !== undefined && UserSession.JSCDUserType === 'Admin')) {

              // log.debug(defaultCloningOptions)
              var arrDays = [];
              if (result.arrDays !== undefined) {
                arrDays = (result.arrDays);
              }






              // log.debug('---------->',result.strDashboardType);
              // log.debug('---------->',result.arrSubAccounts);


              setSettingsData({
                NewsletterNotification: result.arrNewsletterNotificationPreference.NewsletterNotification,
                AutoCreatedSynonymsNotification: result.arrCloningNotificationPreference.AutoCreatedSynonymsNotification,
                ManualCreatedSynonymsNotification: result.arrCloningNotificationPreference.ManualCreatedSynonymsNotification,
                JobBudgetNotification: result.arrJobBudgetNotificationPreference.JobBudgetNotification,
                autoCreateEnabled: (result.arrCloningToolTab.autoCreateEnabled ? 1 : 0),
                arrDays: arrDays,
                selectedDay: result.arrCloningToolTab.selectedDay,
                allLocationArray: result.allLocationArray,
                arrSubAccounts: result.arrSubAccounts,
                defaultAdmin: result.defaultAdmin,
              });
            }
            setArrUsers(settingsData.arrSubAccounts)
            //log.debug('settingsData', settingsData);
          },
          (error) => {
            log.debug(error)
          }
        )

    } else {
      log.debug("isModifiedGlobal", '1111')
      // handlePostdata() //TODO Removed for some
    }


    document.getElementById('sidebar').addEventListener('click', alertUser);
    // setIsModifiedGlobal(true)
    // window.addEventListener('beforeunload', alertUser)
    return function cleanup() {
      window.removeEventListener('click', alertUser);
    }

    // eslint-disable-next-line
  }, [
    arrUsers
  ]);
  // useEffect(() => {

  //   // log.debug((settingsData))
  //   if (settingsData !== undefined) {
  //     setArrUsers(settingsData.arrSubAccounts)
  //   }

  //   // eslint-disable-next-line
  // }, [settingsData,isModifiedGlobal]);

  const alertUser = () => {
    return false;
  }

  const [ManuallyCreatedSynonyms, setManuallyCreatedSynonyms] = useState('');
  const [AutoCreatedSynonyms, setAutoCreatedSynonyms] = useState('');
  const [NewsletterData, setNewsletterData] = useState('');
  const [JobBudgetData, setJobBudgetData] = useState('');

  const { payload } = Session.get('login');
  const UserSession = payload;
  const [navigationData, setNavigationData] = useState(false);
  const [DemoModal, setDemoModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successFailModal, setSuccessFailModal] = useState(false);
  const [sessionChanged, setSessionChanged] = useState(false);
  const [postData, setPostData] = useState(false);
  const handleSubmitSettings = (event) => {
    event.preventDefault();
    const params = new URLSearchParams(window.location.search);
    const paramsUserID = params.get("theuserid");
    let theuserid = UserSession.UserID;
    if (UserSession.UserName === 'Demo') {
      setDemoModal(true);
      return false;
    }

    if (paramsUserID != null) {
      theuserid = paramsUserID;
    }
    console.log('SubmitSettings ', ManuallyCreatedSynonyms)
    let tempPostData = {
      'SettingsPreferences': {
        NewsletterNotification: NewsletterData,
        ManualCreatedSynonymsNotification: ManuallyCreatedSynonyms,
        AutoCreatedSynonymsNotification: AutoCreatedSynonyms, JobBudgetNotification: JobBudgetData
      }
    }
    // handlePostdata()
    if (hasError) {
      return false;
    }
    // // return false;
    let postDataSettings = {
      setSettings: 1,
      userID: theuserid,
      sessionData: UserSession,
      method: 'setSettings',
      postData: tempPostData,
      // updateData: updateArr
    };
    // // return false;
    setPortfolioData(postDataSettings).then((SettingsResponse) => {

      if (SettingsResponse.status === 'success') {
        setSaveSettingsModifiedModal(false)
        setSuccessFailModal(true)
        setSessionChanged(false)
      }
      if (SettingsResponse.status === 'fail') {
        setSessionChanged(false)
        setSaveSettingsModifiedModal(false)
      }
      // setModal(false);        
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('sidbar_settings_notifications_tab') + ' ' + t('settings_page_seo_title') + ' - ' + t('jobspreader_board_text') + ' - ' + UserSession.UserExtAPIHiringOrgName}</title>
      </Helmet>
      {settingsData === false?<LoaderNew />:<>
      <div className={"content-wrapper"} id="content-wrapper"  >
        <Navigation navigationData={navigationData} isCampaignLevel={false} isJobGroupLevel={false} />
        <div className='myjobs-level-1'>
          <HeadingBlock data={{ title: t('sidbar_settings_notifications_tab') }} />
          {/* +' '+t('beta_settings_head') */}

          <div className="row">
            <div className="col-md-12 grid-margin top-section-1192 mx-auto ">
              <div className='row'>
                <div className='col-12'>

                  <div className="settings-block " id='pf-campaign-budget-summary-block'>
                    <div className="runtime-budget shadowBox">
                      <div>
                        <form autoComplete="off" method="post" className="form-passwprd" id="wmsForm" onSubmit={(e) => { e.preventDefault(); return false }} novalidate="novalidate">
                          <div className="errorBlock" style={{ display: 'none' }}>
                            <div className="alert alert-warning ">
                              <div className="row">
                                <div className="col-md-1"><i style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
                                  {/* <?php echo file_get_contents('images/icons/Red Warning Icon.svg'); ?> */}
                                </i></div>
                                <div className="col-md-11 errorBlockMsg"></div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-content tab-content-solid">
                            <div className={""} id="settings_tab_notifications" >
                              <>



                                {settingsData !== false ? <NewsletterNotifications key={2} arrUsers={arrUsers} settingsData={settingsData} isManual={false} setArrUsers={setArrUsers} NewsletterData={NewsletterData} setNewsletterData={setNewsletterData} /> : <></>}


                                {settingsData !== false ? <SynonymsNotifications key={1} arrUsers={arrUsers} settingsData={settingsData} isManual={true} setArrUsers={setArrUsers} ManuallyCreatedSynonyms={ManuallyCreatedSynonyms} setManuallyCreatedSynonyms={setManuallyCreatedSynonyms} /> : <></>}
                                {settingsData !== false ? <SynonymsNotifications key={3} arrUsers={arrUsers} settingsData={settingsData} isManual={false} setArrUsers={setArrUsers} AutoCreatedSynonyms={AutoCreatedSynonyms} setAutoCreatedSynonyms={setAutoCreatedSynonyms} /> : <></>}



                                {settingsData !== false ? <BudgetNotifications key={4} arrUsers={arrUsers} settingsData={settingsData} isManual={false} setArrUsers={setArrUsers} JobBudgetData={JobBudgetData} setJobBudgetData={setJobBudgetData} /> : <></>}
                              </>
                            </div>





                            {/* <!-- Tabs Content  --> */}
                          </div>
                          {/* <Button variant="outline-primary" onClick={() => { setModal(false); }}>{t('job_page_click_raise_limit_cancel_text')}</Button> */}

                          <Button variant="primary" className={"finalSubmitSettings greenPrimary pull-right " + ((isModifiedGlobal === false || hasError === true) ? ' disabledButton' : '')} onClick={handleSubmitSettings} >{t('md_beta_save_settings')}</Button>

                        </form>
                      </div></div>
                  </div>
                </div>
              </div>








            </div>
          </div>

        </div>
      </div>
      <Footer />
      <SuccessFailModal modalClassName={'success-modal'} activeTabValue={SettingRoutes.settings_notifications} setSuccessFailModal={setSuccessFailModal} successFailModal={successFailModal} modalTitle={t('pf_success_heading')} modalMessage={t('pf_settings_success_message')} enableSuccessIcon={true} backToSettingsButton={true} backButton={false} backButtonText={t('pf_settings_proceed_btn_text')} setAnotherModal={setModal} sessionChanged={sessionChanged} isSmallModal={true} />
      {DemoModal ? <AlertModal setModal={setDemoModal} modal={DemoModal} modalTitle={t('settings_cloning_tool_auto_task_approve_confirm_title')} modalMessage={t('text_demo_myjobs_clone')} backButton={true} backButtonText={t('job_page_click_raise_limit_cancel_text')} isSmallModal={true} /> : <></>}
      {saveSettingsModifiedModal ? <AlertModal setModal={setSaveSettingsModifiedModal} modal={saveSettingsModifiedModal} modalTitle={t('mj_warnig_title')} modalMessage={t('pf_settings_save_message')} backButton={true} backButtonText={t('job_page_click_raise_limit_cancel_text')} isSmallModal={true} handleSubmitSettings={handleSubmitSettings} submitButton={true} submitButtonText={t('pf_settings_save_yes')} cancelBtnUrl={failRedirectUrl} /> : <></>}
        
      </>}
    </>
  );
}

export function DeleteExternalRecipientModal({ modal, setModal, handleRemoveExternalReceipient, RecipientToRemove, isManual }) {
  const { t } = useTranslation();
  const handleConfirm = (event) => {
    handleRemoveExternalReceipient(event, RecipientToRemove)
    setModal(false)

  }

  const handleCancel = (event) => {
    setModal(false)
  }

  return <>
    {/* <div className="modal-backdrop modal-stack extraModalBackDrop"></div> */}
    <Modal
      className={'extraModal warningModal'}
      dialogClassName={'small-dailog small modal-sm'}
      id={'extraModal'}
      onClose={() => { setModal(false); }}
      show={modal}
      backdropClassName={'extraModalBackDrop'}
      contentClassName={'whitebg smallModal'}
      onHide={() => { setModal(false); }}
    >
      <Modal.Header closeButton >
        <Modal.Title><span>{t('settings_cloning_tool_auto_task_approve_confirm_title')}</span></Modal.Title>
      </Modal.Header>
      <ModalBodyScrollIcon>
        <div className="alert alert-warning ">
          <div className="row">
            <div className="col-md-1"><i style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}><RedWarningSVG /></i></div>
            <div className="col-md-11 msg">{t('syn_notif_sure_to_delete')}</div>
          </div>
        </div>
      </ModalBodyScrollIcon>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={(e) => { handleCancel(e); }}>{t('job_page_click_raise_limit_cancel_text')}</Button>
        <Button variant="primary" className="pull-right greenPrimary" style={{ background: '#3AC693', borderColor: '#3AC693', color: '#fff' }} onClick={handleConfirm} >{t('settings_cloning_tool_auto_task_approve_title_confirm_btn')}</Button>
      </Modal.Footer>
    </Modal>
  </>
}
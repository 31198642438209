/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to 
 * keep your app working for UI changes and 
 * make it independent of network requirements.
 * 
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 * 
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

import { IS_LOCALHOST } from "../config/Constants";

// Actual endpoints. Uncomment below section to use actual data.
export const SERVER_URL = IS_LOCALHOST ? "":"https://dev.jobspreader.com/";
export const DEDICATED_SERVER_URL = "https://dev.jobspreader.com/";
// Local endpoints. Uncomment below section to use dummy local data.
export const PF_BASE_URL =
  SERVER_URL + `kunden_beta/kunden_revamp_api/portfolio`;
  export const Login_BASE_URL =
  SERVER_URL + `kunden_beta/kunden_revamp_api/login`;
export const SET_PF_BASE_URL =
  SERVER_URL + `kunden_beta/kunden_revamp_api/portfolioUpdate`;
export const SET_FEEDBACK_BASE_URL =
  SERVER_URL + `kunden_beta/kunden_revamp_api/feedback_ajax`;

export const LOCAL_LOGIN_PATH = "";
export const SESSION_API_URL = 
  SERVER_URL + "kunden_beta/reactSessionApi/sessionReact";
export const LOGIN_URL = IS_LOCALHOST ? "login":SERVER_URL + "kunden_beta/revamp/login";
// SERVER_URL + "kunden_beta/";
export const SITE_URL = IS_LOCALHOST ? "http://localhost:3000/":SERVER_URL + "kunden_beta/revamp/";
export const SYSTEM_ERROR = "System error. Please try again later!";
export const PHP_COM_URL =
  SERVER_URL + "kunden_beta/kunden_revamp_api/common.ajax";
export const PHP_LOGOUT_URL =IS_LOCALHOST ? "logout": SERVER_URL + "kunden_beta/logout";
  
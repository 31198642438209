import React, { Suspense, useEffect, useRef } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import DemoSession from "./DemoSession";
import SessionCheck from "../inc/SessionCheck";
import Logout from "./Logout";
import NotFound from "./NotFound";
import { Session } from "bc-react-session";
import { RouteMap, SettingRoutes, InnovationLabRoutes, URLAccessibleWithoutSession } from "../../config/RouteMap";
import { ReactComponent as LogoLoaderDE } from '../../assets/icons/LoaderGermanLogo.svg';
import { ReactComponent as LogoLoaderEN } from '../../assets/icons/LoaderEnglishLogo.svg';
import i18n from "../../i18n";
import SettingsTabNotifications from "../blocks/settings/settings_tab_notifications";


const LoginPage = React.lazy(() => import("../LoginPage"));
const Myjobs = React.lazy(() => import("../Myjobs"));
const JSCPCHistoryBlock = React.lazy(() => import("../blocks/campaign/cpc-history-block"));
const JobGroupDetailed = React.lazy(() => import("../JobGroupDetailed"));
const Settings = React.lazy(() => import("../blocks/settings/settings"));
const NotificationSettings = React.lazy(() => import("../blocks/settings/settings_tab_notifications"));
const MyjobsDetailed = React.lazy(() => import("../MyjobsDetailed"));
// const MDChecker = React.lazy(() => import("../MDChecker"));
const TitleGPT = React.lazy(() => import("../TitleGPT"));
const JobGroupDashboard = React.lazy(() => import("../JobGroupDashboard"));
const InnovationLab = React.lazy(() => import("../InnovationLab"));
const TestPage = React.lazy(() => import("../TestPage"));

// import { slide as Menu } from 'react-burger-menu';
// import MyjobsDetailed from '../MyjobsDetailed.js++';
// import Construction from '../Construction';
// import Navigation from './Navigation';

/**
 * The main body containing the routes for the pages.
 *
 * params -  failRedirectUrl
 * params are only being used in the Settings component, so pass it directly
 *
 * @param {json} params
 * @returns {html} Body
 */
function Body(params) {
  const history = useHistory();
  
  const UserSession = Session.get("login").payload;
  const { intlLocale = "en-US" } = UserSession;

  const SuspenseFallback = () => {
    const { payload } = Session.get("login");
    const UserSession = payload;
    return (
      <div className="container-fluid page-body-wrapper">
        <div className="row">
          <div className="content-wrapper full-page-wrapper d-flex align-items-center index-login auth">
            <div className="row w-100">
              <div className="col-lg-8 mx-auto">
                <div className="row">
                  <h1 id="logo" className="mx-auto text-center">
                    {/* <LogoIcon /> */}
                    {i18n.language === 'en' ? <LogoLoaderEN  loading="lazy" className={'full_logo'} /> : <LogoLoaderDE  loading="lazy" className={'full_logo'} />}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      SessionCheck();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  /**
   * This useEffect is to prevent the background from scrolling when the modal is open
   *
   * This is a workaround for the issue where the background jumps to the top when the modal is open
   * due to position: fixed
   */
  const navBarRef = useRef(null);
  useEffect(() => {
    const handleModalOpen = () => {
      document.documentElement.style.overflowY = "hidden";
      if (navigator.userAgent.includes("Firefox")) return;
      document.documentElement.style.paddingRight = "8px";
      navBarRef.current && navBarRef.current.style.setProperty("padding-right", "8px", "important");
    };

    const handleModalClose = () => {
      document.documentElement.style.overflowY = "auto";
      if (navigator.userAgent.includes("Firefox")) return;
      document.documentElement.style.paddingRight = "0";
      navBarRef.current && navBarRef.current.style.setProperty("padding-right", "0", "important");
    };

    let modalPosTimeout;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          if (modalPosTimeout) clearTimeout(modalPosTimeout);
          modalPosTimeout = setTimeout(() => {
            const classList = mutation.target.className.split(/\s+/);
            navBarRef.current = document.querySelector("#content-wrapper .navbar");
            if (classList.includes("modal-open")) {
              handleModalOpen();
            } else {
              handleModalClose();
            }
          }, 50);
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={"/" + URLAccessibleWithoutSession.login} />
      </Route>
      <Route exact path={"/" + URLAccessibleWithoutSession.login}>
        <Suspense fallback={<SuspenseFallback />}>
          <LoginPage />
        </Suspense>
      </Route>
      <Route exact path={"/" + RouteMap.dashboard}>
        <Suspense fallback={<SuspenseFallback />}>
          <Myjobs isCampaignLevel={true} isJobGroupLevel={false} />
        </Suspense>
      </Route>
      <Route exact path={"/" + RouteMap.job_groups_dashboard}>
        <Suspense fallback={<SuspenseFallback />}>
          <JobGroupDashboard isCampaignLevel={false} isJobGroupLevel={true} />
        </Suspense>
      </Route>
      <Route exact path={"/" + RouteMap.cpc_history}>
        <Suspense fallback={<SuspenseFallback />}>
          <JSCPCHistoryBlock />
        </Suspense>
      </Route>
      <Route exact path={"/" + RouteMap.myjobs}>
        <Suspense fallback={<SuspenseFallback />}>
          <MyjobsDetailed />
        </Suspense>
      </Route>
      {/* <Route exact path="/marketdata-checker">
        <Suspense fallback={<SuspenseFallback />}>
          <MDChecker />
        </Suspense>
      </Route> */}
      <Route exact path={"/" + RouteMap.job_groups}>
        <Suspense fallback={<SuspenseFallback />}>
          <JobGroupDetailed isJobGroupLevel={true} />
        </Suspense>
      </Route>
      
      <Route exact path={"/" + RouteMap.settings}>
        <Redirect to={"/" + SettingRoutes.settings_personal} />
      </Route>
      {Object.entries(SettingRoutes).map(([key, value], index) => {
        return (
          <Route exact key={index} path={"/" + value}>
            <Suspense fallback={<SuspenseFallback />}>
            <>{value === SettingRoutes.settings_notifications?<SettingsTabNotifications {...params}/>:<Settings {...params} />}</>
              
            </Suspense>
          </Route>
        );
      })}
      <Route exact path={"/" + RouteMap.title_gpt}>
        <Suspense fallback={<SuspenseFallback />}>
          <TitleGPT />
        </Suspense>
      </Route>
      <Route exact path={"/" + RouteMap.sourcing_search}>
        <Redirect to={"/" + InnovationLabRoutes.sourcing_search_builder} />
      </Route>
      <Route exact path={"/" + RouteMap.innovation_lab}>
        <Suspense fallback={<SuspenseFallback />}>
          <InnovationLab />
        </Suspense>
      </Route>
      {Object.entries(InnovationLabRoutes).map(([key, value], index) => {
        return (
          <Route exact key={index} path={"/" + value}>
            <Suspense fallback={<SuspenseFallback />}>
              <InnovationLab route={key} />
            </Suspense>
          </Route>
        );
      })}
      <Route path={"/" + RouteMap.test}>
        <Suspense fallback={<SuspenseFallback />}>
          <TestPage />
        </Suspense>
      </Route>
      <Route exact path={"/" + RouteMap.logout} component={Logout} />
      <Route exact path={"/" + RouteMap.theuserid + "/:encUserID"} component={DemoSession} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default Body;
